import React, {  useState, memo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import LayersIcon from '@material-ui/icons/Layers';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import SchoolIcon from '@mui/icons-material/School';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import SecurityIcon from '@mui/icons-material/Security';
import Box from '@material-ui/core/Box';


const MapLayerDial = ({ className, layerSetters }) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const [setShowSchools, setShowCrime, setShowHistoricPrices, setSatView, setShowProps, showSchools, showCrime, showHistoricPrices, satView, showProps] = layerSetters;
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSat = () => {
        setSatView(a => !a)
        handleClose();
    }
    const handleSchool = () => {
        setShowSchools(a=>!a)
        setShowCrime(false)
        setShowHistoricPrices(false) 
        handleClose();
    }
    const handleCrime = () => {
        setShowCrime(a => !a)
        setShowSchools(false)
        setShowHistoricPrices(false) 
        handleClose();
    }
    const handlePrice = () => {
        setShowHistoricPrices(a => !a) 
        setShowSchools(false)
        setShowCrime(false)
        handleClose();
    }

    const handleProps = () => {
        setShowProps(a => !a)
        handleClose();
    }

    const actions = [
        { icon: <SatelliteAltIcon />, name: 'Satellite', click: handleSat, active: satView },
        { icon: <SchoolIcon />, name: 'Schools', click: handleSchool, active: showSchools },
        { icon: <SecurityIcon />, name: 'Crime', click: handleCrime, active: showCrime },
        { icon: <CurrencyPoundIcon />, name: 'Price History', click: handlePrice, active: showHistoricPrices },
        { icon: <CurrencyPoundIcon />, name: 'Properties', click: handleProps, active: showProps },
    ];

    console.log("THEME", theme);
    return (
        <Box className={className} >
            
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: 'absolute', bottom: 16, right: 16 }}
                icon={<LayersIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
                direction="down"
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        tooltipOpen
                        onClick={action.click}
                        tooltipPlacement="right"
                        FabProps={{ style: { backgroundColor: action.active ? theme.palette.secondary.main : theme.palette.mapicons.background } }}
                        
                    />
                ))}
            </SpeedDial>
        </Box>
    );
}

export default memo(MapLayerDial);