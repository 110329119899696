import { useState, useEffect } from "react";
import "./myswiper/MySwiper.css";

const ResponsiveImage = ({ id, fp, sizes, className, eager }) => {

    const [isLoaded, setIsLoaded] = useState(false);

    //let exampleSize1 = "(max-width: 420px) 90vw, (max-width: 768px) 76vw, 590px";
    //let exampleSize2 = "(max-width: 420px) 45vw, (max-width: 768px) 38vw, 320px";
    function makeSrcSet(format) {
        return "https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + id + "-320." + format + " 320w," +
            "https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + id + "-590." + format + " 590w," +
            "https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + id + "-768." + format + " 768w," +
            "https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + id + "-1024." + format + " 1024w"
    }

    useEffect(() => {
        if (id != null) {
            setIsLoaded(false);
        }
    }, [id]);


    if (fp) {
        return (
            <img
                onLoad={() => {
                    setIsLoaded(true);
                }}
                className={className + " full"}
                src={"https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + id}
                style={{
                    opacity: isLoaded ? 1 : 0, visibility: isLoaded ? "visible" : "none",
                    maxHeight: isLoaded ? "1000px" : "0px", aspectRatio: "4/3", objectFit: "contain",
                    cursor:"pointer"
                }}
                loading={eager ? "eager" : "lazy"}

            />
        );
    }

    return (
        <>
            <picture>
                <source
                    type="image/avif"
                    srcSet={"https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + id + "-64.avif"}
                />
                <source
                    type="image/webp"
                    srcSet={"https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + id + "-64.webp"}
                />
                <img
                    className={className + " thumb"}
                    srcSet={"https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + id + "-64.jpeg"}
                    src={"https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + id}
                    style={{
                        display: isLoaded ? "none" : "block", maxHeight: isLoaded ? "1000px" : "0px", cursor: "pointer",
                        aspectRatio: "4/3", objectFit: "cover"                    }}
                />
            </picture>
            <picture>
                <source
                    type="image/avif"
                    srcSet={makeSrcSet("avif")}
                    sizes={sizes}
                />
                <source
                    type="image/webp"
                    srcSet={makeSrcSet("webp")}
                    sizes={sizes}
                />
                <img
                    onLoad={() => {
                        setIsLoaded(true);
                    }}
                    className={className + " full"}
                    srcSet={makeSrcSet("jpeg")}
                    sizes={sizes}
                    src={"https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + id}
                    style={{
                        opacity: isLoaded ? 1 : 0, visibility: isLoaded ? "visible" : "none", maxHeight: isLoaded ? "1000px" : "0px", cursor: "pointer",
                        aspectRatio: "4/3", objectFit: "cover"                    }}
                    loading={eager ? "eager" : "lazy"}

                />
            </picture>
        </>
    );
};

export default ResponsiveImage;