import { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Mousewheel, Virtual } from "swiper";
import EffectCarousel from "./effect-carousel.esm.js";
import ProductTile from "./ProductTile.jsx";

import 'swiper/swiper.min.css'
import 'swiper/modules/a11y/a11y.min.css'
import 'swiper/modules/mousewheel/mousewheel.min.css'

import "./effect-carousel.css";
import "./MySwiper.css";

export default function SwiperV({ data, setPropertyCardClicked, setSelectedPropId, propertyMarkerClicked, setPropertyMarkerClicked, productOpen }) {

    const [swiper, setSwiper] = useState(null);
    const lastActiveSlide = useRef(null);
    const lastIdxSlide = useRef(0);
    const [virtualData, setVirtualData] = useState([]);
    const virtualSlideNumber = 9;
    //const forwardShift = 4; // #/2
    //const backwarddShift = 5; // #/2-1
    const shift = 4;

    const setActiveSlide = (id) => {
        let vData = [];
        if (id != null && swiper != null) {
            var activeIdx = data.findIndex(a => a.id == id);
            if (activeIdx >= 0) {
                let swiperRealIndex = swiper.realIndex;
                let idxShift = [0, 1, 2, 3, 4, -4, -3, -2, -1];
                for (let i = 0; i < virtualSlideNumber; i++) {
                    let loc = (i + swiperRealIndex) % 9;
                    let dataIdx = (activeIdx + idxShift[i] + (data.length * 8)) % data.length;
                    let dataItm = data[dataIdx]
                    dataItm.idx = dataIdx;
                    vData[loc] = dataItm;
                }
            }
        }
        return vData;
    }

    useEffect(() => {
        //assuming on vdata change, real index is reset to 0. if this is not the case. capture what is current realindex from onslidechange, and use that value.
        if (data != null && data.length > 0) {
            let vData = setActiveSlide(lastActiveSlide.current)

            if (vData.length < 1) {
                vData.push(data[4 % data.length]);

                vData.push(data[5 % data.length]);
                vData.push(data[6 % data.length]);
                vData.push(data[7 % data.length]);
                vData.push(data[8 % data.length]);

                vData.push(data[0 % data.length]);
                vData.push(data[1 % data.length]);
                vData.push(data[2 % data.length]);
                vData.push(data[3 % data.length]);
            }

            setVirtualData(vData);
            console.log("Setting active slide for swiper", lastActiveSlide.current, vData[0]);
            console.log(vData);
            if (swiper != null && swiper.realIndex > -1 && !productOpen) {
                setSelectedPropId(vData[swiper.realIndex].id);
                lastActiveSlide.current = (vData[swiper.realIndex].id);
            }
        } else {
            setVirtualData([]);
        }
    }, [data, swiper, productOpen]);

    useEffect(() => {
        //doesnt feel like this should live here (in this jsx)
        if (propertyMarkerClicked && data) {
            if (propertyMarkerClicked == -1) {
                console.log("Nulling SelectedPropId");
                setSelectedPropId(null);
            }
            else {
                console.log("Active prop click", propertyMarkerClicked);
                let vData = setActiveSlide(propertyMarkerClicked);
                setVirtualData(vData);
                setSelectedPropId(propertyMarkerClicked);
                lastActiveSlide.current = (propertyMarkerClicked);
                setPropertyMarkerClicked(null);
            }

        }

    }, [propertyMarkerClicked, data, swiper, productOpen]);

    const slideChangeFunc = (swiper) => {
        console.log("SWIPER CHANGE", swiper.realIndex);
        if (virtualData[swiper.realIndex]) {
            setSelectedPropId(virtualData[swiper.realIndex].id);
            lastActiveSlide.current = (virtualData[swiper.realIndex].id);
            console.log("set lastIdx", lastActiveSlide.current);
        } else {
            setSelectedPropId(null);
        }
    };

    const forwardChange = (swiper) => {
        console.log("FORWARD", swiper.realIndex);
        console.log(swiper);
        let slideId = swiper.slides[swiper.activeIndex].id;
        let dataIdx = data.findIndex(a => a.id == slideId);
        let newDataIdx = (dataIdx + shift) % data.length
        let newData = data[newDataIdx];
        newData.idx = newDataIdx;
        setVirtualData(a => {
            console.log(newData);
            a[(swiper.realIndex + shift) % a.length] = newData;
            console.log(a);
            console.log(a.map(b => b.idx));
            return a;
        });


        //let dataIdx = (swiper.realIndex + idxShift[i] + (data.length * 8)) % data.length;
        //let dataItm = data[dataIdx]
        //let newVdata = [vData];

    }

    const backwardChange = (swiper) => {
        console.log("BACKWARD", swiper.realIndex);
        console.log(swiper);
        let slideId = swiper.slides[swiper.activeIndex].id;
        let dataIdx = data.findIndex(a => a.id == slideId);
        let newDataIdx = (dataIdx - shift + (data.length * shift)) % data.length
        let newData = data[newDataIdx];
        newData.idx = newDataIdx;

        setVirtualData(a => {
            console.log(newData);
            a[(swiper.realIndex - shift + (a.length * shift)) % a.length] = newData;
            console.log(a);
            console.log(a.map(b => b.idx));
            return a;
        });
    }

    const swiperParameters = {
        modules: [A11y, Mousewheel, EffectCarousel],
        slidesPerView: 1.2,
        spaceBetween: 10,
        lazy: { enabled: true },
        centeredSlides: true,
        loop: true,
        threshold: 5,
        breakpoints: {
            420: { spaceBetween: 20, slidesPerView: 1.4 },
            768: { spaceBetween: 30, slidesPerView: 1.7 },
            1024: { spaceBetween: 40, slidesPerView: 2 }
        },
        effect: "carousel",
        mousewheel: { enabled: true },

        onSlideChange: slideChangeFunc,
        onSlideNextTransitionStart: forwardChange,
        onSlidePrevTransitionStart: backwardChange,
    }

    if (Swiper && !Swiper.destroyed && !productOpen) {
        return (
            <>
                <Swiper {...swiperParameters} onInit={(ev) => {
                    setSwiper(ev);
                }} >
                    {
                        virtualData.map((f, i) => {
                            return (
                                <SwiperSlide id={f.id} >
                                    <ProductTile product={f} swiper={() => swiper} idx={i} key={f.id} setClicked={setPropertyCardClicked} />
                                </SwiperSlide>
                            )
                        })}

                </Swiper>
            </>
        );
    }
    else {
        return(null);
    }
}
