import React, { useState, memo, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import BasicTable from '../BasicTable';
import NestedTable from '../NestedTable';

const distStr = (num) => {
    if (num >= 1000) {
        let rnd = Math.round(num / 100) / 10;
        return `${rnd} km`
    }
    else {
        return `${num} m`
    }
}

const CrimeDetail = ({ data }) => {

    const [crimeState, setCrimeState] = useState(null);
    const [crimeSubState, setCrimeSubState] = useState(null);
    const seriousCrime = ["Violence and sexual offences", "Robbery", "Possession of weapons", "Burglary"];
    const [crimeCounts, setCrimeCounts] = useState(null);

    useEffect(() => {
        if (data) {
            let tCnts = [];
            Object.entries(data.CrimeCounts).forEach(e => tCnts.push(e));
            setCrimeCounts(tCnts);

            let tCrime = [];
            let tSubCrime = [];
            
            data.Crime.sort((a, b) => a.DistanceM - b.DistanceM).forEach(c => {
                let sCnt = 0;
                let tSubRow = [];

                c.Types.forEach(t => {
                    if (seriousCrime.includes(t.Name)) {
                        sCnt += t.TotalCount;
                    }

                    Object.entries(t.TimeBreakdown).forEach(([k, v]) => {
                        let subRow = [k, t.Name, v];
                        tSubRow.push(subRow);
                    });


                });
                console.log(tSubRow);

                let row = [c.LocationDescription, distStr(c.DistanceM), c.TotalCount, sCnt];
                tCrime.push(row);
                tSubCrime.push(tSubRow);

            });
            setCrimeState(tCrime);
            setCrimeSubState(tSubCrime);
        }
    }, [data]);

    return (
        <Paper>
            <Typography variant="subtitle1" gutterBottom>Area Totals</Typography>
            <BasicTable data={crimeCounts} header={["Type", "Count"]} />
            <br />

            <Typography variant="subtitle1" gutterBottom>Crimes by location</Typography>
            <NestedTable
                data={crimeState}
                header={["Location", "Distance", "Total Crimes", "Serious Crimes Total"]}
                subData={crimeSubState}
                subHeader={["Date", "Crime Type", "Subtotal"]}
            />
            <br />
        </Paper>
    );
}


export default memo(CrimeDetail);