import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({

    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const ProductView = ({ item, tabHeader, tabPanels }) => {
    const classes = useStyles();

    if (item) {

        return (
            <div className={classes.root}>
                {tabHeader}
                {tabPanels}
            </div>);
    }

    return (null);
}

export default memo(ProductView);