import React, { useState, memo, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

const FloodingSelect = ({ filterData, setFilter }) => {
    const [data, setData] = useState([]);
    const [boxes, setBoxes] = React.useState({
        NoRisk: { value: true, label: "No Risk" },
        Medium: { value: true, label: "Medium" },
        Low: { value: true, label: "Low" },
        VeryLow: { value: true, label: "Very Low" },
        High: { value: true, label: "High" }
    });


    //children: Array(4)
    //0: { label: 'No Risk/Data', field: 'FloodRisk', id: '228', count: 88, type: 'Term', … }
    //1: { label: 'Medium', field: 'FloodRisk', id: '229', count: 4, type: 'Term', … }
    //2: { label: 'Low', field: 'FloodRisk', id: '230', count: 3, type: 'Term', … }
    //3: { label: 'High', field: 'FloodRisk', id: '231', count: 1, type: 'Term', … }
    //length: 4



    useEffect(() => {
        if (filterData == null || filterData.exclusionFilters == null || filterData.exclusionFilters.children == null) {
            return;
        }

        let dataT = filterData.exclusionFilters.children.find(a => a.label == "FloodRisk");

        setData(dataT);
    }, [filterData]);

    useEffect(() => {
        setFilter(a => {
            //TODO: if any states are false, add the not filter. via setFilter.
            //remove existing filters if exists.
            let newFilter = a.filter(b => b.field != "FloodRisk");
            let rtnArr = Object.entries(boxes).filter(a => !a[1].value); //get false entries.
            //re-add filters if selected.
            if (rtnArr && rtnArr.length > 0) {
                rtnArr.forEach(v => {
                    let f = data.children.find(a => a.label == v[1].label);
                    if (!f)
                        f = makeFilter(v[1].label);
                    newFilter.push(f);
                });
            }
            return newFilter;
        });
    }, [boxes]);

    const handleChange = (event) => {
        setBoxes(state => {
            let label = state[event.target.name].label;
            let rtn = {
                ...state, [event.target.name]: { value: event.target.checked, label: label }
            };
            return rtn;
        });

    };

    const getText = (selector) => {
        if (data.children) {
            let f = data.children.find(a => a.label == selector);
            return `${selector} (${f ? f.count : 0})`;
        }
    }

    const makeFilter = (label) => {
        return {
            children: null,
            count: 0,
            displayText: label,
            field: "FloodRisk",
            from: null,
            id: "fakedId",
            label: label,
            to: null,
            type: "NotTerm"
        };
    }

    return (
        <Paper>
            <Typography variant="h6" align="left">Flooding</Typography>
            <FormGroup>
                <FormControlLabel value="NoRisk" control={<Checkbox checked={boxes.NoRisk.value} onChange={handleChange} name="NoRisk" />} label={getText(boxes.NoRisk.label)} />
                <FormControlLabel value="VeryLow" control={<Checkbox checked={boxes.VeryLow.value} onChange={handleChange} name="VeryLow" />} label={getText(boxes.VeryLow.label)} />
                <FormControlLabel value="Low" control={<Checkbox checked={boxes.Low.value} onChange={handleChange} name="Low" />} label={getText(boxes.Low.label)} />
                <FormControlLabel value="Medium" control={<Checkbox checked={boxes.Medium.value} onChange={handleChange} name="Medium" />} label={getText(boxes.Medium.label)} />
                <FormControlLabel value="High" control={<Checkbox checked={boxes.High.value} onChange={handleChange} name="High" />} label={getText(boxes.High.label)} />
            </FormGroup>
        </Paper>
    );
}


export default memo(FloodingSelect);