import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import React, { useState, memo, useRef } from 'react';

const Layout = ({ children }) => {

    const [isFilterOpen, setIsFilterOpen] = useState(false);

    return (
        <div style={{
            position: "relative", height: "100vh", display: "flex", flexFlow: "column"}}>
            <NavMenu isFilterOpen={isFilterOpen} setIsFilterOpen={setIsFilterOpen} style={{ flex: "0 1 auto", width: "100vw" }} />
            <Container className='contentContainer' style={{ flex: "1 1 auto", width: "100vw", height:"100%", overflow: "hidden" }}>
                {children}
            </Container>
        </div>
    );
}


export default memo(Layout);