
const IfThen = ({ children, condition }) => {
    console.log("condition", condition);
    return (
        <>
            {condition && children}
        </>
    );
}


export default IfThen;