import React, { useState, memo, useEffect, useRef } from 'react';
import { Paper } from '@material-ui/core';
import Box from '@mui/material/Box';
import GradientBar from './GradientBar';
import { Grid, Typography, Slider } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: 'rgba(255,255,255,0.8)',
        border: '1px solid gray',
        display: 'inline-flex',
        position: 'relative',
        alignItems: 'center',
        verticalAlign: 'middle',
        justifyContent: 'center',
        pointerEvents: 'auto',
        opacity: 1,


        [theme.breakpoints.up("xs")]: {
            width: '400px',
            height: '220px',
            borderRadius: '10px',
            margin: '6px 16px',
        },
        [theme.breakpoints.down("xs")]: {
            width: '220px',
            height: '180px',
            borderRadius: '6px',
            margin: '3px 8px',
            paddingTop: "20px",
        },

        '& .content': {

            [theme.breakpoints.up("xs")]: {
                width: '360px',
            },
            [theme.breakpoints.down("xs")]: {
                width: '180px',
                paddingTop: "0px",
            },
        }
    }
}));

const schoolLayers = [
    "ClassSizeAvgPrimary",
    "ClassSizeAvgSecondary",
    "Diversity",
    "KS2AverageProgress",
    "KS2PercentExpectedStandard",
    "KS2PercentHigherStandard",
    "KS4Attainment8",
    "KS4Progress8",
    "KS5Best3AveragePoints",
    "Ofsted", //busted
    "PercentCapacity",
    "PercentFreeSchoolMeals",
    "PercentPupilsEnglishFirstlanguage",
    "Demographics",
];

const demographics = [
    "PercentWhiteBritish",
    "PercentIrish",
    "PercentTravellerofIrishHeritage",
    "PercentAnyotherWhiteBackground",
    "PercentGypsyRoma",
    "PercentWhiteAndBlackCaribbean",
    "PercentWhiteAndBlackAfrican",
    "PercentWhiteAndAsian",
    "PercentAnyOtherMixedBackground",
    "PercentIndian", //busted
    "PercentPakistani",
    "PercentBangladeshi",
    "PercentAnyOtherAsianBackground",
    "PercentCaribbean",
    "PercentAfrican",
    "PercentAnyOtherBlackBackground",
    "PercentChinese",
    "PercentAnyOther",
];

const SchoolsControl = ({ visible, schoolsState, demographicsState }) => {

    const classes = useStyles();
    const [schoolsFilter, setSchoolsFilter] = schoolsState;
    const [demographicsFilter, setDemographicsFilter] = demographicsState;
    if (!visible) {
        return (<></>);
    }

    return (<Box className={classes.container}>
        <Grid container spacing={0} style={{ margin: 'auto' }} className="content" >
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <GradientBar type={"pricediff"} />
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>

                <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Overlay
                    </InputLabel>
                    <NativeSelect
                        defaultValue={schoolsFilter}
                        inputProps={{
                            name: 'OverlaySelect',
                            id: 'uncontrolled-native',
                        }}
                        onChange={e => setSchoolsFilter(e.target.value)}
                    >
                    {schoolLayers.map(a => (<option value={a}>{a}</option>))}
                   </NativeSelect>
                </FormControl>
            </Grid>
            {schoolsFilter == "Demographics" ? (
                <Grid item xs={12} style={{ textAlign: 'center' }}>

                    <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                            Demographic
                        </InputLabel>
                        <NativeSelect
                            defaultValue={demographicsFilter}
                            inputProps={{
                                name: 'DemographicsSelect',
                                id: 'uncontrolled-native',
                            }}
                            onChange={e => setDemographicsFilter(e.target.value)}
                        >
                            {demographics.map(a => (<option value={a}>{a}</option>))}
                        </NativeSelect>
                    </FormControl>
                </Grid>
            )
                : (<></>)}

    </Grid>
    </Box >)
}


export default memo(SchoolsControl);