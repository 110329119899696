import React, { useState, memo, useRef, Component, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const DescriptionAndKey = ({ data }) => {

    const [rows, setRows] = useState([]);

    function createData(name, value) {
        return { name, value };
    }

    useEffect(() => {

        if (data != null) {

            let rowsData = [
                createData('Flood risk', data.FloodRisk == "No Risk/Data" ? "No known risk" : data.FloodRisk),
                createData('Risk of subsidence by 2030', data.SubsidenceRisk2030),
                createData('Risk of subsidence by 2070', data.SubsidenceRisk2070),
                createData('Total rooms', data.RoomsCnt),
            ];

            const deprivationEngland = 32844;
            if (data.IndexMultipleDeprivation && data.IndexMultipleDeprivation > 0) {
                let depPercentile = 100 * data.IndexMultipleDeprivation / deprivationEngland;
                let depPercRound = 100 - Math.round(depPercentile); //.toFixed(1);
                if (depPercRound >= 95) {
                    rowsData.push(createData("Deprivation", `Very deprived (${depPercRound} percentile)`));
                } else if (depPercRound >= 80) {
                    rowsData.push(createData("Deprivation", `Deprived (${depPercRound} percentile)`));
                } else if (depPercRound >= 60) {
                    rowsData.push(createData("Deprivation", `Slightly deprived (${depPercRound} percentile)`));
                } else if (depPercRound >= 40) {
                    rowsData.push(createData("Deprivation", `Average (${depPercRound} percentile)`));
                } else if (depPercRound >= 20) {
                    rowsData.push(createData("Deprivation", `Below average deprivation (${depPercRound} percentile)`));
                } else if (depPercRound >= 5) {
                    rowsData.push(createData("Deprivation", `Significantly below average deprivation (${depPercRound} percentile)`));
                } else {
                    rowsData.push(createData("Deprivation", `Least deprived (${depPercRound} percentile)`));
                }


            }
            
            let allowedTenures = ["unknown", "owner-occupied"];
            //if (data.locationAccurate &&
            //    (data.tenure == "FREEHOLD" ||
            //        (data.epcData != null && allowedTenures.includes(data.epcData.tenure))
            //    )
            //) {

            setRows(rowsData);
        }
    }, [data]);

    return (
        <Paper>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    {/*<TableHead>*/}
                    {/*    <TableRow>*/}
                    {/*        <TableCell>Feature</TableCell>*/}
                    {/*        <TableCell align="right">Value</TableCell>*/}
                    {/*    </TableRow>*/}
                    {/*</TableHead>*/}
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">{row.name}</TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        </Paper>
    );
}


export default memo(DescriptionAndKey);