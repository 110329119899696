import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import Box from '@material-ui/core/Box';
import MapBox from '../MapBox';
import QueryController from '../QueryController';
import ProductView from '../property/ProductView';
import useProductQuery from '../ProductQuery';
import SimpleBackdrop from '../SimpleBackdrop';
import SideView from './SideView';
import TopView from './TopView';
import BottomNav from './BottomNav';
import PropSwiper from '../PropSwiper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MapLayerDial from '../MapLayerDial';
import Button from '@material-ui/core/Button';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import MainFilter from '../filters/MainFilter';
import SchoolView from '../SchoolView';

import PriceHistoryControl from '../layercontrols/PriceHistoryControl';
import CrimeControl from '../layercontrols/CrimeControl';
import SchoolsControl from '../layercontrols/SchoolsControl';

import MobileSelector from './MobileSelector';
import RightDrawer from './RightDrawer';
import BottomSwipeableDrawer from './BottomSwipeableDrawer';
import BottomSheetMobile from './BottomSheetMobile';
import TabPanels from '../property/TabPanels';
import PropertyTabBar from '../property/PropertyTabBar';

const useStyles = makeStyles((theme) => ({
    fab: {
        marginLeft: "10px",
        pointerEvents: "auto",
    },
    fabCont: {
        marginLeft: "10px",
        marginTop: "10px",
        flexDirection: "row",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "baseline",
    }
}));

const MainView = ({ }) => {
    const [showFilters, setShowFilters] = useState(false);
    const [showHistoricPrices, setShowHistoricPrices] = useState(false);
    const [showCrime, setShowCrime] = useState(false);
    const [showSchools, setShowSchools] = useState(false);
    const [showProps, setShowProps] = useState(true);
    const classes = useStyles();
    const theme = useTheme();
    const [lng, setLng] = useState(-2.0);
    const [lat, setLat] = useState(52.50);
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(6.5);
    const [data, setData] = useState(null);
    const [filterTerms, setFilterTerms] = useState([]);
    const [propertyCardClicked, setPropertyCardClicked] = useState(null);
    const [propertyMarkerClicked, setPropertyMarkerClicked] = useState(null);
    const [propertyCardActive, setPropertyCardActive] = useState(null);
    const [productOpen, setProductOpen] = useState(false);
    const [productLoading, setProductLoading] = useState(false);
    const [product, setProduct] = useProductQuery();
    const [cnt, setCnt] = useState(0);
    const [poly, setPoly] = useState(null);
    const [productData, setProductData] = useState(null);
    const [crimes, setCrimes] = useState(null);
    const [schools, setSchools] = useState(null);
    const [toggleFly, setToggleFly] = useState(null);
    const [prevLocation, setPrevLocation] = useState(null);
    const [swiperOpen, setSwiperOpen] = useState(true);
    const sideNavRef = useRef();
    const bottomNavRef = useRef();
    const statePriceDiffMode = useState(false);
    const [yearHistPriceFilter, setYearHistPriceFilter] = useState([]);
    const [yearCrimeFilter, setYearCrimeFilter] = useState([]);
    const schoolsState = useState('KS2PercentExpectedStandard');
    const [schoolsFilter, setSchoolsFilter] = schoolsState;
    const demographicsState = useState("PercentWhiteBritish");
    const [demographicsFilter, setDemographicsFilter] = demographicsState;
    const [sideViewWidth, setSideViewWidth] = useState(0);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [triggerHalfOpen, setTriggerHalfOpen] = useState(false);
    const [satView, setSatView] = useState(false);


    const [clusterPoints, setClusterPoints] = useState([]);
    const [activePropertyCoords, setActivePropertyCoords] = useState(null);
    const [isFullscreen, setIsFullscreen] = React.useState(false);
    const [tabState, setTabState] = useState(0);
    const prevLoc = useRef(null);

    // Watch for fullscreenchange
    useEffect(() => {
        if (document) {
            if (isFullscreen) {
                document.body.requestFullscreen();
            }
            else if (Boolean(document.fullscreenElement)) {
                document.exitFullscreen();
            }
        }
    }, [isFullscreen]);

    React.useEffect(() => {
        function onFullscreenChange() {
            setIsFullscreen(Boolean(document.fullscreenElement));
        }

        document.addEventListener('fullscreenchange', onFullscreenChange);

        return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
    }, []);


    useEffect(() => {
        if (propertyCardClicked) {
            setProductLoading(true);
            setProduct(propertyCardClicked);
            prevLoc.current = { longitude: lng, latitude: lat, zoom: zoom };
        }
    }, [propertyCardClicked]);

    useEffect(() => {
        prevLoc.current = { longitude: lng, latitude: lat, zoom: zoom };
    }, [propertyMarkerClicked]);

    useEffect(() => {
        if (productOpen && propertyCardActive) {
            setProductLoading(true);
            setProduct(propertyCardActive);
        }
    }, [propertyCardActive]);

    useEffect(() => {
        if (product) {
            setSwiperOpen(false);
            setPoly(product.PolyJson);
            setSchools(product.Schools);
            setCrimes(product.Crime);
            setProductData({ lon: product.Location.Longitude, lat: product.Location.Latitude, id: product.Id });
            setProductOpen(true);
            setProductLoading(false);
        }
    }, [product]);

    useEffect(() => {
        if (selectedSchool) {
            setSwiperOpen(false);
        }
        else if (!productOpen) {
            setSwiperOpen(true);
        }
    }, [selectedSchool, productOpen]);

    useEffect(() => {
        if (!productOpen) {
            setSwiperOpen(true);
            setProductData(null);
            setPoly(null);
            setSchools(null);
            setCrimes(null);
            setPropertyCardClicked(false);
            let flyObj = {
                left: sideNavRef.current ? sideNavRef.current.offsetWidth : 0,
                right: 0,
                loc: prevLoc.current
            }
            setToggleFly(flyObj);
        }
        else if(product != null) {
            let flyObj = {
                left: sideNavRef.current ? sideNavRef.current.offsetWidth : 0,
                right: sideViewWidth,
                loc: { longitude: product.Location.Longitude, latitude: product.Location.Latitude }
            }
            console.log(flyObj);
            setToggleFly(flyObj);
        }
    }, [productOpen, sideNavRef, product]);

    useEffect(() => {
        if (productOpen && triggerHalfOpen) {
            let flyObj = {
                left: sideNavRef.current ? sideNavRef.current.offsetWidth : 0,
                right: sideViewWidth,
                loc: { longitude: product.Location.Longitude, latitude: product.Location.Latitude }
            }
            setTriggerHalfOpen(false);
            setToggleFly(flyObj);
        }
    }, [triggerHalfOpen, product, productOpen])

    return (
        <div className='fullHeight' >

            <div className="fixer left"  >

                <div className={classes.fabCont}>

                    <MapLayerDial className={classes.fab} layerSetters={[setShowSchools, setShowCrime, setShowHistoricPrices, setSatView, setShowProps, showSchools, showCrime, showHistoricPrices, satView, showProps]} />

                    <Button color="secondary"
                        aria-label="add"
                        variant="contained"
                        className={classes.fab}
                        startIcon={<TuneRoundedIcon />}
                        onClick={() => setShowFilters(true)}>Filters</Button>




                </div>

            </div>
            <Box className="abs">
                <TopView open={showHistoricPrices} setOpen={(a) => { }} >
                    <PriceHistoryControl visible={showHistoricPrices} statePriceDiffMode={statePriceDiffMode} setFilter={setYearHistPriceFilter} />
                </TopView>
                <TopView open={showCrime} setOpen={(a) => { }}>
                    <CrimeControl visible={showCrime} setFilter={setYearCrimeFilter} />
                </TopView>
                <TopView open={showSchools} setOpen={(a) => { }} >
                    <SchoolsControl visible={showSchools} schoolsState={schoolsState} demographicsState={demographicsState} />
                </TopView>

                {/*<BottomSwipeableDrawer open={productOpen} setOpen={setProductOpen}>*/}
                {/*    <ProductView item={product} setOpen={setProductOpen} />*/}
                {/*</BottomSwipeableDrawer>}*/}
                <MobileSelector
                    mobileComponent={
                        <BottomSheetMobile open={productOpen} setOpen={setProductOpen} triggerHalfOpen={triggerHalfOpen}
                                header={
                                    <PropertyTabBar setOpen={setProductOpen} setValue={setTabState} value={tabState} setTriggerHalfOpen={setTriggerHalfOpen} />
                                }
                                content={
                                    <TabPanels item={product} value={tabState} />
                                }
                        /> }
                    desktopComponent={
                        <RightDrawer open={productOpen} setOpen={setProductOpen} setSideViewWidth={setSideViewWidth} >
                            <ProductView item={product}
                                tabHeader={
                                    <PropertyTabBar setOpen={setProductOpen} setValue={setTabState} value={tabState} setTriggerHalfOpen={setTriggerHalfOpen} />
                                }
                                tabPanels={
                                    <TabPanels item={product} value={tabState} />
                                } />
                        </RightDrawer>
                    }
                />


                {/*<SideView open={productOpen} setOpen={setProductOpen} setSideViewWidth={setSideViewWidth}>*/}
                {/*    <ProductView item={product} setOpen={setProductOpen} />*/}
                {/*</SideView>*/}
                <SideView open={selectedSchool != null} setOpen={(e) => setSelectedSchool(null)} setSideViewWidth={setSideViewWidth}>
                    <SchoolView item={selectedSchool} setOpen={(e) => setSelectedSchool(null)} />
                </SideView>
                <MainFilter open={showFilters} setOpen={setShowFilters} filterData={data} filterTerms={filterTerms} setFilterTerms={setFilterTerms} />
            </Box>
            {console.log("yearCrimeFilter", yearCrimeFilter)}
            <MapBox data={data} setData={setData} lng={lng} setLng={setLng} lat={lat} setLat={setLat} zoom={zoom}
                setZoom={setZoom} bounds={bounds} setBounds={setBounds} setPropertyMarkerClicked={setPropertyMarkerClicked} poly={poly} toggleFly={toggleFly}
                schools={schools} crimes={crimes} satView={satView} productData={productData} setClusterPoints={setClusterPoints}
                activePropertyCoords={activePropertyCoords} setActivePropertyCoords={setActivePropertyCoords} showHistoricPrices={showHistoricPrices} showCrime={showCrime}
                priceDiffMode={statePriceDiffMode[0]} yearHistPriceFilter={yearHistPriceFilter} yearCrimeFilter={yearCrimeFilter} showSchools={showSchools} schoolsFilter={schoolsFilter}
                demographicsFilter={demographicsFilter} setPropertyCardClicked={setPropertyCardClicked} showProps={showProps} setSelectedSchool={setSelectedSchool} />
            <div className="abs">
                <BottomNav open={swiperOpen} setOpen={setSwiperOpen} ref={bottomNavRef} showProps={showProps}>
                    <PropSwiper data={data} setPropertyCardClicked={setPropertyCardClicked} setPropertyCardActive={setPropertyCardActive}
                        setActivePropertyCoords={setActivePropertyCoords} propertyMarkerClicked={propertyMarkerClicked} clusterPoints={clusterPoints}
                        setPropertyMarkerClicked={setPropertyMarkerClicked} latlon={[lat, lng]} productOpen={productOpen} >
                    </PropSwiper>
                </BottomNav>
            </div>
            <QueryController setData={setData} bounds={bounds} zoom={zoom} filterTerms={filterTerms} />
            <SimpleBackdrop open={productLoading} />
        </div>
    );

}

export default MainView;