import React, { useEffect, useState, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "32px",
        marginBottom: "10px",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "800px",
        minWidth: "320px"
        //height: "80%",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[8],
        //padding: theme.spacing(0, 0, 3),
        //overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        borderRadius: "10px",
    },
    flexy: {
        flex: 1,
        justifyContent: "center",
        flexDirection: "column",
        display: "flex"
    },
    flexy0: {
        flex: 0,
        justifyContent: "center",
        flexDirection: "column",
        display: "flex"
    },
    header: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(1, 1, 1),
        borderRadius: "10px",
        //justifyContent: "center"
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(1, 3, 1),
        borderRadius: "10px",
        //justifyContent: "center"
    },
    body: {
        backgroundColor: theme.palette.background.paper,
        borderBottom: '1px solid #000',
        borderTop: '1px solid #000',
        padding: theme.spacing(1, 5, 1),
        overflowY: "auto",
        flexGrow: 1,
        //height: "100%",
        width: "100%",
    },
    button: {
        whiteSpace: "nowrap",
    },
    blkColor: {
        fontWeight: 600,
        color: "black"
    }
}));



const FadeModal = ({ children, open, setOpen, heading, cnt }) => {
    const classes = useStyles();
    const [restrict, setRestrict] = useState(true);
    //const [firstLoad, setFirstLoad] = useState(true);

    //useEffect(() => {
    //    setOpen(toggle);
    //}, [toggle]);

    //const handleOpen = () => {
    //    setOpen(true);
    //};

    //const handleClose = () => {
    //    setOpen(false);
    //};

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={() => setOpen(false)}
                keepMounted
                BackdropComponent={Backdrop}
                disableRestoreFocus={true}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Slide direction="up" in={open} >
                    <Paper className={classes.paper} elevation={0}>
                        <Paper className={classes.header} elevation={0}>
                            <div className={classes.flexy}>
                                <div>
                                    <IconButton color="secondary"
                                        aria-label="add"
                                        className={classes.button}
                                        onClick={() => setOpen(false)}
                                    ><CancelIcon /></IconButton >
                                </div>
                            </div>
                            <div className={classes.flexy}>
                                <div>
                                    <Typography variant="h6" align="center">{heading}</Typography>
                                </div>
                            </div>
                            <div className={classes.flexy}>
                                <div style={{ "display": "flex", "justifyContent": "flex-end", "alignItems":"center"}}>
                                    <Switch checked={restrict} onChange={() => setRestrict(a=>!a)} name="checkedA" />
                                    <Typography variant="body2" align="center">Restrict to map view</Typography>
                                    
                                </div>
                            </div>
                        </Paper>
                        <Paper className={classes.body} elevation={0} square={true}>
                            {children}
                        </Paper>
                        <Paper className={classes.footer} elevation={0}>
                            <div className={classes.flexy}>
                                <div>
                                    <Link
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            console.info("I'm a button.");
                                        }}
                                    >Clear All
                                    </Link>
                                </div>
                            </div>
                            <div className={classes.flexy}>
                                <div />
                            </div>
                            <div className={classes.flexy0}>
                                <Button color="secondary"
                                    aria-label="add"
                                    variant="contained"
                                    className={classes.button}
                                    classes={{ label: classes.blkColor }}
                                    onClick={() => setOpen(false)}
                                >Show {cnt} properties</Button>
                            </div>
                        </Paper>
                    </Paper>
                </Slide>
            </Modal>
        </div>
    );
}

export default memo(FadeModal);