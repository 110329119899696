import React, { useRef, useEffect, useState, memo, forwardRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2)
    },
    hide: {
        display: "none"
    },
    drawer: {
        flexShrink: 0,
    },
    drawerPaper: {
        border: "none",
        width: "100%",
        [theme.breakpoints.up(theme.breakpoints.values.phone)]: {
            minWidth: "300px",
        },
        [theme.breakpoints.down(theme.breakpoints.values.phone)]: {
            minWidth: "100%",
        },
        height: "400px",
        backgroundColor: 'rgba(255, 255, 255, 0)',
    }
}));

const BottomNav = ({ open, setOpen, showProps, children }, ref) => {

    const classes = useStyles();
    console.log("bottom render");
    return (

        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="bottom"

            open={open && showProps}
            onClose={() => setOpen(false)}
            classes={{
                paper: classes.drawerPaper
            }}
        >
            <div ref={ref}>
                {children}
            </div>
        </Drawer>
    );
}

export default memo(forwardRef(BottomNav));