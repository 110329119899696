import React, { memo } from 'react';
//import { DataGrid } from '@mui/x-data-grid';
import BasicTable from './BasicTable';

const PriceHistoryPopup = ({ data }) => {

    let rows = data.agg.split('|').filter(a => a.length>3).map(a => {
        let row = a.split(';');
        return {
            address: `${row[2]}, ${row[3]}, ${data.address}`.replace(", ,", ",").replace(/^(,\s)+/g, ''),
            price: row[0],
            sqft: row[4],
            type: row[7],
            freehold: row[6],
            rooms: row[5],
            constructionage: row[8],
            date: row[1]
        };
    });

    const columns = [
        { field: 'date', headerName: 'Sold Date' },
        { field: 'address', headerName: 'Address' },
        { field: 'price', headerName: 'Price', type: 'number' },
        { field: 'sqft', headerName: 'Square Feet' },
        { field: 'type', headerName: 'Type' },
        { field: 'freehold', headerName: 'Freehold?' },
        { field: 'rooms', headerName: 'Num Major Rooms' },
        { field: 'constructionage', headerName: 'Age' }
    ];
    console.log("MEEEP", data);
    let tabDataTemp = rows.map(a => [a.date, a.address, a.price, a.sqft, a.type, a.freehold, a.rooms, a.constructionage]);
    let header = [columns[0].headerName, columns[1].headerName, columns[2].headerName, columns[3].headerName, columns[4].headerName, columns[5].headerName, columns[6].headerName, columns[7].headerName];

    return (
        <BasicTable header={header} data={tabDataTemp} />);
        //<div style={{ height: 400, width: '100%' }}>
        //    {/*<DataGrid*/}
        //    <div
        //        rows={rows}
        //        columns={columns}
        //        initialState={{
        //            pagination: {
        //                paginationModel: { page: 0, pageSize: 5 },
        //            },
        //        }}
        //        pageSizeOptions={[5, 20, 50]}
        //        checkboxSelection
        //    />
        //</div>);


}


export default memo(PriceHistoryPopup);