import { useRef, memo, useEffect } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'

// if setting up the CSS is tricky, you can add this to your page somewhere:
// <link rel="stylesheet" href="https://unpkg.com/react-spring-bottom-sheet/dist/style.css" crossorigin="anonymous">
//import 'react-spring-bottom-sheet/dist/style.css'
import '../../bottomSheet.css'



const BottomSheetMobile = ({ open, setOpen, header, content, triggerHalfOpen }) => {
    const sheetRef = useRef();
    useEffect(() => {
        console.log("called triggerhalfopen");
        if (triggerHalfOpen && sheetRef.current) {
            sheetRef.current.snapTo(({ maxHeight }) => 0.25 * maxHeight);
        }
    }, [triggerHalfOpen])

    return (
        <>
            <BottomSheet
                ref={sheetRef} 
                open={open}
                blocking={false}
                onDismiss={() => setOpen(false)}
                snapPoints={({ maxHeight }) => [
                    maxHeight - maxHeight / 10,
                    maxHeight * 0.25,
                ]}
                header={header}
                defaultSnap={({ lastSnap, snapPoints }) =>
                    Math.max(...snapPoints)}
            >
                {content}
            </BottomSheet>
        </>
    )
}

export default memo(BottomSheetMobile);



///const [value, setValue] = React.useState(0);