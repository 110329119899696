import React, { useState, memo, useRef, Component, useEffect } from 'react';
import { Paper, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import KeyValue from '../reusable/KeyValue';
import IfNullOrEmpty from '../reusable/IfNullOrEmpty';
import Icon from '@mdi/react';

import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import FenceOutlinedIcon from '@mui/icons-material/FenceOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined'; 
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined';
import NetworkCheckOutlinedIcon from '@mui/icons-material/NetworkCheckOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import CurrencyPoundOutlinedIcon from '@mui/icons-material/CurrencyPoundOutlined';
import ElderlyIcon from '@mui/icons-material/Elderly';
import { mdiBed, mdiBedOutline, mdiShower, mdiArrowExpandAll, mdiCurrencyGbp } from '@mdi/js';
import BalanceIcon from '@mui/icons-material/Balance';
import PieChartIcon from '@mui/icons-material/PieChart';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const HeroData = ({ data }) => {

    const [propType, setPropType] = useState(null);
    const [urbanClass, setUrbanClass] = useState(null);
    const [broadband, setbroadband] = useState(null);
    const [rows, setRows] = useState([]);
    const classes = useStyles();

    function createData(name, value) {
        return { name, value };
    }

    const cleanPropType = (prop) => {
        if (prop.toLowerCase() == "semi-detached") {
            return "Semi";
        }
        if (prop.toLowerCase() == "end of terrace") {
            return "End Terrace";
        }

        return prop;
    }

    const cleanEpc = (data) => {
        if (data.EpcData != null && data.EpcData.CurrentEnergyRating) {
            return data.EpcData.CurrentEnergyRating
        }
        return null;
    }

    const cleanLand = (data) => {
        if (data.Tenure != "RENTAL" && data.Tenure != "LEASEHOLD"
            && data.PropType.toLowerCase().includes("flat") == false
            && data.PropType.toLowerCase().includes("apartment") == false) {
            if (data.LocationAccurate) {
                return data.PlotAcres;
            }
            else {
                return `${data.PlotAcres}*`
            }
        }
    }

    const cleanOwnership = (data) => {
        return data.OwnershipType.toLowerCase().includes("non-shared") ? "No" : "Yes";
    }

    useEffect(() => {

        if (data != null) {

            if (data.Broadbandcoverage != null && data.Broadbandcoverage.maxPredictedDown != null) {
                setbroadband(data.Broadbandcoverage.maxPredictedDown);
            }

            if (data.PropType != undefined && data.PropType != null && data.PropType != 0 && String(data.PropType).trim().length > 0 && data.PropType != "null") {
                setPropType(cleanPropType(data.PropType));
            }
            else if (data.EpcData != undefined && data.EpcData != null) {
                setPropType(cleanPropType(data.EpcData.BuiltForm));
            }

            if (data.RuralUrbanClassification) {
                let str = data.IsSparse ? "Sparse " : "";
                
                switch (data.RuralUrbanClassification) {
                    case 1:
                        setUrbanClass(str + "Big City");
                        break;
                    case 2:
                        setUrbanClass(str + "Small City");
                        break;
                    case 3:
                        setUrbanClass(str + "Town");
                        break;
                    case 4:
                        setUrbanClass(str + "Suburb");
                        break;
                    case 5:
                        setUrbanClass(str + "Village");
                        break;
                    case 6:
                        setUrbanClass(str + "Countryside");
                        break;
                    default:
                        setUrbanClass("");
                }
            }
            //RuralUrbanClassification | IsSparse


            ///////////////////////

            let rowsData = [
                createData('Bedrooms', data.Bedrooms),
                createData('Bathrooms', data.Bathrooms),
                createData('Square feet', `${data.SqFtParsed} sqft`),
                createData('Price per sqft', `£${data.PricePerSqFoot}`),


                createData('Tenure', data.Tenure),
                //data.PropType  ?? EpcData.BuiltForm

                //data.CurrentEnergyRating
                createData('Max broadband speed', `${Math.round(data.MaxBroadbandSpeed / 1000)}MB/S`),
                //PlotAcres
                //RuralUrbanClassification | IsSparse
                //optional gubbins.
                // Notable. "IsPublicNotice": false, "IsAuction": false, "IsNewBuild": false, "IsExLocalAuthority": false, "IsNationalPark": false,
                createData('Ownership', data.OwnershipType),
                //createData('Is retirement property', data.Retirement ? "Yes" : "No"),
            ];

            const deprivationEngland = 32844;
            if (data.IndexMultipleDeprivation && data.IndexMultipleDeprivation > 0) {
                let depPercentile = 100 * data.IndexMultipleDeprivation / deprivationEngland;
                let depPercRound = 100 - Math.round(depPercentile); //.toFixed(1);
                if (depPercRound >= 95) {
                    rowsData.push(createData("Deprivation", `Very deprived (${depPercRound} percentile)`));
                } else if (depPercRound >= 80) {
                    rowsData.push(createData("Deprivation", `Deprived (${depPercRound} percentile)`));
                } else if (depPercRound >= 60) {
                    rowsData.push(createData("Deprivation", `Slightly deprived (${depPercRound} percentile)`));
                } else if (depPercRound >= 40) {
                    rowsData.push(createData("Deprivation", `Average (${depPercRound} percentile)`));
                } else if (depPercRound >= 20) {
                    rowsData.push(createData("Deprivation", `Below average deprivation (${depPercRound} percentile)`));
                } else if (depPercRound >= 5) {
                    rowsData.push(createData("Deprivation", `Significantly below average deprivation (${depPercRound} percentile)`));
                } else {
                    rowsData.push(createData("Deprivation", `Least deprived (${depPercRound} percentile)`));
                }


            }

            let allowedTenures = ["unknown", "owner-occupied"];
            //if (data.locationAccurate &&
            //    (data.tenure == "FREEHOLD" ||
            //        (data.epcData != null && allowedTenures.includes(data.epcData.tenure))
            //    )
            //) {
        }
    }, [data]);

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6} md={4} lg={3}>
                    <KeyValue keyx="Bedrooms" value={data.Bedrooms} Icon={BedOutlinedIcon} />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <KeyValue keyx="Bathrooms" value={data.Bathrooms} Icon={BathtubOutlinedIcon} />
                </Grid>

                <IfNullOrEmpty value={data.SqFtParsed}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Internal Area" value={data.SqFtParsed + " ft" + String.fromCharCode(178)} Icon={SquareFootOutlinedIcon} />
                    </Grid> 
                </IfNullOrEmpty>
                <IfNullOrEmpty value={data.PricePerSqFoot}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Price per sqft" value={Math.round(data.PricePerSqFoot)} Icon={CurrencyPoundOutlinedIcon} />
                    </Grid>
                </IfNullOrEmpty>

                <IfNullOrEmpty value={data.Tenure}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Tenure" value={data.Tenure} Icon={KeyOutlinedIcon} adjustCase/>
                    </Grid>
                </IfNullOrEmpty>

                <IfNullOrEmpty value={propType}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Property" value={propType} Icon={HomeWorkOutlinedIcon} />
                    </Grid>
                </IfNullOrEmpty>

                <IfNullOrEmpty value={cleanEpc(data)}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Energy Rating" value={cleanEpc(data)} Icon={EnergySavingsLeafOutlinedIcon} />
                    </Grid>
                </IfNullOrEmpty>

                <IfNullOrEmpty value={broadband}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Max broadband speed" value={`${broadband}MB/S`} Icon={NetworkCheckOutlinedIcon} />
                    </Grid>
                </IfNullOrEmpty>

                <IfNullOrEmpty value={data.PlotAcres}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Acres" value={cleanLand(data)} Icon={FenceOutlinedIcon} />
                    </Grid>
                </IfNullOrEmpty>

                <IfNullOrEmpty value={urbanClass}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Urban Class" value={urbanClass} Icon={LocationCityOutlinedIcon} />
                    </Grid>
                </IfNullOrEmpty>


                <IfNullOrEmpty value={data.IsPublicNotice}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Repossession" value={data.IsPublicNotice ? "Yes" : "No"} Icon={BalanceIcon} />
                    </Grid>
                </IfNullOrEmpty>


                <IfNullOrEmpty value={data.IsAuction}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Auction" value={data.IsAuction ? "Yes" : "No"} Icon={GavelOutlinedIcon} />
                    </Grid>
                </IfNullOrEmpty>

                <IfNullOrEmpty value={data.IsNewBuild}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="New Build" value={data.IsNewBuild ? "Yes" : "No"} Icon={ConstructionOutlinedIcon} />
                    </Grid>
                </IfNullOrEmpty>

                <IfNullOrEmpty value={data.IsExLocalAuthority}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Ex-Local Authority" value={data.IsExLocalAuthority} Icon={AccountBalanceOutlinedIcon} />
                    </Grid>
                </IfNullOrEmpty>

                <IfNullOrEmpty value={data.IsNationalPark}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="National Park" value={data.IsNationalPark ? "Yes" : "No"} Icon={ParkOutlinedIcon} />
                    </Grid>
                </IfNullOrEmpty>

                <IfNullOrEmpty value={data.Retirement}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Retirement" value={data.Retirement ? "Yes" : "No"} Icon={ElderlyIcon} />
                    </Grid>
                </IfNullOrEmpty>

                <IfNullOrEmpty value={data.OwnershipType}>
                    <Grid item xs={6} md={4} lg={3}>
                        <KeyValue keyx="Shared Ownership" value={cleanOwnership(data)} Icon={PieChartIcon} />
                    </Grid>
                </IfNullOrEmpty>

            </Grid>
        </div>
    );
}


export default memo(HeroData);