import React, { useState, memo, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import BasicTable from '../BasicTable';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const distStr = (num) => {
    if (num >= 1000) {
        let rnd = Math.round(num / 100) / 10;
        return `${rnd} km`
    }
    else {
        return `${num} m`
    }
}


const schoolSizeMax = (str) => {
    let re = new RegExp('.+\\s(?<size>\\d+.+)');
    if (str != null) {
        let match = re.exec(str);
        console.log(match);
        if (match != null && match.groups != null && match.groups.size != null && match.groups.size.length > 0) {
            return match.groups.size;
        }
    }
    return str;
}

const SchoolDetail = ({ data }) => {

    const [hideCatchment, setHideCatchment] = React.useState(true);
    const [hideIndependent, setHideIndependent] = React.useState(true);
    const [hideReligious, setHideReligious] = React.useState(true);

    const [schoolDataP, setSchoolDataP] = useState(null);
    const [headerDataP, setHeaderDataP] = useState(null);

    const [schoolDataS, setSchoolDataS] = useState(null);
    const [headerDataS, setHeaderDataS] = useState(null);



    useEffect(() => {
        if (data != null && data.Schools != null && data.Schools.length > 0) {
            //no default sorting for. Apply default sorting later, and add a more functional table with sort and filter functionality.
            let headersP = ["Name", "Distance", "Offsted", "Size", "Avg Class Size", "KS2 Expected Standard", "KS2 Higher Standard"]
            let headersS = ["Name", "Distance", "Offsted", "Size", "Avg Class Size", "KS4 Progres8", "KS4 Attainment8", "KS5 Average Grade"]
            setHeaderDataP(headersP);
            setHeaderDataS(headersS);

            let schoolsP = [];
            let schoolsS = [];
            data.Schools.forEach(a => {
                if (!hideCatchment ||
                    (a.DistanceInMetres <= a.CatchmentMetresMed
                        || a.OfstedRatingString == "Independent" //include independent schools
                        || (a.Denomination != null && a.Denomination != "No religious character") //include religious schools
                    )) {

                    if (!hideIndependent || a.OfstedRatingString != "Independent") {

                        if (!hideReligious || a.Denomination == null || a.Denomination == "No religious character") {
                            if (a.SchoolTypeSimple == "Primary" || a.SchoolTypeSimple == "All-Through") {
                                schoolsP.push([a.SchoolName, distStr(a.DistanceInMetres), a.OfstedRatingString, schoolSizeMax(a.SchoolSize),
                                a.ClassSizeAvgPrimary,
                                a.KS2PercentExpectedStandard == null ? "" : `${a.KS2PercentExpectedStandard}%`,
                                a.KS2PercentHigherStandard == null ? "" : `${a.KS2PercentHigherStandard}%`]);
                            }

                            if (a.SchoolTypeSimple == "Secondary" || a.SchoolTypeSimple == "All-Through") {
                                schoolsS.push([a.SchoolName, distStr(a.DistanceInMetres), a.OfstedRatingString, schoolSizeMax(a.SchoolSize),
                                a.classSizeAvgSecondary, a.KS4Progress8, a.KS4Attainment8, a.KS5Best3AverageGrade]);
                            }
                        }
                    }
                }
            });
            setSchoolDataS(schoolsS);
            setSchoolDataP(schoolsP);

        }
    }, [data, hideCatchment, hideIndependent, hideReligious]);

    return (
        <Paper style={{ padding: "10px" }}>
            <FormControlLabel
                control={<Switch checked={hideCatchment} onChange={() => setHideCatchment(a => !a)} />}
                label="Hide schools outside catchment area."
            />
            <FormControlLabel
                control={<Switch checked={hideIndependent} onChange={() => setHideIndependent(a => !a)} />}
                label="Hide Independent schools."
            />
            <FormControlLabel
                control={<Switch checked={hideReligious} onChange={() => setHideReligious(a => !a)} />}
                label="Hide religious schools."
            />
            <Typography variant="subtitle1" gutterBottom>Primary Schools</Typography>
            <BasicTable data={schoolDataP} header={headerDataP} />
            <br />
            <Typography variant="subtitle1" gutterBottom>Secondary Schools</Typography>
            <BasicTable data={schoolDataS} header={headerDataS} />
            <br />
        </Paper>
    );
}


export default memo(SchoolDetail);