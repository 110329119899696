import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const NestedRow = ({ row, i, subRows, subHeaderData }) => {
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();

    return(
    <>
        <TableRow className={classes.root} key={i}>
            <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            {row.map((col) => (
                <TableCell component="th" scope="row">{col}</TableCell>
            ))}
        </TableRow>

        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={row.length + 1}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Table size="small" aria-label="purchases">
                            {subHeaderData}
                            <TableBody>
                                {subRows[i].map((subRow, si) => (
                                    <TableRow key={si}>
                                        {
                                            subRow.map((subcol) => (
                                                <TableCell component="th" scope="row">{subcol}</TableCell>
                                            ))
                                        }
                                    </TableRow>
                                )
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>)
}


const NestedTable = ({ header, data, subHeader, subData }) => {
    const [rows, setRows] = useState([]);
    const [subRows, setSubRows] = useState([]);
    const [headerData, setHeaderData] = useState(null);
    const [subHeaderData, setSubHeaderData] = useState(null);

    useEffect(() => {
        if (data != null) {
            setRows(data);
        }
    }, [data]);

    useEffect(() => {
        if (header != null && header.length > 0) {
            let tHeader = (
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {header.map(a =>
                            (<TableCell>{a}</TableCell>)
                        )}
                    </TableRow>
                </TableHead>
            );
            setHeaderData(tHeader);
        }
    }, [header]);

    useEffect(() => {
        if (subData != null) {
            setSubRows(subData);
        }
    }, [subData]);

    useEffect(() => {
        if (subHeader != null && subHeader.length > 0) {
            let tHeader = (
                <TableHead>
                    <TableRow>
                        {subHeader.map(a =>
                            (<TableCell>{a}</TableCell>)
                        )}
                    </TableRow>
                </TableHead>
            );
            setSubHeaderData(tHeader);
        }
    }, [subHeader]);



    return (
        <Paper>
            <TableContainer component={Paper}>
                <Table aria-label="nested table" size="small">
                    {headerData}
                    <TableBody>
                        {rows.map((row, i) => (
                            <NestedRow row={row} i={i} subRows={subRows} subHeaderData={subHeaderData} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        </Paper>
    );
}

export default memo(NestedTable);

