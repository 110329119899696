import { memo } from "react";
import MediaQuery from 'react-responsive'
import ProgressiveImage from "react-progressive-graceful-image";
import ResponsiveImage from "../ResponsiveImage.jsx"; 
import 'swiper/swiper.min.css'
import 'swiper/modules/a11y/a11y.min.css'
import 'swiper/modules/mousewheel/mousewheel.min.css'
import 'swiper/modules/virtual/virtual.min.css'

import "./effect-carousel.css";
import "./MySwiper.css";

//export default function ProductTile({ product }) {
const ProductTile = ({ product, swiper, idx, setClicked }) => {

    return (
        <div style={{ pointerEvents: "auto", minWidth: "200px" }} className="swiper-slide-content swiper-carousel-animate-opacity">
            <div className="swiper-lazy-preloader"></div>
            <div className="swiper-carousel-animate-opacity base-tile">
                <div className="box" onClick={() => {
                    console.log(swiper());
                    let swip = swiper();
                    if (swip != null) {
                        if (swip.activeIndex < swip.clickedIndex) {
                            swip.slideNext();
                        } else if (swip.activeIndex > swip.clickedIndex) {
                            swip.slidePrev();
                        } else if (swip.activeIndex == swip.clickedIndex) {
                            console.log(swip.activeIndex);
                            console.log(product);
                            setClicked(product.id);
                        }
                    }

                }} >
                    <div className="arow content">
                        <div className="innerbox">
                            <div className="column lft">
                                <ResponsiveImage
                                    id={product.images[0]}
                                    sizes="(max-width: 1360px) 320px, 590px"
                                    className="tileimg"
                                />

                            {/*    <ProgressiveImage*/}
                            {/*        src={"https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + product.images[0] + "-590.avif"}*/}
                            {/*        placeholder={"https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + product.images[0] + "-64.avif" }*/}
                            {/*    >*/}
                            {/*        {(src, loading, srcSetData) => (*/}
                            {/*            <img*/}
                            {/*                src={src}*/}
                            {/*                alt="an image"*/}
                            {/*                className="tileimg"*/}
                            {/*            />*/}
                            {/*        )}*/}
                            {/*</ProgressiveImage>*/}
                            </div>
                            <div className="column rgt">
                                <div className="box">
                                    <div className="arow item">
                                        <ResponsiveImage
                                            id={product.images[1]}
                                            sizes="320px"
                                            className="tileimg leftborder"
                                        />
                                    </div>
                                    <div className="arow item">
                                        <ResponsiveImage
                                            id={product.images[2]}
                                            sizes="320px"
                                            className="tileimg leftborder topborder"
                                        />
                                    </div>
                                </div>
                            </div>
                            <MediaQuery minWidth={1024}>
                                <div className="column rgt">
                                    <div className="box">
                                        <div className="arow item">
                                            <ResponsiveImage
                                                id={product.images[3]}
                                                sizes="320px"
                                                className="tileimg leftborder"
                                            />
                                        </div>
                                        <div className="arow item">
                                            <ResponsiveImage
                                                id={product.images[4]}
                                                sizes="320px"
                                                className="tileimg leftborder topborder"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </MediaQuery>
                        </div>
                    </div>
                    <div className="arow footer">
                        <div className="textContainer">
                            <div className="column">
                                <p className="swiper-slide-content swiper-slide-title tiletext">&pound;{Number(product.price).toLocaleString()}</p>
                            </div>
                            <div className="column">
                                <p className="swiper-slide-content swiper-slide-title tiletext">Beds:{product.bedrooms}</p>
                            </div>
                            <div className="column">
                                <p className="swiper-slide-content swiper-slide-title tiletext">Bath:{product.bathrooms}</p>
                            </div>
                            <div className="column">
                                <p className="swiper-slide-content swiper-slide-title tiletext">Sqft:{product.sqFt}</p>
                            </div>
                            <div className="column">
                                <p className="swiper-slide-content swiper-slide-title tiletext">Postcode:{product.postCode}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(ProductTile);