import React, { useState, memo, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const DemographicDetail = ({ data }) => {

    const [someState, setSomeState] = useState(null);

    useEffect(() => {

    }, [data]);

    return (
        <Paper>
            <Typography variant="body2" gutterBottom>content</Typography>
        </Paper>
    );
}


export default memo(DemographicDetail);