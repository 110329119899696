import React, { useState, memo, useEffect } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@mui/material/Typography';
import Box from '@material-ui/core/Box';
import Stack from '@mui/material/Stack';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { ReactComponent as Icon } from './icon.svg';
import { useTheme } from '@material-ui/core/styles';

const KeyValue = ({ keyx, value, Icon, adjustCase }) => {
    const theme = useTheme();
    const [val, setVal] = useState(value);

    useEffect(() => {
        if (adjustCase && (typeof value == "string") && value.length > 1) {
            setVal(value.charAt(0).toUpperCase() + value.substring(1).toLowerCase());
        }
    }, [adjustCase, value]);
    console.log("Icon", Icon);

    return (
        <Box >
            <Stack spacing={0}>
                <Stack direction="row" spacing={1}>
                    <Icon />
                    <Typography variant="h6" color={theme.palette.text.primary} style={{ textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: "nowrap" }} >
                        {val}
                    </Typography>
                </Stack>
                <Typography variant="caption" color={theme.palette.text.disabled}>
                    {keyx}
                </Typography>
            </Stack>
        </Box>
    );
}


export default KeyValue;