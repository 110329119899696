import React, { useState, memo, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import BasicTable from '../BasicTable';


const distStr = (num) => {
    if (num >= 1000) {
        let rnd = Math.round(num / 100) / 10;
        return `${rnd} km`
    }
    else {
        return `${num} m`
    }
}


const NearestHelper = (label, data, content) => {
    if (data != null && data.length > 0) {
        data.sort((a, b) => a.Distance - b.Distance).forEach(a => {
            let name = a.Tags.name || a.Tags.brand || "unknown";
            (content[label] = content[label] ? content[label] : []).push([
                name,
                a.Tags.denomination || a.Tags.religion ||
                a.Tags.amenity || a.Tags.shop || a.Tags.healthcare || a.Tags.tourism ||
                    a.Tags.leisure || a.Tags.railway || a.Tags.highway || a.Tags.public_transport ||
                     "??",
                distStr(a.Distance)
            ]);
        });
    }
};

const AmenityDetail = ({ data }) => {

    //const [nearestAirport, setNearestAirport] = useState({});
    //const [nearestBarsAndRest, setNearestBarsAndRest] = useState({});
    //const [nearestBus, setNearestBus] = useState({});
    //const [nearestGroceries, setNearestGroceries] = useState({});
    //const [nearestGeneralShops, setNearestGeneralShops] = useState({});
    //const [nearestHotels, setNearestHotels] = useState({});
    //const [nearestLeisure, setNearestLeisure] = useState({});
    //const [nearestMedical, setNearestMedical] = useState({});
    //const [nearestReligious, setNearestReligious] = useState({});
    //const [nearestSupermarkets, setNearestSupermarkets] = useState({});
    //const [nearestTourism, setNearestTourism] = useState({});
    //const [nearestTrains, setNearestTrains] = useState({});
    const [places, setPlaces] = useState({});

    const header = ["Name", "Info", "Distance"];
    useEffect(() => {
        if (data != null && data.PlacesOfInterest != null) {

            let content = {};
            NearestHelper("Nearest Bars and Restaurants", data.PlacesOfInterest.NearestBarsAndRest, content);
            NearestHelper("Nearest Supermarkets", data.PlacesOfInterest.NearestSupermarkets, content);
            NearestHelper("Nearest Grocery Stores", data.PlacesOfInterest.NearestGroceries, content);
            NearestHelper("Nearest General Shops", data.PlacesOfInterest.NearestGeneralShops, content);
            NearestHelper("Nearest Trains", data.PlacesOfInterest.NearestTrains, content);
            NearestHelper("Nearest Bus Stops", data.PlacesOfInterest.NearestBus, content);
            NearestHelper("Nearest Religious Places", data.PlacesOfInterest.NearestReligious, content);
            NearestHelper("Nearest Medical", data.PlacesOfInterest.NearestMedical, content);
            NearestHelper("Nearest Leisure Facilities", data.PlacesOfInterest.NearestLeisure, content);
            NearestHelper("Nearest Tourism Facilities", data.PlacesOfInterest.NearestTourism, content);
            NearestHelper("Nearest Hotels", data.PlacesOfInterest.NearestHotels, content);
            NearestHelper("Nearest Airports", data.PlacesOfInterest.NearestAirport, content);

            //if (data.PlacesOfInterest.NearestAirport != null && data.PlacesOfInterest.NearestAirport.length > 0) {
            //    let cat = "Nearest Aiports";
            //    data.PlacesOfInterest.NearestAirport.sort((a, b) => a.Distance - b.Distance).forEach(a => {
            //        (content[cat] = content[cat] ? content[cat] : []).push([
            //            a.Tags.name ?? a.Tags.brand ?? "unknown",
            //            a.Tags.amenity ?? a.Tags.shop ?? a.Tags.healthcare ?? a.Tags.tourism ?? a.Tags.leisure ?? a.Tags.railway ?? a.Tags.highway ?? a.Tags.public_transport ?? a.Tags.denomination ?? a.Tags.religion ??
            //            distStr(a.distanceM)
            //        ]);
            //    });
            //}

            //data.places.sort((a, b) => a.distanceM - b.distanceM).forEach(a => {
            //    console.log(a);
            //    (content[a.topLevelCategory] = content[a.topLevelCategory] ? content[a.topLevelCategory] : []).push([
            //        a.name,
            //        a.category,
            //        distStr(a.distanceM)
            //    ]);
            //});

            setPlaces(() => content);
        }
    }, [data]);


    return (
        <Paper>
            {places ? Object.keys(places).map(a => (
                <>
                    {console.log(a)}
                    <Typography variant="subtitle1" gutterBottom>{a}</Typography>
                    <BasicTable data={places[a]} header={header} />
                    <br />
                </>)
            ) : (<></>)}
        </Paper>
    );
}


export default memo(AmenityDetail);