import React, { useState, memo, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

const SubsidenceSelect = ({ filterData, setFilter }) => {
    const [data2030, setData2030] = useState(null);
    const [data2070, setData2070] = useState(null);
    //const [risk2030, setRisk2030] = React.useState("Improbable");
    const [risk2070, setRisk2070] = React.useState({
        Improbable: true,
        Possible: true,
        Probable: true,
        Unavailable: true
    });
    const [risk2030, setRisk2030] = React.useState({
        Improbable: true,
        Possible: true,
        Probable: true,
        Unavailable: true
    });

    useEffect(() => {
        if (filterData == null || filterData.exclusionFilters == null || filterData.exclusionFilters.children == null) {
            return;
        }

        let data2030Raw = filterData.exclusionFilters.children.find(a => a.label == "subsidenceRisk2030");
        let data2070Raw = filterData.exclusionFilters.children.find(a => a.label == "subsidenceRisk2070");

        let dataT2030 = {};
        let f = data2030Raw.children.find(a => a.label == "Improbable");
        dataT2030.Improbable = {
            text: `Improbable by 2030 (${f ? f.count : 0})`,
            filter: f,
            cnt: f ? f.count : 0
        };
        f = data2030Raw.children.find(a => a.label == "Probable");
        dataT2030.Probable = {
            text: `Probable by 2030 (${f ? f.count : 0})`,
            filter: f,
            cnt: f ? f.count : 0
        };
        f = data2030Raw.children.find(a => a.label == "Possible");
        dataT2030.Possible = {
            text: `Possible by 2030 (${f ? f.count : 0})`,
            filter: f,
            cnt: f ? f.count : 0
        };
        f = data2030Raw.children.find(a => a.label == "Unavailable");
        dataT2030.Unavailable = {
            text: `Unavailable for 2030 (${f ? f.count : 0})`,
            filter: f,
            cnt: f ? f.count : 0
        };

        let dataT2070 = {};

        f = data2070Raw.children.find(a => a.label == "Improbable");
        dataT2070.Improbable = {
            text: `Improbable by 2070 (${f ? f.count : 0})`,
            filter: f,
            cnt: f ? f.count : 0
        };
        f = data2070Raw.children.find(a => a.label == "Probable");
        dataT2070.Probable = {
            text: `Probable by 2070 (${f ? f.count : 0})`,
            filter: f,
            cnt: f ? f.count : 0
        };
        f = data2070Raw.children.find(a => a.label == "Possible");
        dataT2070.Possible = {
            text: `Possible by 2070 (${f ? f.count : 0})`,
            filter: f,
            cnt: f ? f.count : 0
        };
        f = data2070Raw.children.find(a => a.label == "Unavailable");
        dataT2070.Unavailable = {
            text: `Unavailable for 2070 (${f ? f.count : 0})`,
            filter: f,
            cnt: f ? f.count : 0
        };

        setData2030(dataT2030);
        setData2070(dataT2070);

    }, [filterData]);

    useEffect(() => {
        setFilter(a => {
            //TODO: if any states are false, add the not filter. via setFilter.
            //remove existing filters if exists.
            let newFilter = a.filter(b => b.field != "subsidenceRisk2030");
            let rtnArr = Object.entries(risk2030).filter(a => !a[1]); //get false entries.
            //re-add filters if selected.
            if (rtnArr && rtnArr.length > 0) {
                rtnArr.forEach(v => {
                    let f = data2030[v[0]].filter;
                    if (!f)
                        f = makeFilter(v[0], "subsidenceRisk2030");
                    newFilter.push(f);
                });
            }
            return newFilter;
        });
    }, [risk2030]);

    useEffect(() => {
        setFilter(a => {
            //TODO: if any states are false, add the not filter. via setFilter.
            //remove existing filters if exists.
            let newFilter = a.filter(b => b.field != "subsidenceRisk2070");
            let rtnArr = Object.entries(risk2070).filter(a => !a[1]); //get false entries.
            //re-add filters if selected.
            if (rtnArr && rtnArr.length > 0) {
                rtnArr.forEach(v => {
                    let f = data2070[v[0]].filter;
                    if (!f)
                        f = makeFilter(v[0], "subsidenceRisk2070");
                    newFilter.push(f);
                });
            }
            return newFilter;
        });
    }, [risk2070]);

    const makeFilter = (label, field) => {
        return {
            children: null,
            count: 0,
            displayText: label,
            field: field,
            from: null,
            id: "fakedId",
            label: label,
            to: null,
            type: "NotTerm"
        };
    }

    const handleChange2030 = (event) => {
        setRisk2030(state => {
            let rtn = { ...state, [event.target.name]: event.target.checked };
            return rtn;
        });
        
    };

    const handleChange2070 = (event) => {
        setRisk2070(state => {
            let rtn = { ...state, [event.target.name]: event.target.checked };
            return rtn;
        });
    };


    //const { Improbable, Possible, Probable, Unavailable } = risk2030;

    let render2030 = data2030
        && (
            <>
                <Typography variant="subtitle2" align="left">By 2030</Typography>
                <FormGroup>
                <FormControlLabel value="Improbable" control={<Checkbox checked={risk2030.Improbable} onChange={handleChange2030} name="Improbable" />} label={data2030.Improbable.text} />
                <FormControlLabel value="Possible" control={<Checkbox checked={risk2030.Possible} onChange={handleChange2030} name="Possible" />} label={data2030.Possible.text} />
                <FormControlLabel value="Probable" control={<Checkbox checked={risk2030.Probable} onChange={handleChange2030} name="Probable" />} label={data2030.Probable.text} />
                <FormControlLabel value="Unavailable" control={<Checkbox checked={risk2030.Unavailable} onChange={handleChange2030} name="Unavailable" />} label={data2030.Unavailable.text} />
                </FormGroup>
            </>
        );

    let render2070 = data2070
        && (
        <>
            <Typography variant="subtitle2" align="left">By 2070</Typography>
            <FormGroup>
                <FormControlLabel value="Improbable" control={<Checkbox checked={risk2070.Improbable} onChange={handleChange2070} name="Improbable" />} label={data2070.Improbable.text} />
                <FormControlLabel value="Possible" control={<Checkbox checked={risk2070.Possible} onChange={handleChange2070} name="Possible" />} label={data2070.Possible.text} />
                <FormControlLabel value="Probable" control={<Checkbox checked={risk2070.Probable} onChange={handleChange2070} name="Probable" />} label={data2070.Probable.text} />
                <FormControlLabel value="Unavailable" control={<Checkbox checked={risk2070.Unavailable} onChange={handleChange2070} name="Unavailable" />} label={data2070.Unavailable.text} />
            </FormGroup>
            </>
        );

    return (
        <Paper>
            <Typography variant="h6" align="left">Sudsidence Risks</Typography>
            {render2030}

            {render2070}
        </Paper>
    );
}

export default memo(SubsidenceSelect);