import React, { useState, memo, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import BasicTable from '../BasicTable';

const AreaSummary = ({ data }) => {

    const [dataState, setDataState] = useState(null);

    useEffect(() => {
        if (data != null) {
            let dState = [];

            if (data.OaClassificationName != null && data.OaClassificationName.length > 0) {
                dState.push(["Local area classification", data.OaClassificationName]);
            }

            if (data.SoaClassificationName != null && data.SoaClassificationName.length > 0) {
                dState.push(["Wider area classification", data.SoaClassificationName]);
            }

            if (data.WorkplaceClassificationName != null && data.WorkplaceClassificationName.length > 0) {
                dState.push(["Workplace classification", data.WorkplaceClassificationName]);
            }
            if (data.LocalAuthorityClassificationName != null && data.LocalAuthorityClassificationName.length > 0) {
                dState.push(["Local authority classification", data.LocalAuthorityClassificationName]);
            }
            if (data.PercentQualifications4OrAbove != null && data.PercentQualifications4OrAbove > 0) {
                dState.push(["Percent degree educated", data.PercentQualifications4OrAbove]);
            }
            
            setDataState(dState);
        }
    }, [data]);

    return (
        <Paper>
            <BasicTable data={dataState} /> 
        </Paper>
    );
}


export default memo(AreaSummary);