/**
 * Code from the below medium post, only updated for latest material UI, using a
 * Menu for the popup and with breakpoints that work.
 *
 * https://medium.com/@habibmahbub/create-appbar-material-ui-responsive-like-bootstrap-1a65e8286d6f
 */
import React from "react";
import { Button, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ButtonAppBarCollapse from "./ButtonAppBarCollapse";
import { Link } from 'react-router-dom';

const styles = theme => ({
    root: {
        position: "absolute",
        right: 0
    },
    buttonBar: {
        [theme.breakpoints.down("xs")]: {
            display: "none"
        },
        margin: "10px",
        paddingLeft: "16px",
        right: 0,
        position: "relative",
        width: "100%",
        background: "transparent"
    }
});

const AppBarCollapse = props => (
    <div className={props.classes.root}>
        <ButtonAppBarCollapse>
            <Link to="/" color="inherit">
                <MenuItem>Home</MenuItem>
            </Link>
            <Link to="/about" color="secondary" target="_blank" rel="noopener noreferrer">
                <MenuItem>About</MenuItem>
            </Link>
        </ButtonAppBarCollapse>
        <div className={props.classes.buttonBar} id="appbar-collapse">
            <Link to="/" color="inherit">
                <Button>Home</Button>
            </Link>
            <Link to="/about" color="secondary" target="_blank" rel="noopener noreferrer">
                <Button>About</Button>
            </Link>
        </div>
    </div>
);

export default withStyles(styles)(AppBarCollapse);
