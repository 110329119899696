import React, { useRef, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2)
    },
    hide: {
        display: "none"
    },
    drawer: {
        flexShrink: 0,
    },
    drawerPaper: {
        [theme.breakpoints.up("xs")]: {
            width: "50vw",
            maxWidth: "1200px",
            top: "48px"
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            top: "36px"
        },
        height: "100%",
        pointerEvents: "auto",
        overflow:"visible"
    },
    spacer: {
        height: "64px"
    },
    labels: {
        margin: "0px"
    },
    visRight: {
        flexShrink: 0,
        height: "100%",
        [theme.breakpoints.up("xs")]: {
            display: "block",
        },
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    visBottom: {
        flexShrink: 0,
        height: "100%",
        [theme.breakpoints.up("xs")]: {
            display: "none"

        },
        [theme.breakpoints.down("xs")]: {
            display: "block"
        },
    }
}));

const SideView = ({ open, setOpen, children, cnt, setSideViewWidth }) => {

    const classes = useStyles();
    const drawerBleeding = 56;
    const ref = useRef();


    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                setSideViewWidth(entry.contentRect.width);
            }
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <>
            <div className={classes.visBottom}>

                <SwipeableDrawer
                    className={classes.visBottom}
                    anchor="bottom"
                    open={open}
                    onOpen={() => { }}
                    onClose={() => setOpen(false)}
                    swipeAreaWidth={drawerBleeding}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    PaperProps={{
                        sx: {
                            height: 'calc(100% -64px)', // Adjust as needed
                            top: 64, // Offset for app bar
                        },
                    }}
                >

                    <div style={{ paddingBottom: "40px" }} >
                        {children}
                    </div>
                </SwipeableDrawer>
               
            </div>
            <div className={classes.visRight}>
                <Drawer
                    className={classes.visRight}
                    variant="persistent"
                    anchor="right"
                    open={open}
                    onClose={() => setOpen(false)}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <div ref={ref} style={{ paddingBottom: "40px" }} >
                        {children}
                    </div>
                </Drawer>
            </div>
        </>
    );
}

export default memo(SideView);