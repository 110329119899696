import React, { useRef, useEffect, useState, memo, forwardRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2)
    },
    hide: {
        display: "none"
    },
    drawer: {
        flexShrink: 0,
    },
    drawerPaper: {
        width: "100vw",
        pointerEvents: "none",
        justifyContent: "flex-End",
        backgroundColor: "rgba(255,0,0,0)",
        borderBottom: "0px solid rgba(0, 0, 0, 0)"
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-start"
    },
    labels: {
        //padding: "0px 16px 0px 16px"
        margin: "0px"
    },
    vis: {
        flexShrink: 0,
        height: "100%",
    },
    spacer: {
        [theme.breakpoints.up("xs")]: {
            height: "48px"
        },
        [theme.breakpoints.down("xs")]: {
            height: "32px"
        },
    }
}));

const TopView = ({ open, setOpen, children }) => {

    const theme = useTheme();
    const classes = useStyles();
    const drawerBleeding = 156;
    //32/48
    return (
        <>
            <div className={classes.vis}>
                <Drawer
                    className={classes.vis}
                    variant="persistent"
                    anchor="top"
                    open={open}
                    onClose={() => setOpen(false)}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    
                    <Grid container direction="column" alignItems="flex-end">
                        <div className={classes.spacer} />
                    <div style={{ "overflowY": "hidden" }}>
                        {children}
                        </div>
                    </Grid>
                </Drawer>
            </div>
        </>
    );
}

export default memo(TopView);