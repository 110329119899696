import React, { useRef, useEffect, useState, memo } from 'react';
import ReactDOM from 'react-dom';
import MapBox from './MapBox';

const GeoNormalizer = (neLat, swLat, neLng, swLng) => {
    let percent = 0.10; //10%
    let latDiff = neLat - swLat;
    let lngDiff = neLng - swLng;

    //get diff magnitudes.
    let latMag = Math.pow(10, Math.floor(Math.log(latDiff) / Math.LN10 + 0.000000001) * -1) * 10;
    let lngMag = Math.pow(10, Math.floor(Math.log(lngDiff) / Math.LN10 + 0.000000001) * -1) * 10;
    console.log("latMag", latMag);
    console.log("lngMag", lngMag);
    //normalize diffs
    let latDiffNorm = Math.round(latDiff * latMag) / latMag;
    let lngDiffNorm = Math.round(lngDiff * lngMag) / lngMag;

    let stepLat = 1 / (latDiffNorm * percent);
    let stepLng = 1 / (lngDiffNorm * percent);
    console.log("stepLat", stepLat);
    console.log("stepLng", stepLng);

    let n = Math.floor(neLat * stepLat) / stepLat;
    let sigN = Math.floor(n * latMag) / latMag;

    let s = Math.ceil(swLat * stepLat) / stepLat;
    let nsAdjust = (n - s) * .15;
    let sigS = Math.ceil((s+nsAdjust) * latMag) / latMag;

    let w = Math.ceil(swLng * stepLng) / stepLng;
    let sigW = Math.ceil(w * lngMag) / lngMag;

    let e = Math.floor(neLng * stepLng) / stepLng;
    let sigE = Math.floor(e * lngMag) / lngMag;

    let rtn = { n: sigN, s: sigS, e: sigE, w: sigW };
    console.log(neLat, swLat, neLng, swLng);
    console.log("rtn", rtn);
    return rtn;
}



const QueryController = ({ bounds, filterTerms, zoom, setData }) => {
    
    const [timer, setTimer] = useState(null);
    
    const debounce = (method, delayMs) => {
        delayMs = delayMs || 100;
        setTimer(null);
        clearTimeout(timer);
        var t = setTimeout(() => {
            method()
        }, delayMs);
        setTimer(t);
    }

    useEffect(() => {

        if (bounds) {
            debounce(() => {
                console.log("QueryController running");
                let x = GeoNormalizer(bounds._ne.lat, bounds._sw.lat, bounds._ne.lng, bounds._sw.lng);
                var payload = {
                    LatN: x.n,
                    LatS: x.s,
                    LonE: x.e,
                    LonW: x.w,
                    Zoom: zoom,
                    FilterTerms: filterTerms.map(a => ({ "label": a.label, "field": a.field, "type":a.type, "from":a.from, "to":a.to }))
                };
                let url = "https://api.propertypiper.com/api/filter/box3";
                if (window.location.host.includes("propertypiper.co.uk")) {
                    url = "https://api.propertypiper.co.uk/api/filter/box3";
                }
                fetch(url, {
                //fetch("http://localhost:8001/api/filter/box3", {
                //
                    method: 'POST',  
                    headers: {
                        "Content-Type": "application/json",
                    },
                    redirect: "follow",
                    body: JSON.stringify(payload),
                }).then((resp) => {
                    if (resp.status == 200 && resp) {
                        return resp.json();
                    }
                    console.log(resp);
                }).then(body => {
                    setData(body);
                });
            });
        }
    }, [bounds, filterTerms, setData ]);

    return null;

}

export default memo(QueryController);