import React, { useState, memo, useRef } from 'react';
import './NavMenu.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import AppBarCollapse from "./AppBarCollapse";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    navigation: {},
    toggleDrawer: {},
    appTitle: {},
    toolBar: {
        [theme.breakpoints.up("xs")]: {
            minHeight: "48px",
        },
        [theme.breakpoints.down("xs")]: {
            minHeight: "32px",
        },
    }
}));

const NavMenu = (props) => {
    const classes = useStyles();
    return (
        <div style={{ position: "relative" }}>
            <AppBar elevation={0} position="fixed" className={classes.navigation}>
                <Toolbar className={classes.toolBar} >
                    <Typography
                        color="inherit"
                        className={classes.appTitle}
                    >
                        PropertyPiper (Alpha)
                    </Typography>
                    <AppBarCollapse />
                </Toolbar>
            </AppBar>
            <Toolbar className={classes.toolBar} />
        </div>
    );
}


export default NavMenu;