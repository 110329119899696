import IfThen from './IfThen';

const IfNullOrEmpty = ({ children, value }) => {
    return (
        <IfThen condition={value != undefined && value != null && value != 0 && String(value).trim().length > 0 && value != "null"} >
            {children}
        </IfThen>
    );
}


export default IfNullOrEmpty;