import { createTheme } from '@material-ui/core/styles'
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import cyan from '@material-ui/core/colors/cyan';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import amber from '@material-ui/core/colors/amber';
export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1600,
            xl: 1920,
            phone: 415,
        }
    },
    shadows: ["none"],
    palette: {
        //theme colors: 132a13 31572c 4f772d 90a955 ecf39e  https://coolors.co/palette/132a13-31572c-4f772d-90a955-ecf39e
        //type: 'dark'
        
        background: {
            default: "#4f772d",
        },
        primary: {
            //main: purple[900],
            //main: yellow[700],
            //main: cyan["A200"],
            main: "#4f772d",
        },
        secondary: {
            main: "#90a955",
        },
        datavis: {
            //main: grey[200]
            main: "#31572c"
        },
        mapicons: {
            primary: "#31572c",
            background: "#fff",
            alt: "#4f772d",
            altBackground: "#ecf39e"
        }
        //"common": {
        //    "black": "#000",
        //    "white": "#fff"
        //},
        //"background": {
        //    "paper": "#fff",
        //    "default": "#fafafa"
        //},
        //"primary": {
        //    "light": "rgba(89, 210, 188, 1)",
        //    "main": "rgba(20, 160, 140, 1)",
        //    "dark": "rgba(0, 113, 95, 1)",
        //    "contrastText": "#fff"
        //}

    },
    overrides: {
        //MuiButton: {
        //    label: {
        //        color: 'black',
        //    }
        //},
        //MuiButton: {
        //    containedPrimary: {
        //        color: 'black',
        //    },
        //},
    }
});
export default theme;