import React, { useEffect, useState, memo, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FadeModal from '../FadeModal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PriceSlider from './PriceSlider';
import BedroomSlider from './BedroomSlider';
import BathroomSlider from './BathroomSlider';
import SqFtSlider from './SqFtSlider';

import AcreageSlider from './AcreageSlider';
import CrimeSlider from './CrimeSlider';
import DeprivationSlider from './DeprivationSlider';
import PriceSqFtSlider from './PriceSqFtSlider';
import RoomCntSlider from './RoomCntSlider';
import BroadbandSlider from './BroadbandSpeedSlider';
import SchoolFilter from './SchoolFilter';
//import BarsSelect from './BarsSelect';
//import HotelsSelect from './HotelsSelect';
import RecreationSelect from './RecreationSelect';
//import ShopsSelect from './ShopsSelect';
import FloodingSelect from './FloodingSelect';
import SubsidenceSelect from './SubsidenceSelect';
import NearbyAmenitiesFilter from './NearbyAmenitiesFilter';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const MainFilter = ({ open, setOpen, filterTerms, setFilterTerms, filterData }) => {
    const [cnt, setCnt] = useState(0);
    console.log("filterData", filterData);

    useEffect(() => {
        if (filterData != null) {
            let c = filterData.exclusionFilters.children.sort((a,b)=>a.count-b.count).find(a => a.count >= 0).count;
            setCnt(c);
        }
    },[filterData])


    const classes = useStyles();
    return (
        <FadeModal open={open} setOpen={setOpen} heading={"Filters"} cnt={cnt}>
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <PriceSlider filterData={filterData} setFilter={setFilterTerms} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <BedroomSlider filterData={filterData} setFilter={setFilterTerms} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <BathroomSlider filterData={filterData} setFilter={setFilterTerms}  />
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="h6" align="left">Property Types</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SqFtSlider filterData={filterData} setFilter={setFilterTerms} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <PriceSqFtSlider filterData={filterData} setFilter={setFilterTerms} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SchoolFilter filterData={filterData} setFilter={setFilterTerms} label="Primary"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SchoolFilter filterData={filterData} setFilter={setFilterTerms} label="Secondary" />
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="h6" align="left">Local Amenities</Typography>
                    </Grid>
                    {/*<Grid container>*/}
                    {/*    <Grid item xs={12} sm={6} >*/}
                    {/*        <NearbyAmenitiesFilter filterData={filterData} setFilter={setFilterTerms} />*/}
                    {/*    </Grid>*/}
                    {/*    */}{/*<Grid item xs={12} sm={6} >*/}
                    {/*    */}{/*    <HotelsSelect filterData={filterData} setFilter={setFilterTerms} />*/}
                    {/*    */}{/*</Grid>*/}
                    {/*    */}{/*<Grid item xs={12} sm={6} >*/}
                    {/*    */}{/*    <ShopsSelect filterData={filterData} setFilter={setFilterTerms} />*/}
                    {/*    */}{/*</Grid>*/}
                    {/*    <Grid item xs={12} sm={6}>*/}
                    {/*        <RecreationSelect filterData={filterData} setFilter={setFilterTerms} />*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={6}>
                        <NearbyAmenitiesFilter filterData={filterData} setFilter={setFilterTerms} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" align="left">Property Types</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" align="left">Tenures</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <CrimeSlider filterData={filterData} setFilter={setFilterTerms} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SubsidenceSelect filterData={filterData} setFilter={setFilterTerms} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FloodingSelect filterData={filterData} setFilter={setFilterTerms} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AcreageSlider filterData={filterData} setFilter={setFilterTerms} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <RoomCntSlider filterData={filterData} setFilter={setFilterTerms} />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <DeprivationSlider filterData={filterData} setFilter={setFilterTerms} />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <BroadbandSlider filterData={filterData} setFilter={setFilterTerms} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" align="left">Ownership</Typography>
                    </Grid>
                </Grid>
            </div>



        </FadeModal>
    );
}

export default memo(MainFilter);

