
import React, { useEffect } from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import About from './components/About';
import MainView from './components/layout/MainView';
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from './theme'
import './custom.css'
import { CssBaseline } from '@material-ui/core';

import { register } from 'swiper/element/swiper-element';


register(); //move register inside ? 



const App = () => (
    <ThemeProvider theme={theme}>
        <CssBaseline /> 
        <Layout>
            <Route exact path='/' component={MainView} />
            <Route path='/about' component={() => {
                window.location.href = 'https://www.moveista.com/';
                return null;
            }} />
        </Layout>
    </ThemeProvider>
)

export default App;