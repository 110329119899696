import React, { useEffect, useState, memo } from "react";
import { Grid, Button, TextField, InputAdornment } from "@material-ui/core";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { MuiRail, MuiHandle, MuiTrack, MuiTick } from "./components";
import BarChart2 from "./BarChart2";
import NumberFormat from 'react-number-format';

const HistoSlider2 = ({ data, setOutValue, prefix, suffix, isRange, leftLabel, rightLabel, renderFactor }) => {

    const [state, setState] = useState(null);
    const [lookup, setLookup] = useState([]);
    const [timer, setTimer] = useState(null);

    const [value, setValue] = useState([0, 0]);
    const [renderedValue, setRenderedValue] = useState([0, 0]);

    useEffect(() => {
        if (data != null) {
            let sorted = data.sort((a, b) => a[0] - b[0]);

            let normalized = sorted.map((a, i) => {
                return {
                    value: i,
                    cnt: a[1],
                    from: a[0]
                };
            });

            if (value[0] == 0 && value[1] == 0) {
                setValue([0, normalized.length - 1]);
            }

            //this block may no logne be required? always call setLookup(normalized)?
            let n = JSON.stringify(lookup);
            let n2 = JSON.stringify(normalized);
            if (n != n2) {
                setLookup(normalized);
            }
        }
    }, [data]);

    useEffect(() => {
        if (lookup != null && lookup.length > 0 && value != null && (value[0] >= 0 || value[1] >= 0)) {
            let start = lookup[value[0]].from;
            let end = lookup[value[1]].from;

            setOutValue(() => [start, end]);

            if (renderFactor != null && renderFactor != 0) {
                start = start * renderFactor;
                end = end * renderFactor;
            }

            if (!isRange) { end = end - 1 }
            let lim = 10000000 * (renderFactor ? renderFactor : 1);

            let endSuffix = `${end}${suffix ? suffix : ""}`;
            start = `${start}${suffix ? suffix : ""}`;

            if (end >= lim) {
                if (lookup[value[1] - 1] != null) {
                    let endButOne = lookup[value[1] - 1].from;
                    if (renderFactor != null && renderFactor != 0) {
                        endButOne = endButOne * renderFactor;
                    }
                    endSuffix = endButOne + (suffix ? `${suffix}` : "") + "+";
                }
                else {
                    endSuffix = "No Limit";
                }
            }

            setRenderedValue(() => [start, endSuffix]);
        }
    }, [value, lookup])

    const NumberFormatCustom = (props) => {
        const { inputRef, onChange, ...other } = props;
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                isNumericString
                prefix={prefix}
            />
        );
    }

    const SetValueToNearestRendered = (renderedVal, isFrom) => {
        let i = parseInt(renderedVal); //enforce int;
        //let closest = lookup.sort((a, b) => Math.abs(i - a.from) - Math.abs(i - b.from))[0]
        if (isFrom) {
            //want the nearest element that is lower.
            let closestLower = lookup.slice().reverse().find(a => a.from <= i);
            setValue(a => [closestLower.value, a[1]]);
        } else {
            let closestUpper = lookup.find(a => a.from >= i);
            setValue(a => [a[0], closestUpper.value]);
        }
    };


    if (lookup == null) {
        return (<></>);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <div style={{ paddingLeft: "15px", paddingRight: "15px", width: "100%", height: "50%" }}>
                    <BarChart2
                        data={data}
                        highlight={value}
                        isRange={isRange}
                        prefix={prefix}
                        suffix={suffix}
                        renderFactor={renderFactor}
                    />
                    <Slider
                        mode={3}
                        step={1}
                        domain={[0, lookup.length > 1 ? lookup.length - 1 : 1]}
                        rootStyle={{
                            position: "relative",
                            width: "100%"
                        }}
                        //onUpdate={update => setState(a => ({ ...a, update, inputValues: update }))
                        //}
                        onUpdate={
                            update => {
                                if (update[0] != value[0] || update[1] != value[1]) {//not ideal but 'somewhat' reduces number of rerenders.
                                    setValue(update);
                                }
                            }
                        }
                        //onChange={change => { setValue(change) }}
                        //onChange={values => setState(a => ({ ...a, values }))}
                        values={value}
                    >
                        <Rail>
                            {({ getRailProps }) => <MuiRail getRailProps={getRailProps} />}
                        </Rail>
                        <Handles>
                            {({ handles, getHandleProps }) => (
                                <div className="slider-handles">
                                    {handles.map(handle => (
                                        <MuiHandle
                                            key={handle.id}
                                            handle={handle}
                                            domain={[0, lookup.length - 1]}
                                            getHandleProps={getHandleProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </Handles>
                    </Slider>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-around"
                        style={{ marginTop: "16px" }}
                    >
                        <Grid item xs style={{ textAlign: "right" }}>
                            <TextField
                                size="small"
                                fullWidth={true}
                                variant="outlined"
                                label={leftLabel}
                                disabled={true} //temp until can make this work properly
                                value={renderedValue[0]}
                                onChange={evt => {
                                    const v = evt.target.value;
                                    setRenderedValue(a => [v, a[1]]);
                                    SetValueToNearestRendered(v, true);
                                }}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                            />
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: "center" }}>
                            -
                        </Grid>
                        <Grid item xs style={{ textAlign: "left" }}>
                            <TextField
                                size="small"
                                fullWidth={true}
                                variant="outlined"
                                label={rightLabel}
                                disabled={true} //temp until can make this work properly
                                value={renderedValue[1]}
                                onChange={evt => {
                                    const v = evt.target.value;
                                    setRenderedValue(a => [a[0], v]);
                                    SetValueToNearestRendered(v, false);
                                }}
                                InputProps={{
                                    inputComponent: NumberFormatCustom,
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
}


export default memo(HistoSlider2);
