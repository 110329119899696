import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ResponsiveImage from "../ResponsiveImage.jsx";
// Import Swiper styles
//import "swiper/css";
//import "swiper/css/free-mode";
//import "swiper/css/navigation";
//import "swiper/css/thumbs";
import useWindowDimensions from "../hooks/useWindowDimensions.jsx";
import 'swiper/swiper.min.css';
import "./swiperthumb.css";



// import required modules
import { FreeMode, Navigation, Thumbs, Pagination } from "swiper";



const SwiperThumb = ({ data }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const { height, width } = useWindowDimensions();
    const [images, setImages] = useState([]);

    const pushToSecond = ([x, ...y], [...z]) => [x, ...z, ...y];

    useEffect(() => {
        let arr = pushToSecond(data.Images.map(a => [a, false]), data.FloorplanImages.map(a => [a, true]));
        setImages(arr);
    }, [data]);

    const clickImage = (e) => {
        let targ = e.target.currentSrc.replace(/(-768)|(-590)|(-320)/, "-1024");
        window.open(targ, '_blank')
    }

    return (
        <>
            <Swiper
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                }}
                spaceBetween={10}
                navigation={true}
                lazy={{ enabled: true }}
                thumbs={{ swiper: thumbsSwiper }}
                pagination={true}
                modules={[FreeMode, Navigation, Thumbs, Pagination]}
                className="mySwiper2X"
            >
                {images.map((a, i) => {
                    return (
                        <SwiperSlide>
                            <div style={{ pointerEvents: "auto", minWidth: "200px" }} onClick={e => clickImage(e) } >
                                <div className="swiper-lazy-preloader"></div>
                                <div style={{ marginTop: "75%", float: "right" }}/>
                                <ResponsiveImage
                                    id={a[0]}
                                    fp={a[1]}
                                    sizes="50vw"
                                    className="propMainImg"
                                />
                                {/*<img src={"https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + a + "-1024.avif"}/>*/}
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                autoHeight={true}
                navigation={true}
                slidesPerView={width > 1600 ? 8 : (width > 900 ? 6 : 4)}
                freeMode={true}
                lazy={{ enabled: true }}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiperX"
            >
                {images.map((a, i) => {
                    return (
                        <SwiperSlide>
                            <div style={{ pointerEvents: "auto" }}>
                                <div className="swiper-lazy-preloader"></div>
                                <div style={{ marginTop: "75%", float: "right" }} /> 
                                <ResponsiveImage
                                    id={a[0]}
                                    fp={a[1]}
                                    sizes="(min-width: 2400px) 150px, 6vw" 
                                    className="propThumbMainImg"
                                    eager={true}
                                />
                                {/*// 4/3 ratio inverse for margin = 75% */}
                                {/*// (100/2)/8 = 100/16 = 6*/}
                                {/*<img src={"https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + a + "-1024.avif"}/>*/}
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </>
    );
}

export default SwiperThumb;