import React, { useState, memo, useRef, Component, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import MySwiper from "./myswiper/MySwiper.jsx";
import SwiperWeb from "./myswiper/SwiperWeb.jsx";
import SwiperV from "./myswiper/SwiperV.jsx";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100vw",
        position: "absolute",
        bottom: "30px",
        [theme.breakpoints.up("xs")]: {

        },
        [theme.breakpoints.down("xs")]: {
            bottom: "10px",
        }
    }
}));

const PropSwiper = ({ data, setPropertyCardClicked, setActivePropertyCoords, clusterPoints, setPropertyCardActive, propertyMarkerClicked,
    setPropertyMarkerClicked, latlon, productOpen }) => {

    const [propData, setPropData] = useState([]);
    const [selectedPropId, setSelectedPropId] = useState(null);
    const classes = useStyles();

    const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d;
    }

    const deg2rad = (deg) => {
        return deg * (Math.PI / 180)
    }

    const compare = (a, b) => {
        let latA = a.coordinates[1];
        let latB = b.coordinates[1];
        let latT = latlon[0];

        let lonA = a.coordinates[0];
        let lonB = b.coordinates[0];
        let lonT = latlon[1];

        let aDist = getDistanceFromLatLonInKm(latT, lonT, latA, lonA);
        let bDist = getDistanceFromLatLonInKm(latT, lonT, latB, lonB);

        let dist = aDist - bDist;
        return dist;
    };

    useEffect(() => {
            if (data != null && data.isItemResult && data.data != null && data.data.data != null && data.data.data.features != null) {
                let tData = data.data.data.features.filter(f => f.properties.cnt == 1).map(f => ({ ...f.properties, ...f.geometry }))
                    .concat(data.data.data.features.filter(f => f.properties.cnt > 1).flatMap(f => f.properties.collection.map(a => ({ ...a, ...f.geometry }))));
                tData.sort(compare);
                console.log(tData);
                setPropData(tData);
            }
            else {
                setPropData(null);
                if (!productOpen) {
                    setSelectedPropId(null);
                }
            }
    }, [data, productOpen]);

    useEffect(() => {
            //lookup prop by id.
            //check propdata first.
            if (propData && selectedPropId) {
                let clusterResult = clusterPoints.filter(a => a.id === selectedPropId);
                if (clusterResult != null && clusterResult.length > 0) {
                    if (clusterResult[0].value) {
                        //if we have the value (i.e. a non-server clustered point in the cluster) do this as marginally faster.
                        setActivePropertyCoords({ coords: clusterResult[0].cords, val: clusterResult[0].value, selectedId: selectedPropId });
                    }
                    else {
                        //else is a server clustered point within the cluster
                        let propValue = propData.find(a => a.id === selectedPropId).value;

                        if (propValue == undefined) {
                            //this is a cluster of non-server clustered points, AND server clustered points.
                        }
                        setActivePropertyCoords({ coords: clusterResult[0].cords, val: propValue, selectedId: selectedPropId });
                    }
                    console.log("y", { coords: clusterResult[0].cords, val: clusterResult[0].sum });
                } else {
                    let propResult = propData.filter(a => a.id == selectedPropId);
                    if (propResult != null && propResult.length > 0) {
                        let activePropCoords = { coords: propResult[0].coordinates, val: propResult[0].priceStr, selectedId: selectedPropId, server: propResult[0].serverCluster };
                        setActivePropertyCoords(activePropCoords);
                    } else {
                        setActivePropertyCoords(null);
                    }
                }
                setPropertyCardActive(selectedPropId);
            }
            else {
                setActivePropertyCoords(null);
                setPropertyCardActive(null);
            }


            //assuming match, see if need to update latlong due to clustering (check clusterPoints).

            //set activeProperty.

    }, [selectedPropId, clusterPoints, propData, productOpen]);

    if (propData) {
        return (
            <div className={classes.container}>
                <div style={{
                    marginLeft: "15px",
                    marginRight: "16px",
                    width: "auto",
                }}>
                    <div style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        maxWidth: "1600px",
                    }}>
                        <SwiperV setSelectedPropId={setSelectedPropId} setPropertyCardClicked={setPropertyCardClicked} data={propData}
                            propertyMarkerClicked={propertyMarkerClicked} setPropertyMarkerClicked={setPropertyMarkerClicked} productOpen={productOpen} />
                    </div>
                </div>
            </div>
        );
    }
    else {
        return <div />;
    }
}

export default memo(PropSwiper);