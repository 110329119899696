import { Container } from 'reactstrap';
import MiAccordion from '../MiAccordion';
import React, { useState, memo, useRef, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import ProductView from './ProductView';
import ProductHeader from './ProductHeader';
import DescriptionAndKey from './DescriptionAndKey';
import HeroData from './HeroData';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const ProductSummary = ({ data }) => {

    const [accordionContent, setAccordionContent] = useState([]);
    const theme = useTheme();
    useEffect(() => {

        let aContent = [
            { heading: "Overview", content: (<HeroData data={data} />), id:"ProductSummaryoverview" },
            { heading: "Risks and Other", content: (<DescriptionAndKey data={data} />), id: "ProductSummaryrisks" },
            { heading: "Description", content: (<Typography variant="body2" gutterBottom dangerouslySetInnerHTML={{ __html: data.Text }} />), id: "ProductSummarydesc" },
            { heading: "Enviromental", content: (<div />), id: "ProductSummaryenv" },
            /*{ heading: "Info", content: (<Typography variant="body2">{data.bedrooms}</Typography>) },*/
            
        ];

        setAccordionContent(aContent)

    }, [data]);

    return (
        <>
            <ProductHeader data={data} />
            <MiAccordion contentArray={accordionContent} startExpanded={"ProductSummaryoverview"} />
        </>
    );
}


export default memo(ProductSummary);