import React, { memo } from 'react';
import useWindowDimensions from "../hooks/useWindowDimensions.jsx";
import { useTheme } from '@material-ui/core/styles';

const MobileSelector = ({ mobileComponent, desktopComponent }) => {

    const { width } = useWindowDimensions();
    const theme = useTheme();
    console.log("theme.breakpoints.values.sm", theme.breakpoints.values.sm);
    console.log("width", width);
    if (width < theme.breakpoints.values.sm) {
        return mobileComponent;
    }
    else {
        return desktopComponent;
    }
}

export default memo(MobileSelector);