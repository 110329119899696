import React, { useRef, useEffect, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

const useStyles = makeStyles((theme) => ({

    drawerPaper: {
        width: "50vw",
        maxWidth: "1200px",
        top: "48px",
        height: "100%",
        pointerEvents: "auto",
        overflow: "visible"
    },
    visRight: {
        flexShrink: 0,
        height: "100%",
        display: "block",
    }
}));

const RightDrawer = ({ open, setOpen, children, setSideViewWidth }) => {

    const classes = useStyles();
    const ref = useRef();


    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                setSideViewWidth(entry.contentRect.width);
            }
        });

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (<>
        <Drawer
            className={classes.visRight}
            variant="persistent"
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            classes={{
                paper: classes.drawerPaper
            }}
        >
            <div ref={ref} style={{ paddingBottom: "40px" }} >
                {children}
            </div>
        </Drawer>
    </>
    );
}

export default memo(RightDrawer);