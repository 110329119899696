import React, { useState, memo, useEffect, useRef } from 'react';
import { Paper } from '@material-ui/core';
import { Grid, Typography, Slider } from '@mui/material';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useWindowDimensions from "../hooks/useWindowDimensions.jsx";

const priceHistoryStops = [
    { position: 0, color: "rgba(200,255,200,0.5)", val: "£0" },
    { position: 45, color: "rgba(100,255,100,0.5)", val: "£100K" }, //100000
    { position: 90, color: "rgba(50,255,50,0.5)", val: "£200K" }, //200000
    { position: 135, color: "rgba(128,255,50,0.5)", val: "£300K" }, //300000
    { position: 180, color: "rgba(255,255,50,0.5)", val: "£400K" }, //400000
    { position: 225, color: "rgba(255,128,50,0.5)", val: "£500K" }, //500000
    { position: 270, color: "rgba(255,0,50,0.5)", val: "£1M" }, //1000000
    { position: 315, color: "rgba(128,0,25,0.5)", val: "£1.5M" }, //1500000
    { position: 360, color: "rgba(64,0,0,0.5)", val: "£2M+" }, //2000000
];

const crimeStops = [
    { position: 0, color: "rgba(200,255,200,0.6)", val: "0" },
    { position: 90, color: "rgba(25,255,25,0.6)", val: "100" },
    { position: 180, color: "rgba(255,255,50,0.6)", val: "1000" },
    { position: 270, color: "rgba(255,0,25,0.6)", val: "10000" },
    { position: 360, color: "rgba(0,0,0,0.6)", val: "30000" }

];

const priceDiffStops = [
    { position: 0, color: "#0000ff", val: "-100%" },
    { position: 33, color: "#0073ff", val: "-50" }, //100000
    { position: 66, color: "#00a0ff", val: "-30%" }, //200000
    { position: 99, color: "#00c2f2", val: "-20%" }, //300000
    { position: 132, color: "#00dfd3", val: "-10%" }, //400000
    { position: 165, color: "#4de2b2", val: "0%" }, //500000
    { position: 198, color: "#81e28d", val: "10%" }, //1000000
    { position: 231, color: "#b1de6a", val: "20%" }, //1500000
    { position: 264, color: "#c4c026", val: "30%" }, //2000000
    { position: 297, color: "#da9b00", val: "50%" }, //2000000
    { position: 330, color: "#ef6c00", val: "100%" }, //2000000
    { position: 360, color: "#ff0000", val: "200%" }, //2000000
];

const useStyles = makeStyles((theme) => ({
    barWidth: {
        [theme.breakpoints.up("xs")]: {
            width: '360px',
        },
        [theme.breakpoints.down("xs")]: {
            width: '180px',
        },
    },
    barHeight: {
        [theme.breakpoints.up("xs")]: {
            height: '40px',
        },
        [theme.breakpoints.down("xs")]: {
            height: '20px',
        },
    }
}));

const GradientBar = ({ type }) => {

    const [gradientStops, setGradientStops] = useState([priceHistoryStops, 1]);
    const [barStyle, setBarStyle] = useState({});
    const classes = useStyles();
    const theme = useTheme();
    const { width, height } = useWindowDimensions();
    useEffect(() => {
        if (type == "pricehistory") {
            setGradientStops([priceHistoryStops, 1]);
        }
        else if (type == "pricediff") {
            setGradientStops([priceDiffStops, 0.4]);
        }
        else if (type == "crime") {
            setGradientStops([crimeStops, 1]);
        }
    }, [type])

    useEffect(() => {

        let multiplier = 1;
        if (width < theme.breakpoints.width("sm")) {
            multiplier = 0.5;
        }

        let gradientString = gradientStops[0]
            .map(stop => `${stop.color} ${stop.position * multiplier}px`)
            .join(', ');

        // Apply the gradient as a background
        let barStyle = {
            opacity: gradientStops[1],
            background: `linear-gradient(to right, ${gradientString})`,
        };

        setBarStyle(barStyle);
    }, [gradientStops])
    // Generate the gradient string


    return (
        <Grid container spacing={0} style={{ margin: 'auto' }} className={classes.barWidth} >
            {/* Add 9 labels */}
            {gradientStops[0].map((i, index) => (
                <Grid item key={index} xs={Math.round((120 / gradientStops[0].length)) / 10} style={{ textAlign: 'center', }}>
                    <Typography variant={(width >= theme.breakpoints.width("sm") ? "body1" : "body2")} style={{ paddingLeft: "5px", paddingBottom: "5px", transform: 'rotate(315deg)' }}>
                        {i.val}
                    </Typography>
                </Grid>
            ))}

            {/* Add the div */}
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <div style={{ ackground: '#f0f0f0' }} className={`${classes.barWidth} ${classes.barHeight}`}>
                    <div style={barStyle} className={`${classes.barWidth} ${classes.barHeight}`} ></div >
                </div>
            </Grid>
        </Grid>)

}


export default memo(GradientBar);