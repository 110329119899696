import React, { useEffect, memo, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Paper } from '@material-ui/core';
import Link from '@mui/material/Link';

const useStyles = makeStyles((theme) => ({

    tabPan: {
        "overflowY": "auto",
        [theme.breakpoints.up("xs")]: {
            "maxHeight": 'calc(100vh - 96px)' //48+48
        },
        [theme.breakpoints.down("xs")]: {
            "maxHeight": 'calc(100vh - 80px)' //32 + 48
        },
    },
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const SchoolView = ({ item, setOpen }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [schoolData, setSchoolData] = useState(null);

    useEffect(() => {
        if (item != null) {
            //go call api and get data.
        }
    }, [item]);

    if (item != null) {
        return (
            <div className={classes.root}>
                <AppBar position="sticky">
                    <div style={{ display: "flex", minHeight: 48 }}>
                        <IconButton onClick={() => setOpen(a => !a)} style={{ "align": "right" }} color="secondary">
                            <ChevronRightIcon />
                        </IconButton>
                        <Typography variant="h6">School name from data {item}</Typography>
                    </div>
                </AppBar>
                <Paper>
                    <Typography variant="body2">School name from data {item}</Typography>
                    <Link
                        href={"https://www.compare-school-performance.service.gov.uk/school/" + item + "/"}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {'compare-school-performance.service.gov.uk'}
                    </Link>
                </Paper>
            </div>);
    }

    return (null);
}

export default memo(SchoolView);