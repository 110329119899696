import React, { useState, memo, useEffect, useRef } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import InputLabel from '@material-ui/core/InputLabel';

import MenuItem from '@material-ui/core/MenuItem';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 9 + ITEM_PADDING_TOP,
            //width: 250,
        },
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "95%"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    label: {
        "&.Mui-focused": {
            color: theme.palette.text.secondary,
        },
        fontSize: "1rem",
        [theme.breakpoints.up("sm")]: {
            fontSize: "1.25rem"
        },
    }
}));

const getStyles = (name, theme) => {
    return {
        fontWeight:
            theme.typography.fontWeightRegular
        //theme.typography.fontWeightMedium,
    };
}

const BasicMultiSelect = ({ data, onChange, label }) => {

    const classes = useStyles();
    const theme = useTheme();
    const [values, setValues] = React.useState([]);

    //useEffect(() => {
    //    if (selectRef.current) {
    //        selectRef.current.focus();
    //    }
    //    //let tempVal = [];
    //    //values.forEach(a => tempVal.push(data.find(b=>a==b.name)));
    //    //onChange(tempVal);
    //}, [values]);

    const handleChange = (event, v) => {
        let tempVal = [];
        event.target.value.forEach(a => tempVal.push(data.find(b => a == b.name)));
        onChange(tempVal);
        setValues(event.target.value);

    };

    const handleDelete = (value) => {
        let tempVal = [];
        values.forEach(a => tempVal.push(data.find(b => a == b.name)));
        tempVal = tempVal.filter(v => v.name != value);
        onChange(tempVal);
        setValues(a => a.filter(b => value != b));
    };

    return (
        <FormControl className={(classes.formControl)} >
            <InputLabel id="demo-simple-select-label" className={classes.label}>{label}</InputLabel>
            <Select
                
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                //disableCloseOnSelect
                multiple
                //native
                value={values}
                onChange={handleChange}
                //getOptionSelected={(option, value) => option.name === value.name}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {selected.map((value) => (
                            <Chip key={value}
                                label={value}
                                //variant="outlined"
                                onMouseDown={(event) => {
                                    event.stopPropagation();
                                }}
                                onDelete={() => handleDelete(value)}
                                className={classes.chip} />
                        ))}
                    </div>
                )}
                MenuProps={MenuProps}
            >
                {data.map((option) => (

                    <MenuItem key={option.name} value={option.name}
                        style={getStyles(option.name, theme)}
                    >
                        {/*<Checkbox checked={personName.indexOf(name) > -1} />*/}
                        <Checkbox checked={values.some(a=>a == option.name) } />
                        <ListItemText primary={option.title} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        //<Autocomplete
        //    multiple
        //    id="checkboxes-tags-demo"
        //    options={data}
        //    native
        //    disableCloseOnSelect
        //    getOptionLabel={(option) => option.name}
        //    renderOption={(option, { selected }) => (
        //        <React.Fragment>
        //            <Checkbox
        //                icon={icon}
        //                checkedIcon={checkedIcon}
        //                style={{ marginRight: 8 }}
        //                checked={selected}
        //            />
        //            {option.title}
        //        </React.Fragment>
        //    )}
        //    /*style={{ width: 500 }}*/
        //    style={{ marginRight: "10px" }}
        //    renderInput={(params) => (
        //        <TextField {...params} variant="outlined" placeholder="Select..." />
        //    )}
        //    onChange={(e, v) => onChange(v)}
        //    getOptionSelected={(option, value) => option.name === value.name}
        ///>
    );
}


export default memo(BasicMultiSelect);