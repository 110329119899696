import React, { useState, memo, useEffect, useRef } from 'react';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import HistoSlider2 from '../reusable/histoslider/HistoSlider2';
import BasicMultiSelect from '../reusable/BasicMultiSelect';

const RecreationSelect = ({ filterData, setFilter }) => {
    const [data, setData] = useState([]);
    const field = "RecreationTerms";
    useEffect(() => {
        if (filterData == null || filterData.filters == null || filterData.filters.children == null) {
            return;
        }

        let targetData = filterData.filters.children.find(a => a.label == "amenities");

        let tData = targetData.children.map(a => ({
            title: `${a.displayText} (${a.count})`,
            name: a.displayText,
            filter: a
        }));

        setData(tData);
    }, [filterData]);

    const filterChange = (val) => {
        setFilter(a => {
            //remove existing filters if exists.
            let newFilter = a.filter(b => b.field != field);
            //re-add filters if selected.
            if (val && val.length > 0) {
                val.forEach(v => newFilter.push(v.filter));
            }
            return newFilter;
        });
    }

    return (
        <Paper>
            <BasicMultiSelect data={data} onChange={filterChange} label="Recreation" />
        </Paper>
    );
}


export default memo(RecreationSelect);