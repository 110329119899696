import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
//import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
//import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

import maplibregl from 'maplibre-gl/dist/maplibre-gl';
import MapWorker from 'worker-loader!maplibre-gl/dist/maplibre-gl-csp-worker';
import HomeIcon from '@material-ui/icons/Home';
import circle from '@turf/circle';
import hexgrid from '@turf/hex-grid';
import pointsWithinPolygon from '@turf/points-within-polygon';
import pointInPolygon from '@turf/boolean-point-in-polygon';
import pip from 'point-in-polygon-hao';
import centroid from '@turf/centroid';
import BasicTable from './BasicTable';
import PriceHistoryPopup from './PriceHistoryPopup';
import CrimePopup from './CrimePopup';

import { theme } from '../theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { Paper, Typography, Box } from '@material-ui/core';
import { percentageWithCommas } from '../lib/ClusterRender';
import { MapboxglSpiderifier } from '../lib/Spiderify.jsx';
import "../lib/Spiderify.css";

import useWindowDimensions from "./hooks/useWindowDimensions.jsx";
import { useTheme } from '@material-ui/core/styles';

/*import { Protocol } from 'pmtiles';*/


Object.assign(globalThis, { pip });

maplibregl.workerClass = MapWorker;
maplibregl.accessToken = 'pk.eyJ1IjoiaG91c2VjODQiLCJhIjoiY2tsc2t3N3I4MDN4MDJubjgxYjNmODE5ZyJ9.BYgTwOrkMw3hEs9ypeLs8g';

const MapBox = ({ lng, setLng, lat, setLat, zoom, setZoom, bounds, setBounds, data, setData, setPropertyMarkerClicked, poly, crimes, schools,
    toggleFly, satView, productData, setClusterPoints, activePropertyCoords, setActivePropertyCoords, showHistoricPrices, showCrime, yearCrimeFilter,
    yearHistPriceFilter, priceDiffMode, showSchools, schoolsFilter, demographicsFilter, setPropertyCardClicked, showProps, setSelectedSchool }) => {

    const mapContainer = useRef();
    const [map, setMap] = useState(null);
    const [lastHash, setLastHash] = useState("");
    const [init, setInit] = useState(false);
    const spider = useRef();
    const [activeMarker, setActiveMarkerX] = useState(null);
    const theme = useTheme();
    const crimeFilterRef = useRef(yearCrimeFilter)
    const { height, width } = useWindowDimensions();

    const [debounceTimer, setDebounceTimer] = useState(null);
    const [satMode, setSatMode] = useState(false);
    useEffect(() => {
        crimeFilterRef.current = yearCrimeFilter;
    }, [yearCrimeFilter]);


    useEffect(() => {
        if (satView != null && map != null && data != null) {
            console.log("STYLE CHANGE DETECTED");
            setLastHash("");

            let style = {
                'sources': {
                    'raster-tiles': {
                        'type': 'raster',
                        'tiles': [
                            'https://khms3.google.com/kh/v=979?x={x}&y={y}&z={z}'
                        ],
                        'tileSize': 256,
                    }
                },
                'layers': [
                    {
                        'id': 'simple-tiles',
                        'type': 'raster',
                        'source': 'raster-tiles',
                        'minzoom': 0,
                        'maxzoom': 23
                    }
                ]
            };

            if (!satMode) {
                map.addSource("raster-tiles", style.sources["raster-tiles"]);
                map.addLayer(style.layers[0], "aeroway_fill");
                map.setLayoutProperty("building-3d", "visibility", "none");
                map.setLayoutProperty("building", "visibility", "none");
            }
            else {
                if (map.getLayer("simple-tiles")) {
                    map.removeLayer("simple-tiles");
                }
                if (map.getSource("raster-tiles")) {
                    map.removeSource("raster-tiles");
                }
                map.setLayoutProperty("building-3d", "visibility", "visible");
                map.setLayoutProperty("building", "visibility", "visible");
            }
            setSatMode(a => !a);
        }
    }, [satView]);

    const setActiveMarker = (marker) => {
        setActiveMarkerX(a => {
            if (a != null) {
                a.remove();
            }
            return marker;
        });
    };

    useEffect(() => {
        if (activeMarker != null) {
            activeMarker.getElement().addEventListener('click', (e) => {
                setPropertyCardClicked(activePropertyCoords.selectedId); //deleberatly closing over, don't add to deps.
            });
            var n = activeMarker.addTo(map);
            console.log("Added ", activeMarker);
        }
    }, [activeMarker, map]);

    const lon2tile = (lon, zoom) => Math.floor((lon + 180) / 360 * Math.pow(2, zoom));
    const lat2tile = (lat, zoom) => Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom));

    const renderPriceHist = (bounds, zoom, map, showHistoricPrices, yearHistPriceFilter, priceDiffMode, width) => {
        if (showHistoricPrices) {
            console.log("rendering price hist");
            if (!map.isSourceLoaded('pricedata')) {
                setTimeout(() => renderPriceHist(bounds, zoom, map, showHistoricPrices, yearHistPriceFilter, priceDiffMode), 80);
                console.log("Waiting on data");
                return;
            }


            let extraLng = (bounds._ne.lng - bounds._sw.lng) / 15; // 15;
            let extraLat = (bounds._ne.lat - bounds._sw.lat) / 8; // 8;
            let bnd = [bounds._sw.lng - extraLng, bounds._sw.lat - extraLat, bounds._ne.lng + extraLng, bounds._ne.lat + extraLat];

            //let latdiff
            let multiplier = 1;
            if (width < theme.breakpoints.width("sm")) {
                multiplier = .5;
            } else if (width < theme.breakpoints.width("md")) {
                multiplier = .75;
            }

            let dis = 0.06;
            if (zoom < 15) {
                let pow = 15 - zoom;
                dis = (Math.pow(2, pow) / (priceDiffMode ? 12.5 : 25)) * multiplier;
            }



            let hexPolys = hexgrid(bnd, dis, { units: 'miles' });
            let hexLookup = {};
            hexPolys.features.forEach(hx => {
                hx.geometry.coordinates[0].forEach(pt => {
                    let x = lon2tile(pt[0], Math.floor(zoom));
                    let y = lat2tile(pt[1], Math.floor(zoom));
                    if (hexLookup[x] == undefined) {
                        hexLookup[x] = {};
                    }
                    if (hexLookup[x][y] == undefined) {
                        hexLookup[x][y] = [];
                        hexLookup[x][y].push(hx);
                    }
                    else {
                        if (!hexLookup[x][y].some(i => i.geometry.coordinates[0][0][0] == hx.geometry.coordinates[0][0][0] &&
                            i.geometry.coordinates[0][0][1] == hx.geometry.coordinates[0][0][1] &&
                            i.geometry.coordinates[0][1][0] == hx.geometry.coordinates[0][1][0] &&
                            i.geometry.coordinates[0][1][1] == hx.geometry.coordinates[0][1][1] &&
                            i.geometry.coordinates[0][2][0] == hx.geometry.coordinates[0][2][0] &&
                            i.geometry.coordinates[0][2][1] == hx.geometry.coordinates[0][2][1] &&
                            i.geometry.coordinates[0][3][0] == hx.geometry.coordinates[0][3][0] &&
                            i.geometry.coordinates[0][3][1] == hx.geometry.coordinates[0][3][1] &&
                            i.geometry.coordinates[0][4][0] == hx.geometry.coordinates[0][4][0] &&
                            i.geometry.coordinates[0][4][1] == hx.geometry.coordinates[0][4][1] &&
                            i.geometry.coordinates[0][5][0] == hx.geometry.coordinates[0][5][0] &&
                            i.geometry.coordinates[0][5][1] == hx.geometry.coordinates[0][5][1] &&
                            i.geometry.coordinates[0][6][0] == hx.geometry.coordinates[0][6][0] &&
                            i.geometry.coordinates[0][6][1] == hx.geometry.coordinates[0][6][1]
                        )) {
                            hexLookup[x][y].push(hx);
                        }
                    }
                });
            });

            let allowedYears = yearHistPriceFilter;
            let minYear = Math.min(...allowedYears);
            let maxYear = Math.max(...allowedYears);

            //set filters on pricehistory points & labels.
            let gtrFil = [];
            let yearFields = [];
            if (map != null) {

                if (priceDiffMode) {
                    yearFields = [`p${minYear - 2000}`, `p${maxYear - 2000}`];
                }
                else {
                    yearFields = yearHistPriceFilter.map(a => `p${a - 2000}`);
                }
                //["all", boolean, boolean]: boolean
                //["any", boolean, boolean]: boolean
                let numFilter = ["number"];
                yearFields.forEach(a => numFilter.push(["get", a]));
                numFilter.push(0);
                gtrFil = ["all", [">", numFilter, 0], ["<", ["get", "price"], 50000000]];
                console.log(gtrFil);
                map.setFilter("pricehistorypoint", gtrFil);
                map.setFilter("pricehistorylabel", gtrFil);

            }

            if (priceDiffMode) {
                allowedYears = [minYear, maxYear];
            }
            let t1 = new Date().getTime();
            let priceDataPoints = [];

            if (zoom < 15 || gtrFil.length == 0) {
                priceDataPoints = map.querySourceFeatures('pricedata', {
                    sourceLayer: 'outCluster',
                    filter: ['in', ["get", "year"], ["literal", allowedYears]]
                });
            }
            else {
                priceDataPoints = map.querySourceFeatures('pricedata', {
                    sourceLayer: 'outCluster',
                    filter: gtrFil
                });
            }

            let slow = 0;
            let fail = 0;
            let oob = 0;
            let firstTry = 0;
            let adjustment = [[0, 0], [0, 1], [0, -1], [1, 0], [-1, 0], [-1, -1], [-1, 1], [1, 1], [1, -1]];
            priceDataPoints.forEach(f => {

                //bounds check
                let inbounds = true;

                let fLon = f.geometry.coordinates[0];
                let fLat = f.geometry.coordinates[1];
                inbounds = fLon < bounds._ne.lng && fLon > bounds._sw.lng && fLat < bounds._ne.lat && fLat > bounds._sw.lat


                if (inbounds) {
                    let result = false;
                    for (let i = 0; i < 9; i++) {
                        if (hexLookup[f.tile.x + adjustment[i][0]] != undefined && hexLookup[f.tile.x + adjustment[i][0]][f.tile.y + adjustment[i][1]] != undefined) {

                            let hexLookupBin = hexLookup[f.tile.x + adjustment[i][0]][f.tile.y + adjustment[i][1]];
                            for (let i = 0; i < hexLookupBin.length; i++) {
                                let a = hexLookupBin[i];
                                result = pointInPolygon(f.geometry, a.geometry);

                                if (result) {

                                    let p = f.properties;

                                    if (((zoom < 15 && allowedYears.includes(p.year) && p.point_count > 0) || (zoom >= 15 && yearFields.some(a => Number(p[a]) > 0))) && p.price > 0 && p.price < 50000000) {
                                        if (priceDiffMode && p.price < 5000000) {
                                            if (a.properties.growth == undefined) {
                                                a.properties.growth = 0;
                                                a.properties.max = 0;
                                                a.properties.cntmax = 0;
                                                a.properties.min = 0;
                                                a.properties.cntmin = 0;
                                            }
                                            //if (p[`p${minYear - 2000}`] > 0)
                                            if (zoom < 15 && p.year == minYear) {
                                                a.properties.cntmin += p.point_count
                                                a.properties.min += (p.price * p.point_count);
                                            }
                                            if (zoom < 15 && p.year == maxYear) {
                                                a.properties.cntmax += p.point_count
                                                a.properties.max += (p.price * p.point_count);
                                            }
                                            if (zoom >= 15 && p[`p${minYear - 2000}`] > 0) {
                                                a.properties.cntmin += 1;
                                                a.properties.min += p[`p${minYear - 2000}`];

                                            }
                                            if (zoom >= 15 && p[`p${maxYear - 2000}`] > 0) {
                                                a.properties.cntmax += 1
                                                a.properties.max += p[`p${maxYear - 2000}`];
                                            }
                                        }
                                        else if (p.price < 50000000 && zoom < 15) {
                                            if (a.properties.price == undefined) {
                                                a.properties.price = p.price;
                                                a.properties.cnt = p.point_count;
                                            }
                                            else {
                                                let denom = a.properties.cnt + p.point_count;
                                                let newAvg = (a.properties.cnt / (denom) * a.properties.price) + ((p.point_count / denom) * p.price);
                                                a.properties.price = newAvg;
                                                a.properties.cnt += p.point_count;
                                            }
                                        }
                                        else if (p.price < 50000000 && zoom >= 15) {
                                            if (a.properties.price == undefined) {
                                                a.properties.price = Math.max(...(yearFields.filter(x => Number(p[x]) > 0).map(y => Number(p[y]))));
                                                a.properties.cnt = 1;
                                            }
                                            else {
                                                let denom = a.properties.cnt + 1;
                                                let price = Math.max(...(yearFields.filter(x => Number(p[x]) > 0).map(y => Number(p[y]))));
                                                let newAvg = (a.properties.cnt / (denom) * a.properties.price) + ((1 / denom) * price);
                                                a.properties.price = newAvg;
                                                a.properties.cnt += 1;
                                            }
                                        }

                                    }
                                    break;
                                }
                            }
                        }
                        if (result) {
                            if (i == 0) {
                                firstTry += 1;
                            }

                            break;
                        }
                    }

                    if (!result) {
                        fail += 1;
                    }
                }
                else {
                    oob += 1;
                }

            });
            if (fail > 0)
                console.log("%cFAILS", "background: red; color: yellow; font-size: large", fail);
            let centroids = [];
            if (priceDiffMode) {
                hexPolys.features = hexPolys.features.filter(a => a.properties.max > 0 || a.properties.min > 0);
                hexPolys.features.forEach(a => {
                    let poly = {
                        type: "Feature",
                        properties: {},
                        geometry: a.geometry
                    };
                    let point = centroid(poly);

                    if (a.properties.max <= 0 || a.properties.min <= 0) {
                        point.properties.label = a.properties.max <= 0 ? `No data for ${maxYear}` : `No data for ${minYear}`;
                        a.properties.growth = undefined;
                    }
                    else if (a.properties.cntmax < 5 || a.properties.cntmin <= 5) {
                        point.properties.label = a.properties.cntmax < 5 ? `Not enough data \r\nfor ${maxYear}` : `Not enough data \r\nfor ${minYear}`;
                        a.properties.growth = undefined;
                    }
                    else {
                        let maxAvg = a.properties.max / a.properties.cntmax;
                        let minAvg = a.properties.min / a.properties.cntmin;
                        a.properties.growth = ((100 * maxAvg) / minAvg) - 100;
                        point.properties.label = `${(Math.round(a.properties.growth * 10) / 10)}%`
                    }

                    centroids.push(point);
                });
                let fc = {
                    type: "FeatureCollection",
                    features: centroids
                };
                map.getSource('pricehexcentroid').setData(fc);
                map.getSource('pricehex').setData(hexPolys);
                map.setLayoutProperty(
                    'pricehistoryhex',
                    'visibility',
                    'none');
                map.setLayoutProperty(
                    'pricediffhex',
                    'visibility',
                    'visible');
                map.setLayoutProperty(
                    'pricediffcentroid',
                    'visibility',
                    'visible');

            }
            else {
                hexPolys.features = hexPolys.features.filter(a => a.properties.price > 0);

                map.getSource('pricehex').setData(hexPolys);
                map.setLayoutProperty(
                    'pricehistoryhex',
                    'visibility',
                    'visible');
                map.setLayoutProperty(
                    'pricediffhex',
                    'visibility',
                    'none');
                map.setLayoutProperty(
                    'pricediffcentroid',
                    'visibility',
                    'none');
            }
        }
        else {
            if (map != null) {
                let src = map.getSource('pricehex');
                if (src != null) {
                    src.setData(emptySource);
                }
            }
        }
    }

    const renderSchools = (bounds, zoom, map, showSchools, schoolsFilter, demographicsFilter, width) => {

        if (showSchools && map != null) {
            if (!map.isSourceLoaded('schooldata')) {
                setTimeout(() => renderSchools(bounds, zoom, map, showSchools, schoolsFilter, demographicsFilter), 80);
                console.log("Waiting on data");
                return;
            }
            let src = map.getSource('schoolcatch');
            if (src != null) {
                src.setData(emptySource);
            }

            if (zoom >= 13) {
                //calculate catchments.
                let schoolPoints = map.querySourceFeatures('schooldata', {
                    sourceLayer: 'schoolpoints',
                });

                let schoolPolys = {
                    'type': 'FeatureCollection',
                    'features': []
                };

                schoolPoints.forEach(f => {

                    let fLon = f.geometry.coordinates[0];
                    let fLat = f.geometry.coordinates[1];

                    let inbounds = fLon < bounds._ne.lng && fLon > bounds._sw.lng && fLat < bounds._ne.lat && fLat > bounds._sw.lat

                    if (inbounds) {
                        if (f.properties.CatchmentMedProbMetres > 0) {
                            let center = [f.geometry.coordinates[0], f.geometry.coordinates[1]];
                            let radius = f.properties.CatchmentMedProbMetres / 1000;
                            let options = { steps: 120, units: 'kilometers', properties: { id: f.properties.Id } }
                            let circlePoly = circle(center, radius, options);

                            circlePoly['id'] = f.properties.Id;
                            schoolPolys.features.push(circlePoly)

                        }
                    }
                });

                map.getSource('schoolcatch').setData(schoolPolys);
            }

            //hexes
            if (zoom <= 13) {
                let adjustment = [[0, 0], [0, 1], [0, -1], [1, 0], [-1, 0], [-1, -1], [-1, 1], [1, 1], [1, -1]];
                let extraLng = (bounds._ne.lng - bounds._sw.lng) / 15; // 15;
                let extraLat = (bounds._ne.lat - bounds._sw.lat) / 8; // 8;
                let bnd = [bounds._sw.lng - extraLng, bounds._sw.lat - extraLat, bounds._ne.lng + extraLng, bounds._ne.lat + extraLat];

                //let latdiff
                let multiplier = 1;
                if (width < theme.breakpoints.width("sm")) {
                    multiplier = .5;
                } else if (width < theme.breakpoints.width("md")) {
                    multiplier = .75;
                }

                let dis = 0.06;
                if (zoom < 15) {
                    let pow = 15 - zoom;
                    dis = (Math.pow(2, pow) / 20) * multiplier;
                }


                let hexPolys = hexgrid(bnd, dis, { units: 'miles' });
                let hexLookup = {};
                hexPolys.features.forEach(hx => {
                    hx.geometry.coordinates[0].forEach(pt => {
                        let x = lon2tile(pt[0], Math.floor(zoom));
                        let y = lat2tile(pt[1], Math.floor(zoom));
                        if (hexLookup[x] == undefined) {
                            hexLookup[x] = {};
                        }
                        if (hexLookup[x][y] == undefined) {
                            hexLookup[x][y] = [];
                            hexLookup[x][y].push(hx);
                        }
                        else {
                            if (!hexLookup[x][y].some(i => i.geometry.coordinates[0][0][0] == hx.geometry.coordinates[0][0][0] &&
                                i.geometry.coordinates[0][0][1] == hx.geometry.coordinates[0][0][1] &&
                                i.geometry.coordinates[0][1][0] == hx.geometry.coordinates[0][1][0] &&
                                i.geometry.coordinates[0][1][1] == hx.geometry.coordinates[0][1][1] &&
                                i.geometry.coordinates[0][2][0] == hx.geometry.coordinates[0][2][0] &&
                                i.geometry.coordinates[0][2][1] == hx.geometry.coordinates[0][2][1] &&
                                i.geometry.coordinates[0][3][0] == hx.geometry.coordinates[0][3][0] &&
                                i.geometry.coordinates[0][3][1] == hx.geometry.coordinates[0][3][1] &&
                                i.geometry.coordinates[0][4][0] == hx.geometry.coordinates[0][4][0] &&
                                i.geometry.coordinates[0][4][1] == hx.geometry.coordinates[0][4][1] &&
                                i.geometry.coordinates[0][5][0] == hx.geometry.coordinates[0][5][0] &&
                                i.geometry.coordinates[0][5][1] == hx.geometry.coordinates[0][5][1] &&
                                i.geometry.coordinates[0][6][0] == hx.geometry.coordinates[0][6][0] &&
                                i.geometry.coordinates[0][6][1] == hx.geometry.coordinates[0][6][1]
                            )) {
                                hexLookup[x][y].push(hx);
                            }
                        }
                    });
                });

                let schoolDataPoints = map.querySourceFeatures('schooldata', {
                    sourceLayer: schoolsFilter, //'KS2PercentExpectedStandard',
                });

                let fail = 0;
                schoolDataPoints.forEach(f => {

                    //bounds check
                    let inbounds = true;

                    let fLon = f.geometry.coordinates[0];
                    let fLat = f.geometry.coordinates[1];

                    inbounds = fLon < bounds._ne.lng && fLon > bounds._sw.lng && fLat < bounds._ne.lat && fLat > bounds._sw.lat

                    if (inbounds) {

                        let result = false;

                        for (let i = 0; i < 9; i++) {
                            if (hexLookup[f.tile.x + adjustment[i][0]] != undefined && hexLookup[f.tile.x + adjustment[i][0]][f.tile.y + adjustment[i][1]] != undefined) {

                                let hexLookupBin = hexLookup[f.tile.x + adjustment[i][0]][f.tile.y + adjustment[i][1]];

                                for (let i = 0; i < hexLookupBin.length; i++) {
                                    let a = hexLookupBin[i];
                                    result = pointInPolygon(f.geometry, a.geometry);
                                    if (result) {

                                        let p = f.properties;
                                        let val = p[schoolsFilter];
                                        if (schoolsFilter == "Demographics" && demographicsFilter != null && demographicsFilter.length > 0) {
                                            val = p[demographicsFilter];
                                        }
                                        if (a.properties.val == undefined) {
                                            a.properties.val = val;
                                            a.properties.cnt = p.point_count > 0 ? p.point_count : 1;
                                            a.properties.tot = a.properties.val * a.properties.cnt
                                        }
                                        else {
                                            let pts = p.point_count > 0 ? p.point_count : 1;
                                            a.properties.cnt += pts;
                                            a.properties.tot += pts * val;
                                            a.properties.val += a.properties.tot / a.properties.cnt;
                                        }
                                        break;
                                    }
                                }

                                if (result) {
                                    break;
                                }
                            }
                        }

                        if (!result) {
                            fail += 1;
                        }
                    }
                });

                if (fail > 0)
                    console.log("%cFAILS", "background: red; color: yellow; font-size: large", fail);
                console.log(hexPolys);
                map.getSource('schoolhex').setData(hexPolys);
                map.setLayoutProperty(
                    'schoolhexlayer',
                    'visibility',
                    'visible');
            }
        }
        else {
            if (map != null) {
                let src = map.getSource('schoolhex');
                if (src != null) {
                    src.setData(emptySource);
                }
                src = map.getSource('schoolcatch');
                if (src != null) {
                    src.setData(emptySource);
                }
            }
        }
    };

    const renderCrime = (bounds, zoom, map, showCrime, yearCrimeFilter, width) => {
        if (showCrime) {
            if (!map.isSourceLoaded('crimedata')) {
                setTimeout(() => renderCrime(bounds, zoom, map, showCrime, yearCrimeFilter), 80);
                console.log("Waiting on data");
                return;
            }

            let gtrFil = [];
            let wcntFields = [];
            let cntFields = [];
            if (map != null) {

                wcntFields = yearCrimeFilter.map(a => `p${a - 2000}wcnt`);
                cntFields = yearCrimeFilter.map(a => `p${a - 2000}cnt`);
                let numFilter = ["number"];
                wcntFields.forEach(a => numFilter.push(["get", a]));
                numFilter.push(0);
                gtrFil = [">", numFilter, 0];
                map.setFilter("crimelabel", gtrFil);
                map.setFilter("crimepoint", gtrFil);

                let text = ["+"];
                wcntFields.forEach(a => text.push(["to-number", ["get", a], 0]));
                map.setLayoutProperty("crimelabel", "text-field", ["to-string", text])

            }

            if (zoom >= 16)
                return;

            let extraLng = (bounds._ne.lng - bounds._sw.lng) / 15; // 15;
            let extraLat = (bounds._ne.lat - bounds._sw.lat) / 8; // 8;
            let bnd = [bounds._sw.lng - extraLng, bounds._sw.lat - extraLat, bounds._ne.lng + extraLng, bounds._ne.lat + extraLat];

            //let latdiff
            //let latdiff
            let multiplier = 1;
            if (width < theme.breakpoints.width("sm")) {
                multiplier = .5;
            } else if (width < theme.breakpoints.width("md")) {
                multiplier = .75;
            }

            let dis = 0.06;
            if (zoom < 15) {
                let pow = 15 - zoom;
                dis = (Math.pow(2, pow) / 20) * multiplier;
            }

            let hexPolys = hexgrid(bnd, dis, { units: 'miles' });
            let hexLookup = {};
            hexPolys.features.forEach(hx => {
                hx.geometry.coordinates[0].forEach(pt => {
                    let x = lon2tile(pt[0], Math.floor(zoom));
                    let y = lat2tile(pt[1], Math.floor(zoom));
                    if (hexLookup[x] == undefined) {
                        hexLookup[x] = {};
                    }
                    if (hexLookup[x][y] == undefined) {
                        hexLookup[x][y] = [];
                        hexLookup[x][y].push(hx);
                    }
                    else {
                        if (!hexLookup[x][y].some(i => i.geometry.coordinates[0][0][0] == hx.geometry.coordinates[0][0][0] &&
                            i.geometry.coordinates[0][0][1] == hx.geometry.coordinates[0][0][1] &&
                            i.geometry.coordinates[0][1][0] == hx.geometry.coordinates[0][1][0] &&
                            i.geometry.coordinates[0][1][1] == hx.geometry.coordinates[0][1][1] &&
                            i.geometry.coordinates[0][2][0] == hx.geometry.coordinates[0][2][0] &&
                            i.geometry.coordinates[0][2][1] == hx.geometry.coordinates[0][2][1] &&
                            i.geometry.coordinates[0][3][0] == hx.geometry.coordinates[0][3][0] &&
                            i.geometry.coordinates[0][3][1] == hx.geometry.coordinates[0][3][1] &&
                            i.geometry.coordinates[0][4][0] == hx.geometry.coordinates[0][4][0] &&
                            i.geometry.coordinates[0][4][1] == hx.geometry.coordinates[0][4][1] &&
                            i.geometry.coordinates[0][5][0] == hx.geometry.coordinates[0][5][0] &&
                            i.geometry.coordinates[0][5][1] == hx.geometry.coordinates[0][5][1] &&
                            i.geometry.coordinates[0][6][0] == hx.geometry.coordinates[0][6][0] &&
                            i.geometry.coordinates[0][6][1] == hx.geometry.coordinates[0][6][1]
                        )) {
                            hexLookup[x][y].push(hx);
                        }
                    }
                });
            });
            //////////





            let crimeDataPoints = [];
            if (zoom < 14 || gtrFil.length == 0) {
                crimeDataPoints = map.querySourceFeatures('crimedata', {
                    sourceLayer: 'crimelayer',
                    filter: ['in', ["get", "year"], ["literal", yearCrimeFilter]]
                });
            }
            else {
                crimeDataPoints = map.querySourceFeatures('crimedata', {
                    sourceLayer: 'crimelayer',
                    filter: gtrFil
                });
            }
            let slow = 0;
            let fail = 0;
            let oob = 0;
            let firstTry = 0;
            let adjustment = [[0, 0], [0, 1], [0, -1], [1, 0], [-1, 0], [-1, -1], [-1, 1], [1, 1], [1, -1]];
            crimeDataPoints.forEach(f => {

                //bounds check
                let inbounds = true;

                let fLon = f.geometry.coordinates[0];
                let fLat = f.geometry.coordinates[1];

                inbounds = fLon < bounds._ne.lng && fLon > bounds._sw.lng && fLat < bounds._ne.lat && fLat > bounds._sw.lat

                if (inbounds) {
                    //adjust tiles if zoom above 14.
                    if (zoom >= 14) {
                        f.tile.x = lon2tile(f.geometry.coordinates[0], Math.floor(zoom));
                        f.tile.y = lat2tile(f.geometry.coordinates[1], Math.floor(zoom));
                    }
                    let result = false;
                    for (let i = 0; i < 9; i++) {
                        if (hexLookup[f.tile.x + adjustment[i][0]] != undefined && hexLookup[f.tile.x + adjustment[i][0]][f.tile.y + adjustment[i][1]] != undefined) {

                            let hexLookupBin = hexLookup[f.tile.x + adjustment[i][0]][f.tile.y + adjustment[i][1]];

                            for (let i = 0; i < hexLookupBin.length; i++) {
                                let a = hexLookupBin[i];
                                result = pointInPolygon(f.geometry, a.geometry);
                                if (result) {

                                    let p = f.properties;
                                    if (zoom < 14) {

                                        if (yearCrimeFilter.includes(p.year) && p.point_count > 0 && p.wcnt > 0) {
                                            if (a.properties.wcnt == undefined) {
                                                a.properties.wcnt = p.wcnt;
                                                a.properties.cnt = p.cnt;
                                                a.properties.additions = 1;
                                            }
                                            else {
                                                a.properties.wcnt += p.wcnt;
                                                a.properties.cnt += p.cnt;
                                                a.properties.additions += 1;
                                            }

                                        }
                                    }
                                    else if (zoom >= 14) {
                                        if (wcntFields.some(a => Number(p[a]) > 0)) {
                                            let wcnt = wcntFields.filter(x => Number(p[x]) > 0).reduce((a, b) => a + Number(p[b]), 0);
                                            let cnt = cntFields.filter(x => Number(p[x]) > 0).reduce((a, b) => a + Number(p[b]), 0);
                                            if (a.properties.wcnt == undefined) {
                                                a.properties.wcnt = wcnt;
                                                a.properties.cnt = cnt;
                                            }
                                            else {
                                                a.properties.wcnt += wcnt;
                                                a.properties.cnt += cnt;
                                            }
                                        }
                                    }

                                    break;
                                }
                            }
                        }
                        if (result) {
                            if (i == 0) {
                                firstTry += 1;
                            }

                            break;
                        }
                    }

                    if (!result) {
                        fail += 1;
                    }
                }
                else {
                    oob += 1;
                }

            });
            if (fail > 0)
                console.log("%cFAILS", "background: red; color: yellow; font-size: large", fail);
            //  6   7   8
            // /30  /15 /7.5
            //  64  128 256
            console.log("zoom", zoom);
            hexPolys.features.forEach(a => a.properties.wcntScale = a.properties.wcnt / (6000 / Math.pow(2, zoom)));
            hexPolys.features = hexPolys.features.filter(a => a.properties.wcnt > 0);
            map.getSource('crimehex').setData(hexPolys);
        }
        else {
            if (map != null) {
                let src = map.getSource('crimehex');
                if (src != null) {
                    src.setData(emptySource);
                }
            }
        }
    }

    const debounce = (method, delayMs) => {
        delayMs = delayMs || 500;
        setDebounceTimer(null);
        clearTimeout(debounceTimer);
        var t = setTimeout(() => {
            method()
        }, delayMs);
        setDebounceTimer(t);
    }

    useEffect(() => {
        debounce(() => renderPriceHist(bounds, zoom, map, showHistoricPrices, yearHistPriceFilter, priceDiffMode, width), 100);
    }, [bounds, zoom, map, showHistoricPrices, yearHistPriceFilter, priceDiffMode, width]);

    useEffect(() => {
        debounce(() => renderCrime(bounds, zoom, map, showCrime, yearCrimeFilter, width), 100);
    }, [bounds, zoom, map, showCrime, yearCrimeFilter, width]);

    useEffect(() => {
        debounce(() => renderSchools(bounds, zoom, map, showSchools, schoolsFilter, demographicsFilter, width), 100);
    }, [bounds, zoom, map, showSchools, schoolsFilter, demographicsFilter, width]);

    useEffect(() => {
        console.log("clickity", showHistoricPrices);
        if (showHistoricPrices != null && map != null) {
            if (showHistoricPrices) {
                map.setLayoutProperty(
                    'pricehistorypoint',
                    'visibility',
                    'visible');

                map.setLayoutProperty(
                    'pricehistorylabel',
                    'visibility',
                    'visible');
            }
            else {
                map.setLayoutProperty(
                    'pricehistorypoint',
                    'visibility',
                    'none');
                map.setLayoutProperty(
                    'pricehistorylabel',
                    'visibility',
                    'none');
            }
        }
    }, [showHistoricPrices, map]);

    useEffect(() => {
        if (showCrime != null && map != null) {
            if (showCrime) {
                map.setLayoutProperty(
                    'crimepoint',
                    'visibility',
                    'visible');

                map.setLayoutProperty(
                    'crimelabel',
                    'visibility',
                    'visible');
            }
            else {
                map.setLayoutProperty(
                    'crimepoint',
                    'visibility',
                    'none');
                map.setLayoutProperty(
                    'crimelabel',
                    'visibility',
                    'none');
            }
        }
    }, [showCrime, map]);

    useEffect(() => {
        console.log(showSchools);
        if (showSchools != null && map != null) {
            if (showSchools) {
                map.setLayoutProperty(
                    'schooltext',
                    'visibility',
                    'visible');

                map.setLayoutProperty(
                    'schoolpoint',
                    'visibility',
                    'visible');
            }
            else {
                map.setLayoutProperty(
                    'schooltext',
                    'visibility',
                    'none');
                map.setLayoutProperty(
                    'schoolpoint',
                    'visibility',
                    'none');
            }
        }
    }, [showSchools, map]);

    useEffect(() => {
        if (map != null && map.getSource('highlightPointData') != null) {
            if (activePropertyCoords == null || showProps == false) {
                map.getSource('highlightPointData').setData(emptySource);
                spider.current.unspiderfy();
                setActiveMarker(null);
            } else {

                console.log("activePropertyCoords", activePropertyCoords);

                //3 cases: blue point selected, red point selected not spidered, red point selected; spidered.
                if (activePropertyCoords.server) {
                    //is server point (red)
                    let currentLegs = spider.current.getCurrentLegs();
                    console.log("currentLegs", currentLegs);
                    let targetIsSpidered = false;
                    let selectedSpiderNode = null;
                    let iLoc = 0;
                    currentLegs.every(a => {
                        if (a.feature != null && a.feature.id != null) {
                            if (a.feature.id == activePropertyCoords.selectedId) {
                                targetIsSpidered = true;
                                selectedSpiderNode = a;
                                return false;
                            }
                        }
                        return true;
                    });

                    if (targetIsSpidered) {
                        //red point; spidered.
                        console.log("WAS SPIDERED");
                    }
                    else {
                        //red point; not spidered.
                        spider.current.unspiderfy();
                        console.log("activePropertyCoords.coords", activePropertyCoords.coords);
                        let point = map.project([activePropertyCoords.coords[0], activePropertyCoords.coords[1]]);
                        const features = map.queryRenderedFeatures(point, {
                            layers: ['detailClusterLayer']
                        });
                        console.log("activePropertyFeatures", features);


                        let f = features[0];
                        if (f) {
                            console.log(f.geometry.coordinates);
                            let propArr = JSON.parse(f.properties.collection);
                            console.log(propArr);

                            spider.current.spiderfy(f.geometry.coordinates, propArr);
                            currentLegs = spider.current.getCurrentLegs();
                            console.log("current legs post spider", currentLegs);

                            currentLegs.every((a, i) => {
                                if (a.feature != null && a.feature.id != null) {
                                    if (a.feature.id == activePropertyCoords.selectedId) {
                                        selectedSpiderNode = a;
                                        iLoc = i;
                                        return false;
                                    }
                                }
                                return true;
                            });
                        }
                    }

                    if (selectedSpiderNode) {
                        console.log("found node");
                        let style = {
                            marginLeft: selectedSpiderNode.elements.container.marginLeft,
                            marginTop: selectedSpiderNode.elements.container.marginTop,
                            transform: selectedSpiderNode.elements.container.transform,
                        };

                        var placeholder = document.createElement('div')
                        //const rendered = ReactDOM.render(markerHtml, placeholder);
                        //let markerElement = { element: rendered };
                        var innerPlaceholder = document.createElement("div");

                        innerPlaceholder.innerHTML =
                            '<svg width="38" height="38">' +
                            '<circle cx="19" cy="19" r="18" fill=' + theme.palette.mapicons.primary + ' stroke=' + theme.palette.mapicons.background + ' stroke-width="2"/>' +
                            '<text x="50%" y="50%" text-anchor="middle" fill=' + theme.palette.mapicons.background + ' font-size="12" font-weight="600" font-family="open sans" transform="translate(0,4)">' +
                            selectedSpiderNode.feature.priceStr +
                            '</text>' +
                            '</svg>';
                        //console.log("MARG",selectedSpiderNode.elements.container.marginLeft);
                        placeholder.style['margin-left'] = selectedSpiderNode.elements.container.style.marginLeft;
                        placeholder.style['margin-top'] = selectedSpiderNode.elements.container.style.marginTop;
                        placeholder.style['z-index'] = 0;
                        placeholder.style['cursor'] = 'pointer';

                        if (!targetIsSpidered) {
                            innerPlaceholder.className = 'spider-selected-marker animate initial';

                            setTimeout(function () {
                                innerPlaceholder.className = (innerPlaceholder.className || '').replace('initial', '');
                                innerPlaceholder.style['transitionDelay'] = ((150 / 1000) / currentLegs.length * iLoc * 3) + 's';
                            });
                        }

                        placeholder.appendChild(innerPlaceholder);

                        let marker = new maplibregl.Marker(placeholder).setLngLat(activePropertyCoords.coords);
                        //console.log("rendered",rendered);
                        console.log("MARKER", marker);
                        setActiveMarker(marker);
                    }
                    else {
                        console.log("didn't find node");
                    }
                }
                else {


                    //is blue point.
                    console.log("BLUE POINT");
                    spider.current.unspiderfy();
                    var placeholder = document.createElement("div");

                    placeholder.innerHTML =
                        '<svg width="38" height="38">' +
                        '<circle cx="19" cy="19" r="18" fill=' + theme.palette.mapicons.primary + ' stroke=' + theme.palette.mapicons.background + ' stroke-width="2"/>' +
                        '<text x="50%" y="50%" text-anchor="middle" fill=' + theme.palette.mapicons.background + ' font-size="12" font-weight="600" font-family="open sans" transform="translate(0,4)">' +
                        activePropertyCoords.val +
                        '</text>' +
                        '</svg>';
                    placeholder.style['cursor'] = 'pointer';
                    let marker = new maplibregl.Marker(placeholder).setLngLat(activePropertyCoords.coords);
                    setActiveMarker(marker);
                    //
                    //let geojson = {
                    //    'type': 'FeatureCollection',
                    //    'features': []
                    //};

                    //let point = {
                    //    'type': 'Feature',
                    //    'geometry': {
                    //        "coordinates": [activePropertyCoords.coords[0], activePropertyCoords.coords[1]],
                    //        "type": "Point"
                    //    },
                    //    'properties': {
                    //        'val': activePropertyCoords.val
                    //    }
                    //};

                    //geojson.features.push(point);


                    //map.getSource('highlightPointData').setData(geojson);
                }
            }

        }
    }, [activePropertyCoords, map, showProps]);


    const emptySourceGeoJson = {
        'type': 'geojson',
        'data': {
            'type': 'FeatureCollection',
            'features': []
        }
    };

    //pretty naff, do this better...
    const emptySource = {
        'type': 'FeatureCollection',
        'features': []
    };



    useEffect(() => {
        if (map != null && map.getSource('plotPoly') != null) {
            if (poly == null) {
                //remove source.
                map.getSource('plotPoly').setData(emptySource);
            }
            else {
                //overwrite source.
                let geojson = {
                    'type': 'FeatureCollection',
                    'features': []
                };
                let pol = {
                    'type': 'Feature',
                    'geometry': JSON.parse(poly),
                    'properties': {
                        'id': String(new Date().getTime())
                    }
                };
                geojson.features.push(pol);
                map.getSource('plotPoly').setData(geojson);
            }
        }
    }, [poly, map]);

    useEffect(() => {
        if (map != null && map.getSource('crimes') != null) {
            if (poly == null) {
                //remove source.

                map.getSource('crimes').setData(emptySource);
            }
            else {
                //overwrite source.
                let geojson = {
                    'type': 'FeatureCollection',
                    'features': []
                };
                crimes.forEach(i => {
                    let pol = {
                        'type': 'Feature',
                        'geometry': {
                            "coordinates": [i.Location.Longitude, i.Location.Latitude],
                            "type": "Point"
                        },
                        'properties': {
                            'id': i.Id,
                            'location': i.LocationDescription,
                            'crimetypes': i.Types
                        }
                    };

                    geojson.features.push(pol);
                });


                map.getSource('crimes').setData(geojson);
            }
        }
    }, [crimes, map]);

    useEffect(() => {
        if (map != null && map.getSource('schools') != null) {
            if (poly == null) {
                //remove source.
                map.getSource('schools').setData(emptySource);
                map.getSource('schoolCatchments').setData(emptySource);
            }
            else {
                //overwrite source.
                let geojson = {
                    'type': 'FeatureCollection',
                    'features': []
                };

                let geojsonCatchments = {
                    'type': 'FeatureCollection',
                    'features': []
                };
                schools.forEach(i => {
                    let center = [i.Location.Longitude, i.Location.Latitude];
                    let radius = i.CatchmentMetresMed / 1000;
                    let options = { steps: 120, units: 'kilometers', properties: { id: i.SchoolId } }
                    let circlePoly = circle(center, radius, options);
                    //let circleFeature = {
                    //    'type': 'Feature',
                    //    circlePoly,
                    //    //'geometry': {
                    //    //    "coordinates": circlePoly,
                    //    //    "type": "Polygon"
                    //    //},
                    //    'properties': {
                    //        'id':,
                    //    }
                    //};

                    let circleFeature = circlePoly;
                    let pointFeature = {
                        'type': 'Feature',
                        'geometry': {
                            "coordinates": [i.Location.Longitude, i.Location.Latitude],
                            "type": "Point"
                        },
                        'properties': {
                            'id': i.SchoolId,
                            'type': i.SchoolType,
                            'offsted': i.OfstedRatingString,
                            'capcity': i.PercentCapacity,
                            'size': i.SchoolSize,
                            'name': i.SchoolName,
                            'sex': i.Sex,
                            'denomination': i.Denomination,
                            'structure': i.Structure
                        }
                    };

                    circlePoly['id'] = i.SchoolId;
                    pointFeature['id'] = i.SchoolId;

                    geojson.features.push(pointFeature);
                    geojsonCatchments.features.push(circleFeature);

                });

                map.getSource('schools').setData(geojson);
                map.getSource('schoolCatchments').setData(geojsonCatchments);
            }
        }
    }, [schools, map]);

    useEffect(() => {
        if (map != null && toggleFly != null && toggleFly.loc != null) {
            console.log("TOGGLEFLY", toggleFly);
            map.flyTo({
                padding: {
                    left: toggleFly.left,
                    right: toggleFly.right
                },
                speed: toggleFly.loc.zoom == null ? 1 : 1,
                zoom: toggleFly.loc.zoom == null ? 16 : Number(toggleFly.loc.zoom),
                center: [Number(toggleFly.loc.longitude), Number(toggleFly.loc.latitude)]
            });
        }
    }, [toggleFly, map]);


    //useEffect(() => {
    //    if (init) {
    //        if (productData) {
    //            let geojson = {
    //                'type': 'FeatureCollection',
    //                'features': [{
    //                    'type': 'Feature',
    //                    'geometry': {
    //                        "coordinates": [productData.lon, productData.lat],
    //                        "type": "Point"
    //                    },
    //                    'properties': {
    //                        'id': productData.id,
    //                    }
    //                }]
    //            };

    //            map.setFilter('detailLayer', ["all", ['!=', ["get", "id"], productData.id], ["any", ['==', "grp", false], ['has', 'point_count']]]);
    //            map.getSource('selectedPoint').setData(geojson);
    //        }
    //        else {
    //            map.setFilter('detailLayer', ["any", ['==', "grp", false], ['has', 'point_count']]);
    //            map.getSource('selectedPoint').setData(emptySource);
    //        }
    //    }
    //}, [productData, map]);

    useEffect(() => {
        if (map != null) {
            if (typeof map.getSource('clusterData') === 'undefined' || typeof map.getSource('resultData') === 'undefined') {
                console.log("SETTING SOURCE");
                //initial setup - not the best place to do this really...
                let clusterSource = emptySourceGeoJson;
                clusterSource.cluster = true;
                clusterSource.clusterProperties = { "sum": ["+", ["get", "count",]] };
                clusterSource.clusterRadius = 75;
                map.addSource('clusterData', clusterSource);
                clusterSource.cluster = true;
                clusterSource.clusterProperties = {
                    "sum": ["+", ["get", "cnt",]],
                    "myids": ["concat", ["concat", ["get", "id"], ","]],
                    /*"propData": ["concat", ["concat", ["get", "id"], ";", ["get", "priceStr"], ","]],*/
                    "propData": ["concat",
                        ["case",
                            ["has", "idPriceStr"], ["get", "idPriceStr"],
                            ["concat", ["get", "id"], ";", ["get", "priceStr"], ","]
                        ], ","
                    ]
                };

                clusterSource.clusterRadius = 25;
                clusterSource.clusterMaxZoom = 23;
                map.addSource('resultData', emptySourceGeoJson);
                clusterSource.cluster = false;
                clusterSource.clusterProperties = null;
                map.addSource('plotPoly', emptySourceGeoJson);
                map.addSource('crimes', emptySourceGeoJson);
                map.addSource('schools', emptySourceGeoJson);
                map.addSource('schoolCatchments', emptySourceGeoJson);
                map.addSource('selectedPoint', emptySourceGeoJson);
                map.addSource('highlightPointData', emptySourceGeoJson);

            }

            if (typeof map.getLayer('detailLayer') === 'undefined') {
                //initial setup - not the best place to do this really...
                AddLayers(map);
            }

            setInit(true);
        }
    }, [map]);

    useEffect(() => {
        if (data != null && data.data != null && map != null) {
            //AddToLayer(map, data.data, productData);
            console.log("Data upd");
            setLastHash(oldHash => {

                if (!showProps) {
                    map.setLayoutProperty(
                        'clusters',
                        'visibility',
                        'none');
                    map.setLayoutProperty(
                        'cluster-count',
                        'visibility',
                        'none');

                    map.setLayoutProperty(
                        'detailLayer',
                        'visibility',
                        'none');

                    map.setLayoutProperty(
                        'detailLayer-price',
                        'visibility',
                        'none');

                    map.setLayoutProperty(
                        'detailClusterLayer',
                        'visibility',
                        'none');

                    map.setLayoutProperty(
                        'detailClusterLayer-counts',
                        'visibility',
                        'none');

                    return "";
                }

                if (oldHash === data.hash) {
                    console.log("hash", oldHash);
                    return oldHash; //do nothing.
                }
                else {


                }

                //update data.
                if (data.data.data != null) {
                    if (data.isItemResult) {
                        console.log("setting result data");
                        map.getSource('resultData').setData(data.data.data);
                    }
                    else {
                        map.getSource('clusterData').setData(data.data.data);
                    }
                }

                //hide/show layers
                if (!data.isItemResult) {
                    spider.current.unspiderfy();
                    //aggregated
                    map.setLayoutProperty(
                        'clusters',
                        'visibility',
                        'visible');
                    map.setLayoutProperty(
                        'cluster-count',
                        'visibility',
                        'visible');

                    map.setLayoutProperty(
                        'detailLayer',
                        'visibility',
                        'none');

                    map.setLayoutProperty(
                        'detailLayer-price',
                        'visibility',
                        'none');

                    map.setLayoutProperty(
                        'detailClusterLayer',
                        'visibility',
                        'none');

                    map.setLayoutProperty(
                        'detailClusterLayer-counts',
                        'visibility',
                        'none');

                } else {
                    //detail
                    map.setLayoutProperty(
                        'clusters',
                        'visibility',
                        'none');
                    map.setLayoutProperty(
                        'cluster-count',
                        'visibility',
                        'none');
                    map.setLayoutProperty(
                        'detailLayer',
                        'visibility',
                        'visible');
                    map.setLayoutProperty(
                        'detailLayer-price',
                        'visibility',
                        'visible');
                    map.setLayoutProperty(
                        'detailClusterLayer',
                        'visibility',
                        'visible');
                    map.setLayoutProperty(
                        'detailClusterLayer-counts',
                        'visibility',
                        'visible');
                }


                return data.hash;

            });
            console.log("Data upd done");
        }
    }, [productData, map, data, showProps])

    const AddLayers = (mapLocal) => {

        console.log("Adding layers");;
        mapLocal.addLayer({
            id: 'detailLayer',
            type: 'circle',
            source: 'resultData',
            filter: ["any", ['==', "grp", false], ['has', 'point_count']],
            paint: {
                'circle-color': theme.palette.mapicons.background,
                'circle-radius': 14,
                'circle-stroke-width': [
                    "case",
                    ['has', 'point_count'],
                    2,
                    1,
                ],
                'circle-stroke-color': theme.palette.mapicons.primary,
            }
        });

        mapLocal.addLayer({
            id: 'detailLayer-price',
            type: 'symbol',
            source: 'resultData',
            filter: ["any", ['==', "grp", false], ['has', 'point_count']],
            textAllowOverlap: true,
            paint: {
                "text-color": theme.palette.mapicons.primary
            },
            layout: {
                "text-field": [
                    "case",
                    ['has', 'point_count'],
                    ["get", "sum"],
                    ["get", "priceStr"],
                ],
                'text-font': [
                    'Open Sans Semibold'
                ],
                'text-size': 10
            }
        });

        mapLocal.addLayer({
            'id': 'selectedPropertyDotLayer',
            type: 'circle',
            'source': 'selectedPoint',
            paint: {
                'circle-color': '#f00',
                'circle-radius': 4,
                'circle-stroke-width': 1,
                'circle-stroke-color': theme.palette.mapicons.background
            }
        });

        mapLocal.addLayer({
            id: 'detailClusterLayer',
            type: 'circle',
            source: 'resultData',
            filter: ['==', "grp", true],
            paint: {
                'circle-color': theme.palette.mapicons.altBackground,
                'circle-radius': 14,
                'circle-stroke-width': 2,
                'circle-stroke-color': theme.palette.mapicons.alt,
            }
        });

        mapLocal.addLayer({
            id: 'detailClusterLayer-counts',
            type: 'symbol',
            source: 'resultData',
            filter: ['==', "grp", true],
            textAllowOverlap: true,
            paint: {
                "text-color": theme.palette.mapicons.alt,
            },
            layout: {
                "text-field": ["concat", ["get", "cnt"], "..."],
                'text-font': [
                    'Open Sans Semibold'
                ],
                'text-size': 10
            }
        });

        map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'clusterData',
            //filter: ['has', 'point_count'],
            paint: {
                //'circle-color': "#fee",
                'circle-color': theme.palette.mapicons.background,
                'circle-radius': [
                    'step',
                    ["coalesce", ["get", "sum"], ["get", "count"]],
                    30,
                    100,
                    40,
                    1000,
                    50
                ],
                //'circle-stroke-color': "#8b0000",
                'circle-stroke-color': theme.palette.mapicons.primary,
                'circle-stroke-width': 3,
            }
        });

        mapLocal.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'clusterData',
            //filter: ['has', 'point_count'],
            layout: {
                //"text-field": ["concat", ["coalesce", ["get", "sum"], ["get", "count"]], " - ", ['get', 'label']],
                "text-field": ["coalesce", ["get", "sum"], ["get", "count"]],
                'text-font': [
                    'Open Sans Semibold'
                ],
                'text-size': 12
            }
        });

        //selected point layer:
        mapLocal.addLayer({
            id: 'selectedPointLayer',
            type: 'circle',
            source: 'highlightPointData',
            paint: {
                'circle-color': theme.palette.mapicons.primary,
                'circle-radius': 18,
                'circle-stroke-width': 3,
                'circle-stroke-color': theme.palette.mapicons.background,
            }
        });


        mapLocal.addLayer({
            id: 'selectedPointLayer-price',
            type: 'symbol',
            source: 'highlightPointData',
            textAllowOverlap: true,
            paint: {
                "text-color": theme.palette.mapicons.background
            },
            layout: {
                "text-field": ["get", "val"],
                'text-font': [
                    'Open Sans Bold'
                ],
                'text-size': 12,
            }
        });

        //crimes
        mapLocal.addLayer({
            'id': 'crimesLayer',
            'type': 'symbol',
            'source': 'crimes',
            'layout': {
                'icon-image': 'police',
                'icon-size': 0.3,
                'icon-allow-overlap': true,
            }
        });

        mapLocal.addLayer({
            'id': 'schoolsLayer',
            'type': 'symbol',
            'source': 'schools',
            'layout': {
                'icon-image': 'school',
                'icon-size': 0.3,
                'icon-allow-overlap': true,
            }
        });

        //schoolCatchments
        mapLocal.addLayer({
            'id': 'schoolCatchmentsLayer',
            'type': 'fill',
            'source': 'schoolCatchments', // reference the data source
            'layout': {},
            'paint': {
                'fill-color': '#004080', // blue color fill
                //'fill-opacity': 0.05,
                //'visibility':
                //    ['case',
                //        ['==', ['feature-state', 'vis'], null], 'none', 'visible'
                //    ],
                'fill-opacity':
                    ['case',
                        ['==', ['feature-state', 'vis'], null], 0, 0.5
                    ],
                'fill-outline-color': '#000000'
            }
        });

        mapLocal.addLayer({
            'id': 'plotLayer',
            'type': 'fill',
            'source': 'plotPoly', // reference the data source
            'layout': {},
            'paint': {
                'fill-color': '#0080ff', // blue color fill
                'fill-opacity': 0.2
            }
        });
        // Add a black outline around the polygon.
        mapLocal.addLayer({
            'id': 'plotOutline',
            'type': 'line',
            'source': 'plotPoly',
            'layout': {},
            'paint': {
                'line-color': '#000',
                'line-width': 3
            }
        });
    }


    const SetupMouseOver = (mapLocal) => {

        var popup = new maplibregl.Popup({
            closeButton: false,
            closeOnClick: true,
            className: "removeMapBoxPopupPadding maxWidth600"
        });

        mapLocal.on('mouseenter', 'detailLayer', function (e) {
            // Change the cursor style as a UI indicator.
            mapLocal.getCanvas().style.cursor = 'pointer';
        });

        mapLocal.on('mouseenter', 'detailClusterLayer', function (e) {
            // Change the cursor style as a UI indicator.
            mapLocal.getCanvas().style.cursor = 'pointer';
        });

        mapLocal.on('mouseleave', 'detailClusterLayer', function () {
            mapLocal.getCanvas().style.cursor = '';
        });

        //mapLocal.on('mouseenter', 'detailLayer', function (e) {
        //    // Change the cursor style as a UI indicator.
        //    mapLocal.getCanvas().style.cursor = 'pointer';
        //    var coordinates = e.features[0].geometry.coordinates.slice();
        //    var description = e.features[0].properties.description;
        //    var price = e.features[0].properties.price;
        //    var sqFt = e.features[0].properties.sqFt;
        //    var postCode = e.features[0].properties.postCode;

        //    var tblData = [["Postcode", postCode], ["Sqft", sqFt], ["bathrooms", e.features[0].properties.bathrooms], ["bedrooms", e.features[0].properties.bedrooms]];

        //    var dom = (<ThemeProvider theme={theme}>
        //        <Paper >
        //            <Box p={1}>
        //                <Typography variant="h6">{description}</Typography>
        //                <Typography variant="subtitle1">£{price}</Typography>
        //                <BasicTable data={tblData} />
        //            </Box>
        //        </Paper>
        //    </ThemeProvider>)

        //    var placeholder = document.createElement('div')
        //    ReactDOM.render(dom, placeholder);

        //    // Ensure that if the map is zoomed out such that multiple
        //    // copies of the feature are visible, the popup appears
        //    // over the copy being pointed to.
        //    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        //        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        //    }

        //    // Populate the popup and set its coordinates
        //    // based on the feature found.
        //    //popup.setLngLat(coordinates).setHTML(html).addTo(mapLocal);
        //    popup.setLngLat(coordinates).setDOMContent(placeholder).addTo(mapLocal);
        //});

        mapLocal.on('mouseenter', 'crimesLayer', function (e) {
            // Change the cursor style as a UI indicator.
            mapLocal.getCanvas().style.cursor = 'pointer';
            var coordinates = e.features[0].geometry.coordinates.slice();
            var crimetype = JSON.parse(e.features[0].properties.crimetypes);
            var month = e.features[0].properties.month;
            //TODO: redo this at indexer - cluster by lat long, and then by crimetype to give counts and date array. render as pie?
            var dataState = [];
            crimetype.forEach(a => dataState.push([a.Name, a.TotalCount]));
            var placeholder = document.createElement('div')
            var tbl = (<ThemeProvider theme={theme}>
                <BasicTable data={dataState} />
            </ThemeProvider>)
            ReactDOM.render(tbl, placeholder);

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            popup.setLngLat(coordinates).setDOMContent(placeholder).addTo(mapLocal);
        });

        //schools
        mapLocal.on('mouseenter', 'schoolsLayer', function (e) {
            // Change the cursor style as a UI indicator.
            mapLocal.getCanvas().style.cursor = 'pointer';

            mapLocal.setFeatureState({
                source: 'schoolCatchments',
                id: e.features[0].id
            }, {
                vis: 1
            });


            var coordinates = e.features[0].geometry.coordinates.slice();
            var name = e.features[0].properties.name;
            var offsted = e.features[0].properties.offsted;
            var schoolType = e.features[0].properties.schoolType;
            var structure = e.features[0].properties.structure;
            var capacity = e.features[0].properties.capcity;
            var size = e.features[0].properties.size;
            var sex = e.features[0].properties.sex;
            var denomination = e.features[0].properties.denomination;
            //TODO: redo this at indexer - cluster by lat long, and then by crimetype to give counts and date array. render as pie?
            //var html = `<h6>${name}</h6><p>Offsted: ${offsted}<br />`
            //if (schoolType != undefined) {
            //    html += `${schoolType} - ${structure}<br />`
            //}
            //else {
            //    html += `${structure}<br />`
            //}
            //html += `${capcity}% capacity (${size})<br />Gender: ${sex}<br />Denomination: ${denomination}</p>`
            var tblData = [["Offsted", offsted], ["Capacity", capacity], ["Size", size], ["Gender", sex], ["Denomination", denomination]];

            var dom = (<ThemeProvider theme={theme}>
                <Paper >
                    <Box p={1}>
                        <Typography variant="h6">{name}</Typography>
                        <Typography variant="p">{schoolType != undefined && schoolType + " - "}{structure}</Typography>
                        <BasicTable data={tblData} />
                    </Box>
                </Paper>
            </ThemeProvider>)

            var placeholder = document.createElement('div')
            ReactDOM.render(dom, placeholder);

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            // Populate the popup and set its coordinates
            // based on the feature found.
            //popup.setLngLat(coordinates).setHTML(html).addTo(mapLocal);
            popup.setLngLat(coordinates).setDOMContent(placeholder).addTo(mapLocal);
        });

        mapLocal.on('click', 'detailLayer', function (e) {
            var f = e.features[0];
            if (f.properties.cluster) {
                mapLocal.getSource('resultData').getClusterExpansionZoom(
                    f.properties.clusterId,
                    (err, zoomX) => {
                        console.log("zoomX", zoomX);
                        if (err) return;
                        let targZoom = Number(mapLocal.getZoom().toFixed(2)) + 1.5
                        mapLocal.easeTo({
                            center: f.geometry.coordinates,
                            zoom: targZoom,
                            duration: 1500
                        });
                    }
                );
            }
            else {
                setPropertyMarkerClicked(a => f.properties.id);
            }
            spider.current.unspiderfy();
            console.log("detail layer click", e.features);
        });

        mapLocal.on('mouseleave', 'detailLayer', function () {
            mapLocal.getCanvas().style.cursor = '';
            popup.remove();
        });

        //TODO: This has a bug. zoom doesn't work second time around.
        mapLocal.on('click', 'clusters', (e) => {
            const features = mapLocal.queryRenderedFeatures(e.point, {
                layers: ['clusters']
            });
            const clusterId = features[0].properties.cluster_id;
            mapLocal.getSource('clusterData').getClusterExpansionZoom(
                clusterId,
                (err, zoomX) => {
                    if (err) return;
                    let targZoom = Number(mapLocal.getZoom().toFixed(2)) + 2.5
                    mapLocal.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: targZoom
                    });
                }
            );
        });

        mapLocal.on('click', 'detailClusterLayer', (e) => {
            const features = mapLocal.queryRenderedFeatures(e.point, {
                layers: ['detailClusterLayer']
            });
            console.log("CLICK", features);


            let f = features[0];
            console.log(f.geometry.coordinates);
            let propArr = JSON.parse(f.properties.collection);
            console.log(propArr);
            //let pol = {
            //    'type': 'Feature',
            //    'geometry': {
            //        "coordinates": [i.Location.Longitude, i.Location.Latitude],
            //        "type": "Point"
            //    },
            //    'properties': {
            //        'id': i.Id,
            //        'location': i.LocationDescription,
            //        'crimetypes': i.Types
            //    }
            //};

            spider.current.unspiderfy();
            //spider.current.spiderfy(f.geometry.coordinates, propArr);
            setPropertyMarkerClicked(propArr[0].id)
        });

        mapLocal.on('mouseleave', 'schoolsLayer', function (e) {
            var bbox = [
                [e.point.x - 20, e.point.y - 20],
                [e.point.x + 20, e.point.y + 20]
            ];
            var features = mapLocal.queryRenderedFeatures(bbox, {
                layers: ['schoolsLayer']
            });

            if (features.length == 0) {
                //failed to grab so remove ALL catchments.
                var x = mapLocal.querySourceFeatures('schoolCatchments');
                x.forEach(a => {
                    mapLocal.setFeatureState({
                        source: 'schoolCatchments',
                        id: a.id
                    }, {
                        vis: null
                    });
                });
            }
            else {
                features.forEach(a => {
                    mapLocal.setFeatureState({
                        source: 'schoolCatchments',
                        id: a.id
                    }, {
                        vis: null
                    });
                });
            }

            mapLocal.getCanvas().style.cursor = '';
            popup.remove();
        });

        mapLocal.on('mouseleave', 'crimesLayer', function () {
            mapLocal.getCanvas().style.cursor = '';
            popup.remove();
        });

        mapLocal.on('click', 'crimehexlayer', (event) => {
            console.log("clicky", event.features[0].properties);
        });

        mapLocal.on('click', 'pricediffhex', (event) => {
            console.log("clicky", event.features[0].properties);
        });

        mapLocal.on('click', 'pricehistoryhex', (event) => {
            console.log("clicky", event.features[0].properties);
        });

        mapLocal.on('click', 'pricehistorypoint', (event) => {
            console.log(event);
            console.log(event.features);
            const features = mapLocal.queryRenderedFeatures(event.point, {
                layers: ['pricehistorypoint']
            });
            console.log("CLICK", features);

            let placeholder = document.createElement('div');
            let dom = (<ThemeProvider theme={theme}>
                <Paper >
                    <Box p={1}>
                        <PriceHistoryPopup data={event.features[0].properties} />
                    </Box>
                </Paper>
            </ThemeProvider>)
            ReactDOM.render(dom, placeholder);

            popup.setLngLat(event.features[0].geometry.coordinates).setDOMContent(placeholder).addTo(mapLocal);
        });

        mapLocal.on('click', 'schoolpoint', (event) => {
            console.log(event);
            console.log(event.features);
            const features = mapLocal.queryRenderedFeatures(event.point, {
                layers: ['schoolpoint']
            });
            console.log("school-CLICK", features);
            if (features.length > 0) {
                setSelectedSchool(features[0].properties.Id);
            }
        });

        mapLocal.on('click', 'crimepoint', (event) => {
            const features = mapLocal.queryRenderedFeatures(event.point, {
                layers: ['crimepoint']
            });
            let placeholder = document.createElement('div');
            let dom = (<CrimePopup data={event.features[0].properties} filter={crimeFilterRef.current} />);
            ReactDOM.render(dom, placeholder);

            var popupX = new maplibregl.Popup({
                closeButton: false,
                closeOnClick: true,
                className: "trans"
            });

            popupX.on('close', () => {
                console.log('Popup was closed');
                mapLocal.setPaintProperty(
                    'crimepoint',
                    'circle-opacity',
                    {
                        "stops": [
                            [14, 0],
                            [15, 1]
                        ]
                    }
                );
                mapLocal.setPaintProperty(
                    'crimepoint',
                    'circle-stroke-width',
                    1
                );
                mapLocal.setPaintProperty(
                    'crimelabel',
                    'text-opacity',
                    {
                        "stops": [
                            [15, 0],
                            [16, 1]
                        ]
                    }
                );
            });

            popupX.setLngLat(event.features[0].geometry.coordinates).setDOMContent(placeholder).addTo(mapLocal);
            mapLocal.setPaintProperty(
                'crimepoint',
                'circle-opacity',
                0.1
            );
            mapLocal.setPaintProperty(
                'crimepoint',
                'circle-stroke-width',
                0
            );
            mapLocal.setPaintProperty(
                'crimelabel',
                'text-opacity',
                0
            );

        });
    }

    const SetupCrimePoint = (mapLocal, yearCrimeFilter) => {
        console.log("SETTING UP CRIME POINT");

        if (oldFilter != null) {
            console.log("CLEARING");
            mapLocal.off('click', 'crimepoint', oldFilter);
        }
        oldFilter = crimeClickFunc(mapLocal, yearCrimeFilter);


    }


    const Add3d = (mapLocal) => {
        //var layers = mapLocal.getStyle().layers;

        //var labelLayerId;
        //for (var i = 0; i < layers.length; i++) {
        //    if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
        //        labelLayerId = layers[i].id;
        //        break;
        //    }
        //}

        //mapLocal.addLayer(
        //    {
        //        'id': '3d-buildings',
        //        'source': 'composite',
        //        'source-layer': 'building',
        //        'filter': ['==', 'extrude', 'true'],
        //        'type': 'fill-extrusion',
        //        'minzoom': 12,
        //        'paint': {
        //            'fill-extrusion-color': '#aaa',

        //            // use an 'interpolate' expression to add a smooth transition effect to the
        //            // buildings as the user zooms in
        //            'fill-extrusion-height': [
        //                'interpolate',
        //                ['linear'],
        //                ['zoom'],
        //                13,
        //                0,
        //                13.1,
        //                ['get', 'height']
        //            ],
        //            'fill-extrusion-base': [
        //                'interpolate',
        //                ['linear'],
        //                ['zoom'],
        //                13,
        //                0,
        //                13.1,
        //                ['get', 'min_height']
        //            ],
        //            'fill-extrusion-opacity': 0.6
        //        }
        //    },
        //    labelLayerId
        //);
    }

    const SetClusterData = (mapLocal) => {
        var mapLayer = mapLocal.getLayer('detailLayer');
        if (typeof mapLayer !== 'undefined') {
            let detailLayer = mapLocal.queryRenderedFeatures({
                layers: ['detailLayer']
            });
            if (detailLayer !== 'undefined' && Array.isArray(detailLayer)) {
                const uniqueIds = new Set();
                const clusteredPoints = [];
                detailLayer.forEach(i => {
                    if (i.id != undefined && i.id > -1) {
                        const id = i.id;
                        if (!uniqueIds.has(id)) {
                            uniqueIds.add(id);
                            //uniqueFeatures.push(feature);
                            console.log("uniqueClusterFeature", i);
                            i.properties.propData.split(',').filter(a => a.length > 0).forEach(j => {
                                let propDataArr = j.split(';')
                                clusteredPoints.push({
                                    "id": propDataArr[0],
                                    "value": propDataArr[1],
                                    "cords": i.geometry.coordinates,
                                    "clusterId": id,
                                    "sum": i.properties.sum
                                });
                            });
                        }
                    }
                });
                console.log("clusterPoints", clusteredPoints);
                setClusterPoints(a => {
                    if (clusteredPoints == null && a == null) {
                        return a;
                    }
                    if (clusteredPoints.length != a.length) {
                        return clusteredPoints;
                    }

                    for (var i = 0; i < a.length; ++i) {
                        if (a[i].id !== clusteredPoints[i].id) return clusteredPoints;
                    }
                    return a;
                });
            }
        }
    };


    useEffect(() => {
        const initMap = ({ setMap, mapContainer }) => {
            //mapbox://styles/housec84/ckqccqfbe03r718o40dwed8e0?optimize=true sat
            //mapbox://styles/housec84/ckouk806n1vxr17lkaic8078y?optimize=true street

            var bounds = [[-15, 49], [3.4, 61]];
            const mapLocal = new maplibregl.Map({
                container: mapContainer.current,
                //style: 'mapbox://styles/mapbox/streets-v11',
                style: process.env.PUBLIC_URL + "/style.json",
                dark: false,
                center: [lng, lat],
                zoom: zoom,
                maxBounds: bounds
            });

            spider.current = new MapboxglSpiderifier(mapLocal, maplibregl, theme, {
                onClick: function (e, spiderLeg) {
                    console.log('Clicked on ', spiderLeg);
                    console.log("APC", activePropertyCoords);
                    setPropertyMarkerClicked(a => spiderLeg.feature.id);
                },
                onUnclick: () => {
                    setPropertyMarkerClicked(-1);
                    //setActivePropertyCoords(null);
                    setActiveMarker(null);
                    console.log("UNSET");
                },
                markerWidth: 40,
                markerHeight: 40,
            }, React);

            //mapLocal.addControl(new maplibregl.ScaleControl({
            //    maxWidth: 500,
            //    unit: 'metric'
            //}), 'top-right');

            mapLocal.on("load", () => {
                console.log("LOAD");
                setMap(mapLocal);
                setBounds(mapLocal.getBounds());
                Add3d(mapLocal);
                mapLocal.loadImage(
                    'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
                    function (error, image) {
                        mapLocal.addImage('custom-marker', image);
                    });
                SetupMouseOver(mapLocal);
            });



            mapLocal.on('idle', () => {
                console.log("idle");
                SetClusterData(mapLocal);

            });

            //mapLocal.on('sourcedata', (e) => {
            //    console.log(e);
            //    if (e.isSourceLoaded && e.sourceId == "pricedata") {
            //        if (showHistoricPricesRef.current) {
            //            console.log("getting price data from map");
            //            //let xx = mapLocal.querySourceFeatures('pricedata', {
            //            //    sourceLayer: 'outCluster',
            //            //    filter: ['in', ["get", "year"], ["literal", priceHistFilter]]
            //            //});
            //            setPriceHistData(xx);
            //            setPriceHistProc(true);
            //        }
            //    }
            //});

            mapLocal.on('moveend', () => {
                console.log("MOVE END");
                setLng(mapLocal.getCenter().lng.toFixed(4));
                setLat(mapLocal.getCenter().lat.toFixed(4));
                setZoom(mapLocal.getZoom().toFixed(2));
                try {
                    let bounds = mapLocal.getBounds();
                    setBounds(bounds);


                }
                catch (err) {
                    console.log(err);
                }
            });

            mapLocal.scrollZoom.setWheelZoomRate(1 / 300); //default 1/450
            mapLocal.scrollZoom.setZoomRate(1 / 75); //default 1/100
        };

        if (!map) {
            initMap({ setMap, mapContainer });
        }

    }, [map]);

    //const debounce = (method, delayMs) => {
    //    delayMs = delayMs || 200;
    //    setTimer(null);
    //    clearTimeout(timer);
    //    var t = setTimeout(() => {
    //        method()
    //    }, delayMs);
    //    setTimer(t);
    //}

    return (
        <div className="map-container" ref={mapContainer} />
    );
};

export default MapBox;
