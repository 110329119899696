import React, { useState, memo, useEffect, useRef } from 'react';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import HistoSlider2 from '../reusable/histoslider/HistoSlider2';

const RoomCntSlider = ({ filterData, setFilter }) => {
    const [state, setState] = useState(null);
    const [outValue, setOutValue] = useState([]);
    const [timer, setTimer] = useState(null);
    const outValRef = useRef([0, 0]);

    useEffect(() => {
        if (filterData == null || filterData.exclusionFilters == null || filterData.exclusionFilters.children == null) {
            return;
        }
        let data = filterData.exclusionFilters.children.find(a => a.label == "roomsCnt").children;
        let temp = [];
        temp.push([100000000, 0]);
        data.forEach(pd => {
            temp.push([pd.from, pd.count]);
        });
        setState(temp);
    }, [filterData]);

    useEffect(() => {
        if (outValue != null && outValue.length == 2 && outValue[0] >= 0 && outValue[1] > 0) {
            if (outValue[0] != outValRef.current[0] || outValue[1] != outValRef.current[1]) {
                outValRef.current = outValue;
                debounce(() => {
                    let f1 = outValue[0];
                    let f2 = outValue[1];
                    let newFilter = {
                        displayText: `${f1}-${f2}`,
                        field: "roomsCnt",
                        from: f1,
                        id: `xxx-slider-roomsCnt`,
                        label: `${f1}-${f2}`,
                        to: f2,
                        type: "Range",
                    }
                    if (f1 == 0 && f2 == 100000000) {
                        setFilter(filters => [...(filters.filter(a => a.id != newFilter.id))])
                    }
                    else {
                        setFilter(filters => [...(filters.filter(a => a.id != newFilter.id)), newFilter])
                    }
                }, 500);
            }
        }
    }, [outValue]);

    const debounce = (method, delayMs) => {
        delayMs = delayMs || 500;
        setTimer(null);
        clearTimeout(timer);
        var t = setTimeout(() => {
            method()
        }, delayMs);
        setTimer(t);
    }


    return (
        <Paper>
            <Typography variant="h6" align="left">Number of Rooms</Typography>
            <HistoSlider2 data={state} setOutValue={setOutValue}
                isRange={true} leftLabel="min rooms" rightLabel="max rooms" />
        </Paper>
    );
}


export default memo(RoomCntSlider);