import Box from '@material-ui/core/Box';
import ProductSummary from './ProductSummary';
import Neighbourhood from './Neighbourhood';
import JSONPretty from 'react-json-pretty';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { memo } from 'react';
const useStyles = makeStyles((theme) => ({

    tabPan: {
        "overflowY": "auto",
        [theme.breakpoints.up("sm")]: {
            "maxHeight": 'calc(100vh - 96px)' //48+48
        },
        //[theme.breakpoints.down("sm")]: {
        //    "maxHeight": 'calc(100vh - 80px)' //32 + 48
        //},
    }
}));

const TabPanels = ({ value, item }) => {
    const classes = useStyles();
    const TabPanel = ({ children, value, index, ...other }) => {
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                className={classes.tabPan}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Box>{children}</Box>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const theme = useTheme();
    return (
        <>
            <TabPanel value={value} index={0} dir={theme.direction} >
                <ProductSummary data={item} />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <Neighbourhood data={item} />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
                <JSONPretty id="json-pretty" data={item}></JSONPretty>
            </TabPanel>
        </>
    )
}

export default memo(TabPanels);
