import React, { useState, memo, useEffect, useRef } from 'react';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import HistoSlider2 from '../reusable/histoslider/HistoSlider2';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const SchoolFilter = ({ filterData, setFilter, label }) => {

    const [value, setValue] = React.useState("none");
    const [data, setData] = useState(null);
    const labels = [`Any ${label}`, `Ofsted Good ${label}`, `Ofsted Outstanding ${label}`];
    useEffect(() => {
        if (filterData == null || filterData.exclusionFilters == null || filterData.exclusionFilters.children == null) {
            return;
        }
        let catchments = filterData.exclusionFilters.children.find(a => a.label == "school" + label + "CatchmentTerms");
        let dataT = {};
        let f = catchments.children.find(a => a.label == `Any ${label}`);
        dataT.any = {
            text: `Any school in catchment (${f ? f.count : 0})`,
            filter: f,
            cnt: f ? f.count : 0
        };
        f = catchments.children.find(a => a.label == `Ofsted Good ${label}`);
        dataT.good = {
            text: `Ofsted good or better in catchment (${f ? f.count : 0})`,
            filter: f,
            cnt: f ? f.count : 0
        };
        f = catchments.children.find(a => a.label == `Ofsted Outstanding ${label}`);
        dataT.outstanding = {
            text: `Ofsted outstanding in catchment (${f ? f.count : 0})`,
            filter: f,
            cnt: f ? f.count : 0
        };
        dataT.none = {
            text: `Unrestricted (${catchments.count})`,
            filter: null,
            cnt: catchments.count
        };

        setData(dataT);
    }, [filterData]);

    useEffect(() => {
        if (data) {
            let filter = data[value].filter;

            setFilter(a => {
                //remove existing filter if exists.
                let newFilter = a.filter(b => !labels.includes(b.label));
                //re-add new filter if selected.
                if (filter) {
                    return [...newFilter, filter];
                }
                else {
                    return newFilter;
                }
            });

        }
    }, [value]);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    if (data) {
        return (
            <Paper>
                <Typography variant="h6" align="left">{label} School Catchments</Typography>
                <RadioGroup aria-label={label} name={label} value={value} onChange={handleChange}>
                    <FormControlLabel value="none" control={<Radio />} label={data.none.text} />
                    <FormControlLabel value="any" control={<Radio />} label={data.any.text} />
                    <FormControlLabel value="good" control={<Radio />} label={data.good.text} />
                    <FormControlLabel value="outstanding" control={<Radio />} label={data.outstanding.text} />
                </RadioGroup>
            </Paper>
        );
    }
    else {
        return (<></>);
    }
}


export default memo(SchoolFilter);