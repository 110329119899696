import React, { useEffect, memo, useState } from 'react';
import ReactJson from 'react-json-view';

const useProductQuery = () => {

    const [product, setProduct] = useState(null);
    const thing = (id) => {
        if (id) {
            let url = `https://api.propertypiper.com/api/index/${id}`;
            if (window.location.host.includes("propertypiper.co.uk")) {
                url = `https://api.propertypiper.co.uk/api/index/${id}`;
            }
            fetch(url, {
            //    fetch(`http://localhost:8001/api/index/${id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow",
            }).then((resp) => {
                if (resp.status == 200 && resp) {
                    return resp.text();
                    //return resp.json(); // wierdly grabbing text and converting using JSON.parse later seems faster? Can't be right though... validate this.
                }
            }).then(body => {
                let p = JSON.parse(body);
                setProduct(p);
            });
        } else {
            setProduct(null);
        }
    };

    
    return [
        product,
        a => thing(a)
        ];
}

export default useProductQuery;