import React, { memo } from 'react';
//import { DataGrid } from '@mui/x-data-grid';
import BasicTable from './BasicTable';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const GetVal = (prefix, data, filter) => {
    let sum = 0;
    filter.forEach(y => {
        sum += data[`${prefix}${y - 2000}`];
    });
    return sum;
}

const CrimePopup = ({ data, filter }) => {

    console.log(data);
    console.log(filter);
    let x = [
        {
            name: "Antisocial Behaviour",
            value: GetVal("asb", data, filter) * 1,
            raw: GetVal("asb", data, filter)
        },
        {
            name: "Burglary",
            value: GetVal("b", data, filter) * 10,
            raw: GetVal("b", data, filter) 
        },
        {
            name: "Bicycle Theft",
            value: GetVal("bt", data, filter) * 5,
            raw: GetVal("bt", data, filter) 
        },
        {
            name: "Criminal Damage",
            value: GetVal("cda", data, filter) * 3,
            raw: GetVal("cda", data, filter) 
        },
        {
            name: "Drugs",
            value: GetVal("d", data, filter) * 5,
            raw: GetVal("d", data, filter) 
        },
        {
            name: "Other Crime",
            value: GetVal("oc", data, filter) * 3,
            raw: GetVal("oc", data, filter) 
        },
        {
            name: "Public Order",
            value: GetVal("po", data, filter) * 3,
            raw: GetVal("po", data, filter) 
        },
        {
            name: "Possession of Weapons",
            value: GetVal("pow", data, filter) * 20,
            raw: GetVal("pow", data, filter) 
        },
        {
            name: "Robbery",
            value: GetVal("r", data, filter) * 20,
            raw: GetVal("r", data, filter) 
        },
        {
            name: "Shoplifting",
            value: GetVal("s", data, filter) * 3,
            raw: GetVal("s", data, filter)
        },
        {
            name: "Theft From A Person",
            value: GetVal("tftp", data, filter) * 10,
            raw: GetVal("tftp", data, filter) 

        },
        {
            name: "Violence",
            value: GetVal("vaso", data, filter) * 1,
            raw: GetVal("vaso", data, filter) 
        },
        {
            name: "Vehicle Crime",
            value: GetVal("vc", data, filter) * 5,
            raw: GetVal("vc", data, filter) 
        },
        {
            name: "Other Theft",
            value: GetVal("ot", data, filter) * 1,
            raw: GetVal("ot", data, filter)
        }
    ];

    console.log(x);

    x = x.filter(a => a.value > 0);

    let n = {
        labels: x.map(a => `${a.name} (${a.raw})`),
        datasets: [
            {
                label: '# of Votes',
                data: x.map(a => a.value),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.9)',
                    'rgba(54, 162, 235, 0.9)',
                    'rgba(255, 206, 86, 0.9)',
                    'rgba(75, 192, 192, 0.9)',
                    'rgba(153, 102, 255, 0.9)',
                    'rgba(255, 159, 64, 0.9)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    var optionsPie = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (d) => {
                        const percentage = ` ${d.label}, contributes: ${d.raw}`
                        return percentage
                    },
                },
            },

            legend: {
                labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 12
                    },
                    color: "black"
                }
            }
        },
    };

    return <Pie data={n} options={optionsPie} />;
}


export default memo(CrimePopup);