import React, { useState, memo, useRef, Component, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const BasicTable = ({ header, data }) => {

    const [rows, setRows] = useState([]);
    const [headerData, setHeaderData] = useState(null);

    useEffect(() => {
        if (data != null) {
            setRows(data);
        }
    }, [data]);

    useEffect(() => {
        if (header != null && header.length > 0) {
            let tHeader = (
                <TableHead>
                    <TableRow>
                        {header.map(a => 
                            (<TableCell>{a}</TableCell>)
                        )}
                    </TableRow>
                </TableHead>
            );
            setHeaderData(tHeader);
            console.log("tHeader", tHeader, header);
        }
    }, [header]);

    return (
        <Paper >
            <TableContainer component={Paper} style={{ "maxHeight": "500px", "overflowY": "auto" }}>
                <Table aria-label="simple table" size="small"  >
                    {/*<TableHead>*/}
                    {/*    <TableRow>*/}
                    {/*        <TableCell>Feature</TableCell>*/}
                    {/*        <TableCell align="right">Value</TableCell>*/}
                    {/*    </TableRow>*/}
                    {/*</TableHead>*/}
                    {headerData}
                    <TableBody>
                        {rows.map((row, i) => (
                            <TableRow key={i}>
                                {row.map((col) => (
                                    <TableCell component="th" scope="row">{col}</TableCell>
                                ))}
                                {/*<TableCell component="th" scope="row">{row.name}</TableCell>*/}
                                {/*<TableCell align="right">{row.value}</TableCell>*/}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        </Paper>
    );
}


export default memo(BasicTable);