import { Container } from 'reactstrap';
import MiAccordion from '../MiAccordion';
import React, { useState, memo, useRef, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import ProductView from './ProductView';
import ProductHeader from './ProductHeader';
import DescriptionAndKey from './DescriptionAndKey';
import Typography from '@material-ui/core/Typography';
import SchoolDetail from './SchoolDetail';
import CrimeDetail from './CrimeDetail';
import AmenityDetail from './AmenityDetail';
import DemographicDetail from './DemographicDetail';
import AreaSummary from './AreaSummary';

const Neighbourhood = ({ data }) => {

    const [accordionContent, setAccordionContent] = useState([]);

    useEffect(() => {

        let aContent = [
            { heading: "Area Summary", content: (<AreaSummary data={data} />), id:"AreaSummary" },
            { heading: "Schools", content: (<SchoolDetail data={data} />), id: "SchoolDetail" },
            { heading: "Crime", content: (<CrimeDetail data={data} />), id: "CrimeDetail" },
            { heading: "Amenities", content: (<AmenityDetail data={data} />), id: "AmenityDetail" },
            { heading: "Demographics", content: (<DemographicDetail data={data} />), id: "DemographicDetail" },
            
        ];

        setAccordionContent(aContent)

    }, [data]);

    return (
        <>
            <MiAccordion contentArray={accordionContent} startExpanded={"AreaSummary"} />
        </>
        //<Paper>
        //    <Typography variant="h6" gutterBottom>Area Summary</Typography>
        //    <AreaSummary data={data} />
        //    <br/>
        //    <Typography variant="h6" gutterBottom>Schools</Typography>
        //    <SchoolDetail data={data}/>
        //    <br />
        //    <Typography variant="h6" gutterBottom>Crime</Typography>
        //    <CrimeDetail data={data} />
        //    <br />
        //    <Typography variant="h6" gutterBottom>Amenities</Typography>
        //    <AmenityDetail data={data}/>
        //    <br />
        //    <Typography variant="h6" gutterBottom>Demographics</Typography>
        //    <DemographicDetail />
        //    <br />
        //</Paper>
    );
}


export default memo(Neighbourhood);