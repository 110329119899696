import React, { useState, memo, useEffect, useRef } from 'react';
import { Paper } from '@material-ui/core';
import Box from '@mui/material/Box';
import GradientBar from './GradientBar';
import { Grid, Typography, Slider } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: 'rgba(255,255,255,0.8)',
        border: '1px solid gray',
        display: 'inline-flex',
        position: 'relative',
        alignItems: 'center',
        verticalAlign: 'middle',
        justifyContent: 'center',
        pointerEvents: 'auto',
        opacity: 1,


        [theme.breakpoints.up("xs")]: {
            width: '400px',
            height: '220px',
            borderRadius: '10px',
            margin: '6px 16px',
        },
        [theme.breakpoints.down("xs")]: {
            width: '220px',
            height: '180px',
            borderRadius: '6px',
            margin: '3px 8px',
            paddingTop: "20px",
        },

        '& .content': {

            [theme.breakpoints.up("xs")]: {
                width: '360px',
            },
            [theme.breakpoints.down("xs")]: {
                width: '180px',
                paddingTop: "0px",
            },
        }
    }
}));

const CrimeControl = ({ visible, setFilter }) => {

    const [sliderValue, setSliderValue] = useState([2024]);
    const [sliderValues, setSliderValues] = useState([2020, 2024]);
    const [singleYearMode, setSingleYearMode] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (singleYearMode) {
            setFilter(sliderValue);
        }
        else {
            let min = Math.min(...sliderValues);
            let max = Math.max(...sliderValues);
            let filter = Array.from({ length: max - min + 1 }, (_, i) => min + i);
            console.log("crime filter set", filter);
            setFilter(filter);
        }

    }, [singleYearMode, sliderValue, sliderValues, setFilter]);

    const marks = [
        {
            value: 2020,
            label: '2020',
        },
        {
            value: 2021,
            label: '2021',
        },
        {
            value: 2022,
            label: '2022',
        },
        {
            value: 2023,
            label: '2023',
        },
        {
            value: 2024,
            label: '2024',
        }
    ]



    if (!visible) {
        return (<></>);
    }

    console.log("singleYearMode", singleYearMode);
    console.log(sliderValue);
    return (<Box className={classes.container}>
        <Grid container spacing={0} style={{ margin: 'auto' }} className="content" >
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <GradientBar type={"crime"} />
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
                <FormControlLabel control={<Switch checked={singleYearMode} onChange={() => setSingleYearMode(a => !a)} />} label="Single Year" />
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'center' }}>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>

                {singleYearMode ? (
                    <Slider
                        value={sliderValue}
                        onChange={(event, newValues) => setSliderValue(newValues)}
                        marks={marks}
                        min={2020}
                        max={2024}
                        step={1}
                        valueLabelDisplay="auto"
                        getAriaValueText={a => `${a}`}
                    />
                ) : (
                    <Slider
                        value={sliderValues}
                        onChange={(event, newValues) => setSliderValues(newValues) }
                        marks={marks}
                        min={2020}
                        max={2024}
                        step={1}
                        valueLabelDisplay="auto"
                        getAriaValueText={a => `${a}`}
                    />
                )}


            </Grid>
        </Grid>
    </Box>)
}


export default memo(CrimeControl);