import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

const useStyles = makeStyles((theme) => ({

    drawerPaper: {
        width: "100%",
        top: "36px",
        height: "100%",
        pointerEvents: "auto",
        overflow: "visible"
    },
    visBottom: {
        flexShrink: 0,
        height: "100%",
        display: "block"
    }
}));

const BottomSwipeableDrawer = ({ open, setOpen, children }) => {

    const classes = useStyles();
    const drawerBleeding = 56;

    return (

        <SwipeableDrawer
            className={classes.visBottom}
            anchor="bottom"
            open={open}
            onOpen={() => { }}
            onClose={() => setOpen(false)}
            swipeAreaWidth={drawerBleeding}
            classes={{
                paper: classes.drawerPaper
            }}
            PaperProps={{
                sx: {
                    height: 'calc(100% -64px)', // Adjust as needed
                    top: 64, // Offset for app bar
                },
            }}
        >

            <div style={{ paddingBottom: "40px" }} >
                {children}
            </div>
        </SwipeableDrawer>

    );
}

export default memo(BottomSwipeableDrawer);