import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AppBar from '@material-ui/core/AppBar';
import { memo } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';

const PropertyTabBar = ({ setOpen, setValue, value, setTriggerHalfOpen }) => {
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const zoomToLocation = () => {
        setTriggerHalfOpen(true);
    }

    return (
        <AppBar position="sticky">
            <div style={{ display: "flex", minHeight: 48 }}>
                <IconButton color="secondary"
                    aria-label="close"
                    onClick={() => setOpen(a => !a)}
                ><CancelIcon /></IconButton >
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" centered>
                    <Tab label="Summary" {...a11yProps(0)} />
                    <Tab label="Neighbourhood" {...a11yProps(1)} />
                    <Tab label="Debug" {...a11yProps(2)} />
                </Tabs>
                <div style={{ flexGrow: "3" }}></div>
                <IconButton onClick={() => zoomToLocation()} style={{ "align": "right" }} color="secondary">
                    <GpsFixedIcon />
                </IconButton>
                
            </div>
        </AppBar>
    )
}

export default memo(PropertyTabBar);
