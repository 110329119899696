import React, { memo, useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useTheme } from '@material-ui/styles';


const BarChart2 = ({ data, highlight, isRange, prefix, suffix, renderFactor }) => {

    const [barData, setBarData] = useState([]);

    const theme = useTheme();

    const formatLabel = (x) => {
        if (renderFactor != null && renderFactor != 0) {
            x = x * renderFactor;
        }
        let rtn = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return x;
    }

    useEffect(() => {
        if (data != null && data.length > 0) {
            //setBarData(data.map(a => a[1]));
            let dataSource = [];
            //if (isRange) {
                let dataNoTail = data.sort((a, b) => a[0] - b[0]).slice(0, -1);
                dataSource = dataNoTail;
            //}
            //else {
            //    dataSource = data.sort((a, b) => a[0] - b[0]);
            //}
            let normPrefix = prefix ? prefix : "";
            let normSuffix = suffix ? suffix : "";

            //if (renderFactor == null && renderFactor > 1) {
            //    start = start * renderFactor;
            //    end = end * renderFactor;
            //}

            let bar = {
                labels: dataSource.map((v, i) => {
                    if (isRange) {
                        return `${normPrefix}${formatLabel(v[0])}${normSuffix}${i < (dataSource.length - 1) ? "-" + normPrefix + formatLabel(dataSource[i + 1][0]) + normSuffix : "+"}`;
                    }   
                    else {
                        //let n = `${i < (dataSource.length - 1) ? "" + numberWithCommas(dataSource[i + 1][0]) : "+"}`;
                        return `${normPrefix}${formatLabel(v[0])}${normSuffix}`;
                    }
                }),
                datasets: [
                    {
                        backgroundColor: dataSource.map((val, i) =>
                            i >= highlight[0] && i < highlight[1]
                                //? "rgba(251, 192, 45, 1)"
                                //: "rgba(156, 39, 176, 0.2)"
                                ? theme.palette.datavis.main
                                : theme.palette.datavis.main + "10"
                        ), 
                        hoverBackgroundColor: "rgba(255,99,132,0.4)",
                        data: dataSource.map(a => a[1])
                    }
                ]
            };
            setBarData(bar);
        }
    }, [data, highlight]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 0
        },
        plugins: {
            legend: {
                display: false
            }
        },
        legend: {
            display: false
        },
        scales: {
            x: {
                display: false
            }
            ,
            y:
            {
                display: false,
            }
        }
    };

    return (
        <div style={{ "height": "100%", "position": "relative", "marginBottom": "1px" }}>
            <Bar data={barData} options={options} />
        </div>
    );
}


export default memo(BarChart2);
