import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const MiAccordion = ({ contentArray, startExpanded }) => {
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(startExpanded);

    const handleChange = (panel) => (event, isExpanded) => {
        console.log("ack");
        setExpanded(isExpanded ? panel : false);
    };

    //return (
    //    <div onClick={() => console.log("boop?")} >
    //        {contentArray.map((a) => (
    //            <div key={a.id}>
    //                <Typography
    //                    variant="h6"
    //                    onClick={() => handleToggle(a.id)}
    //                    style={{ cursor: 'pointer' }}
    //                >
    //                    {a.heading}
    //                    <ExpandMoreIcon
    //                        style={{
    //                            transform: expand === a.id ? 'rotate(180deg)' : 'rotate(0)',
    //                            transition: 'transform 0.3s ease',
    //                        }}
    //                    />
    //                </Typography>
    //                <Collapse in={expand === a.id}>
    //                    <div>{a.content}</div>
    //                </Collapse>
    //            </div>
    //        ))}
    //    </div>
    //);

    return (
        <div className={classes.root} onClick={() => console.log("boop beep?")}>
            {contentArray.map((a, i) => {
                return (
                    <Accordion key={a.id} expanded={expanded === a.id} onChange={handleChange(a.id)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            {a.heading.length > 0 ? (<Typography className={classes.heading}>{a.heading}</Typography>) : null }    
                        </AccordionSummary>
                        <AccordionDetails>
                            {a.content}
                        </AccordionDetails>
                    </Accordion>
                    );
            })}
        </div>
    );
}

export default MiAccordion;