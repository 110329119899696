import { Container } from 'reactstrap';
import React, { useState, memo, useRef, Component, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ReactDOM from 'react-dom';
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from 'react-responsive-carousel';
import SwiperThumb from "../swiperThumbs/swiperthumb";


const ProductHeader = ({ data }) => {

    const [smallestHeight, setSmallestHeight] = useState(800);

    const onImgLoad = (x) => {
        if (x.target.clientHeight >= 200 && x.target.clientHeight < smallestHeight) {
            setSmallestHeight(x.target.clientHeight);
        }
    };

    return (
        <Paper>
            <Typography variant="h5" gutterBottom>
                &pound;{Number(data.Price).toLocaleString()}
            </Typography>
            <Typography variant="h6" gutterBottom>
                {data.Title}
            </Typography>

            {/*<Carousel infiniteLoop useKeyboardArrows autoPlay={false} swipeable emulateTouch>*/}
            {/*    {data.Images.map((a, i) => {*/}
            {/*        return (*/}
            {/*            <div>*/}
            {/*                <img src={"https://mihousegeneralstorage1.blob.core.windows.net/imageblob/" + a + "-1024.avif"} onLoad={onImgLoad} style={{ maxHeight: smallestHeight, width: "100%", objectFit: "contain" }} />*/}
            {/*            </div>*/}
            {/*        )*/}
            {/*    })}*/}
            {/*</Carousel>*/}

            <SwiperThumb data={data} />
        </Paper>
    );
}


export default memo(ProductHeader);